import { AboutUs } from "../pages/Aboutus";
import { BrandList } from "../pages/BrandList";
import { Cart } from "../pages/Cart";
import { CategoriesDetails } from "../pages/CategoriesDetails";
import { CategoriesList } from "../pages/CategoriesList";
import { CheckOut } from "../pages/CheckOut";
import { ContactUs } from "../pages/ContactUs";
import { Faq } from "../pages/Faq";
import { ProductDetails } from "../pages/Products/ProductDetails";
import { ProductList } from "../pages/Products/ProductList";
import { SubCategoriesDetail } from "../pages/SubCategoriesDetail";
import { WishList } from "../pages/WishList";
import { BrandDetail } from "../pages/BrandDetail";
import { VendingMachineDetail } from "../pages/VendingMachine/VendingMachineDetail";
import { VendingMachineList } from "../pages/VendingMachine/VendingMachineList";
import { ShopDetail } from "../pages/Shop/ShopDetails";
import { ShopList } from "../pages/Shop/ShopList";

const publicRoutes = [
  { path: "all-products", element: <ProductList /> },
  { path: "all-products/:searchtag", element: <ProductList /> },
  { path: "product-details/:id", element: <ProductDetails /> },
  { path: "cart", element: <Cart /> },
  { path: "wish-list", element: <WishList /> },
  { path: "contact-us", element: <ContactUs /> },
  { path: "about-us", element: <AboutUs /> },
  { path: "faq", element: <Faq /> },
  { path: "check-out", element: <CheckOut /> },
  { path: "all-categories", element: <CategoriesList /> },
  { path: "all-categories/:searchparams", element: <CategoriesList /> },
  { path: "categories-detail/:id", element: <CategoriesDetails /> },
  { path: "sub-categories-detail/:id", element: <SubCategoriesDetail /> },
  { path: "all-brands", element: <BrandList /> },
  { path: "all-brands/:searchparams", element: <BrandList /> },
  { path: "brand-detail/:id", element: <BrandDetail /> },
  { path: "all-shops/:searchparams", element: <ShopList /> },
  { path: "all-shops", element: <ShopList /> },
  { path: "shop-detail/:id", element: <ShopDetail /> },
  { path: "all-vending-machines", element: <VendingMachineList /> },
  {
    path: "all-vending-machines/:searchparams",
    element: <VendingMachineList />,
  },
  { path: "vending-machine-detail/:id", element: <VendingMachineDetail /> },
];

export default publicRoutes;
