import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import dealsBackground from "../../assets/img/banner/bg-full1.png";

export const OfferSkeltonSlider = () => {
  return (
    <>
      <section
        className="deals__banner--section banner__bg"
        style={{ backgroundImage: `url(${dealsBackground})` }}
      >
        <div className="container">
          <SkeletonTheme
            className="row align-items-center"
            baseColor="#f2f2f2"
            highlightColor="#fff"
          >
            <div className="col-lg-5 col-md-5 deals__baner--col">
              <div className="deals__banner--content">
                <h3 className="deals__banner--content__subtitle text__secondary">
                  <Skeleton />
                </h3>
                <h2 className="deals__banner--content__maintitle">
                  <Skeleton />
                </h2>
                <p className="deals__banner--content__desc">
                  <Skeleton />
                </p>
                <div
                  className="deals__banner--countdown d-flex"
                  data-countdown="Sep 30, 2023 00:00:00"
                ></div>
                <p className="btn deals__banner--content__btn">
                  <Skeleton />
                </p>
              </div>
            </div>
            <div className="col-lg-7 col-md-7 deals__baner--col">
              <div className="banner__items text-right">
                <div className="banner__items--thumbnail display-block">
                  <Skeleton
                    height={250}
                    width={250}
                    className="banner__items--thumbnail__img display-block"
                  />
                </div>
              </div>
            </div>
          </SkeletonTheme>
        </div>
      </section>
    </>
  );
};
