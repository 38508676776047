import errorImage from "../assets/img/other/404-thumb.png";

export const PageNotFound = () => {
  return (
    <>
      <section className="error__section section--padding">
        <div className="container">
          <div className="row row-cols-1">
            <div className="col">
              <div className="error__content text-center">
                <img
                  className="error__content--img display-block mb-50"
                  src={errorImage}
                  alt="error-img"
                />
                <h2 className="error__content--title">
                  Opps ! We,ar Not Found This Page{" "}
                </h2>
                <p className="error__content--desc">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Excepturi animi aliquid minima assumenda.
                </p>
                <a className="error__content--btn btn" href="index.html">
                  Back To Home
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
