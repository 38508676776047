import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const DetailPageSkelton = () => {
  const items = [1];

  return (
    <>
      <section className="product__section product__categories--section section--padding">
        <div className="container ">
          {items &&
            items.map((item, index) => (
              <SkeletonTheme
                baseColor="#f2f2f2"
                highlightColor="#fff"
                key={index}
              >
                <div className="product__details--section section--padding pt-0">
                  <div className="container">
                    <div className="row row-cols-lg-2 row-cols-md-2">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div className="product__details--media">
                          <div className="product__media--preview__items">
                            <div
                              className="product__media--preview__items--link glightbox"
                              data-gallery="product-media-preview"
                            >
                              <Skeleton
                                className="product__media--preview__items--img"
                                height={200}
                                width={200}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div className="product__details--info">
                          <h2 className="product__details--info__title mb-15">
                            <Skeleton />
                          </h2>
                          <p className="product__details--info__desc mb-20">
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SkeletonTheme>
            ))}
        </div>
      </section>
    </>
  );
};
