import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useErrorBoundary } from "react-error-boundary";
import dealsBackground from "../../assets/img/banner/bg-full1.png";
import defaultImage from "../../assets/img/default.png";
import { getActiveOfferSliders } from "../../api/SlidersApi";
import { OfferSkeltonSlider } from "../Skeltons/OfferSkeltonSlider";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/components/pagination/pagination.min.css";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";

SwiperCore.use([Pagination, Navigation, Autoplay]);

export const OfferSlider = () => {
  const { showBoundary } = useErrorBoundary();

  const [offerSliderData, setOfferSlideData] = useState([]);
  const [displayState, setdisplayState] = useState(false);
  useEffect(() => {
    OfferSlidersData();
  }, []);

  const OfferSlidersData = async () => {
    await getActiveOfferSliders(1)
      .then((result) => {
        if (result.data.status === 1) {
          let sliderDataArray = result.data.sliders;
          setOfferSlideData(sliderDataArray);
          setdisplayState(true);
        }
      })
      .catch((error) => {
        showBoundary(error);
      });
  };
  return (
    <>
      {displayState ? (
        <section
          className="deals__banner--section banner__bg"
          style={{ backgroundImage: `url(${dealsBackground})` }}
        >
          <div className="container">
            <Swiper
              effect={"fade"}
              centeredSlides={false}
              slidesPerView={6}
              spaceBetween={30}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                400: {
                  slidesPerView: 1,
                },
                639: {
                  slidesPerView: 1,
                },
                865: {
                  slidesPerView: 1,
                },
                1000: {
                  slidesPerView: 1,
                },
                1500: {
                  slidesPerView: 1,
                },
                1700: {
                  slidesPerView: 1,
                },
                2560: {
                  slidesPerView: 1,
                },
              }}
              modules={[Pagination]}
              pagination={{ clickable: true }}
              navigation={{
                prevEl: ".prev",
                nextEl: ".next",
              }}
              className="hero__slider--inner hero__slider--activation swiper"
            >
              <div className="hero__slider--wrapper swiper-wrapper">
                {offerSliderData &&
                  offerSliderData.map((item) => (
                    <SwiperSlide
                      className="swiper-slide"
                      key={item.slider_two_id}
                    >
                      <div className="row align-items-center">
                        <div className="col-lg-5 col-md-5 deals__baner--col">
                          <div className="deals__banner--content">
                            <h3 className="deals__banner--content__subtitle text__secondary">
                              {item.slider_two_short_description}
                            </h3>
                            <h2 className="deals__banner--content__maintitle">
                              {item.slider_two_title}
                            </h2>
                            <p className="deals__banner--content__desc">
                              {item.slider_two_description}
                            </p>
                            <div
                              className="deals__banner--countdown d-flex"
                              data-countdown="Sep 30, 2023 00:00:00"
                            ></div>
                            <a
                              className="btn deals__banner--content__btn"
                              href={item.slider_two_link}
                            >
                              Discover Now
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-7 col-md-7 deals__baner--col">
                          <div className="banner__items text-right">
                            <Link
                              className="banner__items--thumbnail display-block"
                              href="#"
                            >
                              {item.slider_two_image ? (
                                <img
                                  className="banner__items--thumbnail__img display-block"
                                  src={item.slider_two_image}
                                  alt="banner-layer-img"
                                />
                              ) : (
                                <img
                                  className="banner__items--thumbnail__img display-block"
                                  src={defaultImage}
                                  alt="banner-layer-img"
                                />
                              )}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
              </div>
            </Swiper>
          </div>
        </section>
      ) : (
        <OfferSkeltonSlider />
      )}
    </>
  );
};
