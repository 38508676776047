import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useErrorBoundary } from "react-error-boundary";
import defaultImage from "../../assets/img/default.png";
import { ProductListSkelton } from "../../components/Skeltons/ProductListSkelton";
import { getproductList } from "../../api/ProductsApi";
import { NoProductsFound } from "./NoProductsFound";
import { ProductCard } from "./ProductCard";

export const AllProducts = () => {
  const { showBoundary } = useErrorBoundary();
  const [productdata, setproductData] = useState([]);
  const [productdisplayState, setproductdisplayState] = useState(false);
  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    await getproductList()
      .then((result) => {
        if (result.data.status === 1) {
          setproductData(result.data.products);
          setproductdisplayState(true);
        }
      })
      .catch((err) => {
        showBoundary(err);
      });
  };

  return (
    <>
      {productdisplayState ? (
        <section className="shop__product--wrapper section--padding pt-0">
          <div className="container">
            <div className="tab_content">
              <div className="section__heading d-flex justify-content-between align-items-center mb-40">
                <h2 className="section__heading--maintitle font-18">
                  All Products{" "}
                </h2>
                <div>
                  <Link to="/all-products">
                    View All <i className="fa fa-angle-double-right fs-3"></i>
                  </Link>
                </div>
              </div>
              <div id="product_grid" className="tab_pane active show">
                <div className="product__section--inner product__section--style3__inner">
                  <div className="row row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-3 row-cols-sm-3 row-cols-2 mb--n30">
                    {productdata.length !== 0
                      ? productdata.slice(0, 11).map((item, index) => (
                          <div key={index}>
                            <ProductCard item={item} />
                          </div>
                        ))
                      : null}

                    {productdata.length !== 0 && productdata.length >= 12 ? (
                      <div className="col mb-30">
                        <div className="product__categories--grid__items product__bg d-flex justify-content-center align-items-center h-100">
                          <div className="product__categories--grid__content">
                            <h3 className="product__categories--grid__content--maintitle">
                              <Link to="/all-products">View More</Link>
                            </h3>
                            <span className="product__categories--grid__content--subtitle">
                              {productdata.length} items
                            </span>
                            <div className="product__categories--grid__icon text-center">
                              <Link
                                className="product__categories--grid__icon--link"
                                to="/all-products"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15.995"
                                  height="10.979"
                                  viewBox="0 0 15.995 10.979"
                                >
                                  <path
                                    d="M212.706,299.839a.425.425,0,0,0,0-.6l-3.458-3.458a.425.425,0,0,1,0-.6l1.008-1.008a.425.425,0,0,1,.6,0l5.065,5.065a.425.425,0,0,1,0,.6l-5.065,5.066a.425.425,0,0,1-.6,0l-1.008-1.008a.425.425,0,0,1,0-.6Zm-6.305-.3a2.215,2.215,0,1,0,2.216-2.216A2.215,2.215,0,0,0,206.4,299.541Zm-3.634,0a1.6,1.6,0,1,0,1.6-1.605A1.6,1.6,0,0,0,202.767,299.541Zm-2.717,0a1.154,1.154,0,1,0,1.154-1.154A1.155,1.155,0,0,0,200.05,299.541Z"
                                    transform="translate(-200.05 -294.05)"
                                    fill="currentColor"
                                  />
                                </svg>
                                <span className="visually-hidden">
                                  categories icon
                                </span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {productdata.length === 0 ? <NoProductsFound /> : null}
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div className="container">
          <div className="row row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-3 row-cols-sm-3 row-cols-2 mb--n30">
            <ProductListSkelton />
          </div>
        </div>
      )}
    </>
  );
};
