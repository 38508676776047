import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useErrorBoundary } from "react-error-boundary";
import defaultImage from "../../assets/img/default.png";
import { getAllVendingMachines } from "../../api/VendingMachine";
import { SliderSkelton } from "../../components/Skeltons/SliderSkelton";
import { getproductList } from "../../api/ProductsApi";
import { ProductCard } from "../../components/Products/ProductCard";
import { ProductListSkelton } from "../../components/Skeltons/ProductListSkelton";
import { NoVMFound } from "../../components/VendingMachines/NoVMFound";

export const VendingMachineList = () => {
  const { searchparams } = useParams();
  const { showBoundary } = useErrorBoundary();

  const [vmListData, setVMListData] = useState([]);
  const [vmState, setVMState] = useState(true);
  const [displayState, setdisplayState] = useState(false);
  const [allVMVisible, setallVMVisible] = useState(12);

  // Product
  const [productDetails, setProductDetails] = useState([]);
  const [visible, setVisible] = useState(12);
  const [searchProduct, setSearchProduct] = useState("");
  const [productLoader, setProductLoader] = useState(false);
  useEffect(() => {
    getallVMData();
  }, []);

  const getallVMData = async () => {
    await getAllVendingMachines()
      .then((res) => {
        // console.log(res.data);
        if (res.data.status === 1) {
          setVMListData(res.data.vms);
          setdisplayState(true);
          getAllAvailableProducts();
        }
      })
      .catch((err) => {
        showBoundary(err);
      });
  };

  const getAllAvailableProducts = async () => {
    await getproductList()
      .then((result) => {
        // console.log(result.data);
        if (result.data.status === 1) {
          let products = result.data.products;
          let updatedProducts = products.map((product) => {
            const showproduct = product?.product_stores?.some(
              (store) => store?.store_vending_machines?.length > 0
            );
            return { ...product, showproduct };
          });
          const produtsList = updatedProducts.filter(
            (product) => product.showproduct
          );
          setProductDetails(produtsList);
          setProductLoader(true);
          if (searchparams) {
            setSearchProduct(searchparams);
          } else {
            setSearchProduct("");
          }
        }
      })
      .catch((error) => {
        showBoundary(error);
      });
  };
  const showMoreProductItems = () => {
    setVisible((prevValue) => prevValue + 12);
  };

  const showMoreAllVMItems = () => {
    setallVMVisible((prevValue) => prevValue + 12);
  };
  return (
    <>
      <section className="product__section product__categories--section section--padding pt-5">
        <div className="container">
          <div className="row mb-5">
            <div className="col">
              <div className="breadcrumb__content">
                <ul className="breadcrumb__content--menu d-flex">
                  <li className="breadcrumb__content--menu__items">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb__content--menu__items text__primary">
                    <span>All Vending Machines</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* ===================== Sorting Conatiner start ============= */}
          {/* Tab Data Start */}
          {displayState ? (
            <>
              {/* All Stores Section Start */}
              {vmListData.length !== 0 && vmState && (
                <div className="section__heading d-flex justify-content-between align-items-center mb-40">
                  <h2 className="section__heading--maintitle">
                    All Vending Machines{" "}
                  </h2>
                </div>
              )}
              {vmListData.length === 0 ? <NoVMFound /> : null}
              <div className="row mt-5">
                {vmListData.length !== 0 &&
                  vmState &&
                  vmListData.slice(0, allVMVisible).map((item) => (
                    <div
                      className="col-lg-2 col-xs-6 col-sm-6 mb-30"
                      key={item.vm_id}
                    >
                      <div className="categories2__product--items text-center">
                        {item.vm_images ? (
                          <div className="categories2__product--thumbnail">
                            <Link
                              className="categories2__product--link display-block"
                              to={`/vending-machine-detail/${item.vm_id}`}
                            >
                              <img
                                className="categories2__product--img display-block cat-list-img rounded-circle"
                                src={item.vm_images[0]}
                                alt={item.vm_name}
                                title={item.vm_name}
                              />
                            </Link>
                          </div>
                        ) : (
                          <div className="categories2__product--thumbnail">
                            <Link
                              className="categories2__product--link display-block"
                              to={`/vending-machine-detail/${item.vm_id}`}
                            >
                              <img
                                className="categories2__product--img display-block cat-list-img rounded-circle"
                                src={defaultImage}
                                alt={item.vm_name}
                                title={item.vm_name}
                              />
                            </Link>
                          </div>
                        )}
                        <div className="product__categories--content2">
                          <h3 className="product__categories--content2__maintitle">
                            <Link to={`/vending-machine-detail/${item.vm_id}`}>
                              {item.vm_name}
                            </Link>
                          </h3>
                          {/* {item.brand_state === 1 ? (
                              <span className="text__primary">featured</span>
                            ) : null} */}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              {vmListData && !vmState && vmListData.length > 12 ? (
                <div className="row mt-5 pt-5">
                  <div className="col d-flex justify-content-center align-items-center">
                    <button
                      className="btn btn-success"
                      onClick={showMoreAllVMItems}
                    >
                      Load More
                    </button>
                  </div>
                </div>
              ) : null}
              {/* All Stores Section end */}
              {/* All Available Products start */}
              <div className="row row-md-reverse">
                <div className="col-lg-12">
                  {vmListData.length === 0 &&
                  productDetails.length === 0 ? null : (
                    <div className="shop__header bg__gray--color mb-30">
                      <div className="product__view--mode d-flex align-items-center justify-content-between">
                        <div className="section__heading">
                          <h2 className="fs-3 fw-bold">
                            All Vending Machine Products{" "}
                          </h2>
                        </div>
                        {/* d-none */}
                        <div className="product__view--mode__list product__view--search d-xl-block">
                          <form
                            className="product__view--search__form"
                            action="#"
                          >
                            <label>
                              <input
                                className="product__view--search__input border-0"
                                placeholder="Search by Product Name"
                                type="text"
                                value={searchProduct}
                                onChange={(e) => {
                                  setSearchProduct(e.target.value);
                                }}
                              />
                            </label>
                          </form>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="shop__product--wrapper">
                    <div className="tab_content">
                      <div id="product_grid" className="tab_pane active show">
                        <div className="product__section--inner product__section--style3__inner">
                          <div className="row row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-3 row-cols-sm-3 row-cols-2 mb--n30">
                            {productDetails.length !== 0
                              ? productDetails
                                  .filter((item) =>
                                    item.product_title
                                      ?.toLowerCase()
                                      .includes(searchProduct?.toLowerCase())
                                  )
                                  .slice(0, visible)
                                  .map((item, index) => (
                                    <div key={index}>
                                      <ProductCard item={item} />
                                    </div>
                                  ))
                              : null}
                            {productLoader ? "" : <ProductListSkelton />}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {visible < productDetails.length ? (
                  <div className="row mt-5 pt-5">
                    <div className="col d-flex justify-content-center align-items-center">
                      <button
                        className="btn btn-success"
                        onClick={showMoreProductItems}
                      >
                        Load More
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
              {/* All Available Products End */}
            </>
          ) : (
            <SliderSkelton />
          )}
          {/* Tab Data End */}
        </div>
      </section>
    </>
  );
};
