import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const ProductListSkelton = () => {
  const items = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <>
      {items &&
        items.map((item, index) => (
          <SkeletonTheme
            baseColor="#f2f2f2"
            highlightColor="#fff"
            className="col-lg-3 col-xs-6 col-sm-6 mb-30"
            key={index}
          >
            <div className="product__items product__items2">
              <div className="product__items--thumbnail">
                <div className="product__items--link text-center">
                  <Skeleton height={244} width={232} />
                </div>
                <div className="product__items--content product__items2--content text-center">
                  <h3 className="product__items--content__title h4 product-list-title">
                    <Skeleton />
                  </h3>
                  <div className="product__items--price">
                    <span className="current__price">
                      <Skeleton />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </SkeletonTheme>
        ))}
    </>
  );
};
