import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import girlImage from "../assets/img/other/about-thumb-list2.png";
import showCaseImage from "../assets/img/other/about-thumb-list1.png";
import coverImage from "../assets/img/banner/counterup-bg2.png";
import { geAboutusData } from "../api/WebsiteManagement";
import { useErrorBoundary } from "react-error-boundary";

export const AboutUs = () => {
  const [aboutusData, setAboutusData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const { showBoundary } = useErrorBoundary();
  useEffect(() => {
    getAboutData();
  }, []);

  const aboutData = [];
  const getAboutData = async () => {
    await geAboutusData()
      .then((result) => {
        if (result.data.status === 1) {
          aboutData.push(result.data.aboutus);
          setAboutusData(aboutData);
          setDataLoading(true);
        }
      })
      .catch((error) => {
        showBoundary(error);
      });
  };

  return (
    <>
      <section className="about__section section--padding mb-95 pt-5">
        <div className="container">
          <div className="row mb-30">
            <div className="col">
              <div className="breadcrumb__content">
                <ul className="breadcrumb__content--menu d-flex">
                  <li className="breadcrumb__content--menu__items">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb__content--menu__items text__primary">
                    <span>About us</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {aboutusData &&
            aboutusData.map((item) => (
              <div className="row" key={item.aboutus_id}>
                <div className="col-lg-6">
                  <div className="about__thumb d-flex">
                    <div className="about__thumb--items">
                      <img
                        className="about__thumb--img border-radius-5 display-block"
                        src={item.aboutus_image_1}
                        alt="about-thumb"
                      />
                    </div>
                    <div className="about__thumb--items position__relative">
                      <img
                        className="about__thumb--img border-radius-5 display-block"
                        src={item.aboutus_image_2}
                        alt="about-thumb"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about__content">
                    <span className="about__content--subtitle text__secondary mb-20">
                      {" "}
                      {item.aboutus_sub_description}
                    </span>
                    <h2 className="about__content--maintitle mb-25">
                      {item.aboutus_title}
                    </h2>
                    <div
                      className="about__content--desc mb-20"
                      dangerouslySetInnerHTML={{
                        __html: item.aboutus_description,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </section>

      <div
        className="counterup__banner--section counterup__banner__bg2 mb-50"
        id="funfactId"
        style={{ backgroundImage: `url(${coverImage})` }}
      >
        <div className="container">
          {aboutusData &&
            aboutusData.map((item, index) => (
              <div className="row row-cols-1 align-items-center" key={index}>
                <div className="col">
                  <div className="counterup__banner--inner position__relative d-flex align-items-center justify-content-between">
                    <div className="counterup__banner--items text-center">
                      <h2 className="counterup__banner--items__text text-white">
                        YEARS OF <br />
                        FOUNDATION
                      </h2>
                      <span
                        className="counterup__banner--items__number js-counter text-white"
                        data-count="50"
                      >
                        {item.aboutus_estd}
                      </span>
                    </div>
                    <div className="counterup__banner--items text-center">
                      <h2 className="counterup__banner--items__text text-white">
                        SKILLED TEAM <br />
                        MEMBERS{" "}
                      </h2>
                      <span
                        className="counterup__banner--items__number js-counter text-white"
                        data-count="100"
                      >
                        {item.count_members}
                      </span>
                    </div>
                    <div className="counterup__banner--items text-center">
                      <h2 className="counterup__banner--items__text text-white">
                        HAPPY <br />
                        CUSTOMERS
                      </h2>
                      <span
                        className="counterup__banner--items__number js-counter text-white"
                        data-count="80"
                      >
                        {item.count_customers}
                      </span>
                    </div>
                    <div className="counterup__banner--items text-center">
                      <h2 className="counterup__banner--items__text text-white">
                        MONTHLY <br />
                        ORDERS
                      </h2>
                      <span
                        className="counterup__banner--items__number js-counter text-white"
                        data-count="70"
                      >
                        {item.count_orders}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};
