import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import defaultImage from "../../assets/img/vending-machine.png";
import { getAllVendingMachines } from "../../api/VendingMachine";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/components/pagination/pagination.min.css";
import { SliderSkelton } from "../Skeltons/SliderSkelton";
import SwiperCore, { Navigation } from "swiper";
import { NoVMFound } from "./NoVMFound";

SwiperCore.use([Navigation]);

export const NearByVMSlider = () => {
  const { showBoundary } = useErrorBoundary();
  const [featuredVMList, setFeaturedVMList] = useState([]);
  const [displayState, setDisplayState] = useState(false);
  useEffect(() => {
    getFeaturedBrands();
  }, []);
  const getFeaturedBrands = async () => {
    await getAllVendingMachines()
      .then((result) => {
        if (result.data.status === 1) {
          // console.log(result.data);
          setFeaturedVMList(result.data.vms);
          setDisplayState(true);
        }
      })
      .catch((error) => {
        showBoundary(error);
      });
  };
  return (
    <>
      {displayState ? (
        <section className="product__section product__categories--section mb-20">
          <div className="container">
            <div className="section__heading d-flex justify-content-between align-items-center mb-40">
              <h2 className="section__heading--maintitle font-18">
                Near by Vending Machines{" "}
              </h2>
              <div>
                <Link to="/all-vending-machines">
                  View All <i className="fa fa-angle-double-right fs-3"></i>
                </Link>
              </div>
            </div>
            {featuredVMList.length === 0 ? <NoVMFound /> : null}
            <Swiper
              effect={"fade"}
              centeredSlides={false}
              slidesPerView={12}
              spaceBetween={10}
              autoplay={false}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                },
                400: {
                  slidesPerView: 2,
                },
                639: {
                  slidesPerView: 2,
                },
                865: {
                  slidesPerView: 2,
                },
                1000: {
                  slidesPerView: 6,
                },
                1500: {
                  slidesPerView: 12,
                },
                1700: {
                  slidesPerView: 12,
                },
                2560: {
                  slidesPerView: 12,
                },
              }}
              navigation={featuredVMList.length > 12 ? true : false}
              className="product__section--inner categories2__swiper--activation swiper"
            >
              <div className="swiper-wrapper">
                {featuredVMList &&
                  featuredVMList.map((item) => (
                    <SwiperSlide className="swiper-slide " key={item.vm_id}>
                      <div className="categories2__product--items text-center">
                        {item.vm_images ? (
                          <div className="categories2__product--thumbnail">
                            <Link
                              className="categories2__product--link display-block "
                              to={`/vending-machine-detail/${item.vm_id}`}
                            >
                              <img
                                className="categories2__product--img display-block cat-img rounded-circle"
                                src={defaultImage}
                                alt={item.vm_name}
                                title={item.vm_name}
                              />
                            </Link>
                          </div>
                        ) : (
                          <div className="categories2__product--thumbnail">
                            <Link
                              className="categories2__product--link display-block"
                              to={`/vending-machine-detail/${item.vm_id}`}
                            >
                              <img
                                className="categories2__product--img display-block cat-img rounded-circle"
                                src={defaultImage}
                                alt={item.vm_name}
                                title={item.vm_name}
                              />
                            </Link>
                          </div>
                        )}

                        <div className="product__categories--content2">
                          <h3 className="product__categories--content2__maintitle product-list-title">
                            <Link to={`/vending-machine-detail/${item.vm_id}`}>
                              {item.vm_name}
                            </Link>
                          </h3>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
              </div>
            </Swiper>
          </div>
        </section>
      ) : (
        <SliderSkelton />
      )}
    </>
  );
};
