import React from "react";
import defaultImage from "../../assets/img/default.png";
import { Link } from "react-router-dom";

export const ProductCard = ({ item }) => {
  return (
    <div className="col mb-30">
      <div className="product__items product__items2">
        <div className="product__items--thumbnail">
          <Link
            className="product__items--link"
            to={`/product-details/${item.product_id}`}
          >
            {item.product_images.length !== 0 ? (
              <img
                className="product__items--img product__primary--img"
                src={item.product_images[0]}
                alt="product-img"
              />
            ) : (
              <img
                className="product__items--img product__primary--img"
                src={defaultImage}
                alt="product-img"
              />
            )}
          </Link>
          {/* <div className="product__badge">
            <span className="product__badge--items sale">
                Sale {item.product_discount} % Off
            </span>
            </div> */}
          <ul className="product__items--action">
            <li className="product__items--action__list">
              <a className="product__items--action__btn" href="#">
                <svg
                  className="product__items--action__btn--svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 
                    187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="32"
                  />
                </svg>
                <span className="visually-hidden">Wishlist</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="product__items--content product__items2--content text-center">
          <h3
            className="product__items--content__title product-list-title h4"
            title={item.product_title}
          >
            <Link to={`/product-details/${item.product_id}`}>
              {item.product_title}
            </Link>
          </h3>
          <div className="product__items--price">
            <span className="current__price">
              $ {item.product_actual_price}
            </span>
          </div>
          <div className="product__items--rating d-flex justify-content-center align-items-center">
            <ul className="d-flex">
              <li className="product__items--rating__list">
                <span className="product__items--rating__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10.105"
                    height="9.732"
                    viewBox="0 0 10.105 9.732"
                  >
                    <path
                      data-name="star - Copy"
                      d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,
                      0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,
                      9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                      transform="translate(0 -0.018)"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </li>
              <li className="product__items--rating__list">
                <span className="product__items--rating__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10.105"
                    height="9.732"
                    viewBox="0 0 10.105 9.732"
                  >
                    <path
                      data-name="star - Copy"
                      d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,
                      0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,
                      9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                      transform="translate(0 -0.018)"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </li>
              <li className="product__items--rating__list">
                <span className="product__items--rating__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10.105"
                    height="9.732"
                    viewBox="0 0 10.105 9.732"
                  >
                    <path
                      data-name="star - Copy"
                      d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,
                      0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,
                      9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                      transform="translate(0 -0.018)"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </li>
              <li className="product__items--rating__list">
                <span className="product__items--rating__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10.105"
                    height="9.732"
                    viewBox="0 0 10.105 9.732"
                  >
                    <path
                      data-name="star - Copy"
                      d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,
                      0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,
                      9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                      transform="translate(0 -0.018)"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </li>
              <li className="product__items--rating__list">
                <span className="product__items--rating__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10.105"
                    height="9.732"
                    viewBox="0 0 10.105 9.732"
                  >
                    <path
                      data-name="star - Copy"
                      d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,
                      0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,
                      9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                      transform="translate(0 -0.018)"
                      fill="#c7c5c2"
                    />
                  </svg>
                </span>
              </li>
            </ul>
            <span className="product__items--rating__count--number">(24)</span>
            {item.product_views === 0 ? (
              ""
            ) : (
              <span className="product__items--rating__count--number me-3">
                <i className="fa fa-eye"></i> {item.product_views}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
