import axios from "axios";

var backendUrl = process.env.REACT_APP_BACKEND_URL;

export async function getActiveHomeSliders(type) {
  return await axios.get(backendUrl + "slider_one/filter/" + type);
}

export async function getActiveOfferSliders(type) {
  return await axios.get(backendUrl + "slider_two/filter/" + type);
}
