import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useErrorBoundary } from "react-error-boundary";
import Loader from "../layouts/Loader";
import { enquiryForm } from "../api/WebsiteManagement";
import { getContactusData, getSocialMediaData } from "../api/WebsiteManagement";

export const ContactUs = () => {
  const { showBoundary } = useErrorBoundary();
  const [contactData, setContactData] = useState([]);
  const [socialIcons, setsocialIcons] = useState([]);
  const [displayState, setDisplayeState] = useState(false);
  useEffect(() => {
    getcontactData();
  }, []);
  const getcontactData = async () => {
    await getContactusData()
      .then((result) => {
        if (result.data.status === 1) {
          let contactusArray = result.data.contacts;
          setContactData(contactusArray);
          getSocialIcons();
        }
      })
      .catch((error) => {
        showBoundary(error);
      });
  };

  const getSocialIcons = async () => {
    await getSocialMediaData()
      .then((result) => {
        if (result.data.status === 1) {
          let socialIconsArray = result.data.medias;
          setsocialIcons(socialIconsArray);
          setDisplayeState(true);
        }
      })
      .catch((error) => {
        showBoundary(error);
      });
  };

  // Post Contact us APi
  const {
    register,
    handleSubmit,
    reset,
    onChange,
    onBlur,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      enquiry_first_name: "",
      enquiry_last_name: "",
      enquiry_phone_number: "",
      enquiry_email: "",
      enquiry_message: "",
    },
  });
  const contactFormData = async (data) => {
    console.log(data);
    if (data) {
      await enquiryForm(data)
        .then((result) => {
          if (result.data.status === 1) {
            reset();
            toast.success(result.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        })
        .catch((error) => {
          showBoundary(error);
        });
    }
  };
  const isValidEmail = (email) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );

  const handleEmailValidation = (email) => {
    const isValid = isValidEmail(email);

    const validityChanged =
      (errors.email && isValid) || (!errors.email && !isValid);
    // if (validityChanged) {
    //   console.log("Fire tracker with", isValid ? "Valid" : "Invalid");
    // }

    return isValid;
  };
  return (
    <>
      {" "}
      {displayState ? (
        <section className="contact__section section--padding pt-5">
          <div className="container">
            <div className="row mb-30">
              <div className="col">
                <div className="breadcrumb__content">
                  <ul className="breadcrumb__content--menu d-flex">
                    <li className="breadcrumb__content--menu__items">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb__content--menu__items text__primary">
                      <span>Contact us</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="section__heading text-center mb-40">
              <span className="section__heading--subtitle">
                Contact With Us
              </span>
              <h2 className="section__heading--maintitle">Get In Touch</h2>
            </div>
            <div className="main__contact--area position__relative">
              <div className="contact__form">
                <h3 className="contact__form--title mb-40">Contact us</h3>
                <form
                  className="contact__form--inner"
                  noValidate
                  onSubmit={handleSubmit(contactFormData)}
                >
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="contact__form--list mb-20">
                        <label
                          className="contact__form--label"
                          htmlFor="input1"
                        >
                          First Name{" "}
                          <span className="contact__form--label__star">*</span>
                        </label>
                        <input
                          className="contact__form--input"
                          name="firstname"
                          onChange={onChange}
                          onBlur={onBlur}
                          id="input1"
                          {...register("enquiry_first_name", {
                            required: true,
                            pattern: {
                              value: /^[a-zA-Z ]*$/,
                            },
                          })}
                          placeholder="Your First Name"
                          type="text"
                        />
                        {errors.enquiry_first_name?.type === "required" && (
                          <span className="mt-2 text__primary">
                            First Name is Needed
                          </span>
                        )}
                        {errors.enquiry_first_name?.type === "pattern" && (
                          <span className="mt-2 text__primary">
                            You can use Alphabets Only
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="contact__form--list mb-20">
                        <label
                          className="contact__form--label"
                          htmlFor="input2"
                        >
                          Last Name{" "}
                          <span className="contact__form--label__star">*</span>
                        </label>
                        <input
                          className="contact__form--input"
                          name="lastname"
                          onChange={onChange}
                          onBlur={onBlur}
                          id="input2"
                          {...register("enquiry_last_name", {
                            required: true,
                            pattern: {
                              value: /^[a-zA-Z ]*$/,
                            },
                          })}
                          placeholder="Your Last Name"
                          type="text"
                        />
                        {errors.enquiry_last_name?.type === "required" && (
                          <span className="mt-2 text__primary">
                            Last Name is Needed
                          </span>
                        )}
                        {errors.enquiry_last_name?.type === "pattern" && (
                          <span className="mt-2 text__primary">
                            You can use Alphabets Only
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="contact__form--list mb-20">
                        <label
                          className="contact__form--label"
                          htmlFor="input3"
                        >
                          Phone Number{" "}
                          <span className="contact__form--label__star">*</span>
                        </label>
                        <input
                          className="contact__form--input"
                          name="number"
                          id="input3"
                          onChange={onChange}
                          onBlur={onBlur}
                          placeholder="Phone number"
                          {...register("enquiry_phone_number", {
                            required: true,
                            valueAsNumber: false,
                            pattern: {
                              value: /^(0|[1-9]\d*)(\.\d+)?$/,
                            },
                          })}
                          type="number"
                        />
                        {errors.enquiry_phone_number?.type === "required" && (
                          <span className="mt-2 text__primary">
                            Phone Number is Needed
                          </span>
                        )}
                        {errors.enquiry_phone_number?.type === "pattern" && (
                          <span className="mt-2 text__primary">
                            Only Number Format
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="contact__form--list mb-20">
                        <label
                          className="contact__form--label"
                          htmlFor="input4"
                        >
                          Email{" "}
                          <span className="contact__form--label__star">*</span>
                        </label>
                        <input
                          className="contact__form--input"
                          name="email"
                          id="input4"
                          placeholder="Email"
                          {...register("enquiry_email", {
                            required: true,
                            validate: handleEmailValidation,
                          })}
                          type="text"
                        />
                        {errors.enquiry_email && (
                          <span className="mt-2 text__primary">
                            Please Enter Proper Email Address
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="contact__form--list mb-15">
                        <label
                          className="contact__form--label"
                          htmlFor="input5"
                        >
                          Write Your Message{" "}
                          <span className="contact__form--label__star">*</span>
                        </label>
                        <textarea
                          className="contact__form--textarea"
                          name="message"
                          id="input5"
                          placeholder="Write Your Message"
                          {...register("enquiry_message", {
                            required: true,
                          })}
                        ></textarea>
                        {errors.enquiry_message && (
                          <span className="mt-2 text__primary">
                            Please Provide your Valuable Message
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <button className="contact__form--btn btn" type="submit">
                    {" "}
                    <span>Submit Now</span>
                  </button>
                </form>
              </div>
              {contactData &&
                contactData.map((item) => (
                  <div
                    className="contact__info border-radius-5"
                    key={item.contact_id}
                  >
                    <div className="contact__info--items">
                      <h3 className="contact__info--content__title text-white mb-15">
                        Contact Us
                      </h3>
                      <div className="contact__info--items__inner d-flex">
                        <div className="contact__info--icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="31.568"
                            height="31.128"
                            viewBox="0 0 31.568 31.128"
                          >
                            <path
                              id="ic_phone_forwarded_24px"
                              d="M26.676,16.564l7.892-7.782L26.676,1V5.669H20.362v6.226h6.314Zm3.157,7a18.162,18.162,0,0,1-5.635-.887,1.627,1.627,0,0,0-1.61.374l-3.472,3.424a23.585,23.585,0,0,1-10.4-10.257l3.472-3.44a1.48,1.48,0,0,0,.395-1.556,17.457,17.457,0,0,1-.9-5.556A1.572,1.572,0,0,0,10.1,4.113H4.578A1.572,1.572,0,0,0,3,5.669,26.645,26.645,0,0,0,29.832,32.128a1.572,1.572,0,0,0,1.578-1.556V25.124A1.572,1.572,0,0,0,29.832,23.568Z"
                              transform="translate(-3 -1)"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div className="contact__info--content">
                          <p className="contact__info--content__desc text-white">
                            {item.contact_phone_primary ? (
                              <Link to={`tel:${item.contact_phone_primary}`}>
                                {item.contact_phone_primary}
                              </Link>
                            ) : null}
                            <br />
                            {item.contact_phone_secondary ? (
                              <Link to={`tel:${item.contact_phone_secondary}`}>
                                {item.contact_phone_secondary}
                              </Link>
                            ) : null}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="contact__info--items">
                      <h3 className="contact__info--content__title text-white mb-15">
                        Email Address
                      </h3>
                      <div className="contact__info--items__inner d-flex">
                        <div className="contact__info--icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="31.57"
                            height="31.13"
                            viewBox="0 0 31.57 31.13"
                          >
                            <path
                              id="ic_email_24px"
                              d="M30.413,4H5.157C3.421,4,2.016,5.751,2.016,7.891L2,31.239c0,2.14,1.421,3.891,3.157,3.891H30.413c1.736,0,3.157-1.751,3.157-3.891V7.891C33.57,5.751,32.149,4,30.413,4Zm0,7.783L17.785,21.511,5.157,11.783V7.891l12.628,9.728L30.413,7.891Z"
                              transform="translate(-2 -4)"
                              fill="currentColor"
                            />
                          </svg>
                        </div>
                        <div className="contact__info--content">
                          <p className="contact__info--content__desc text-white">
                            {item.contact_email_secondary ? (
                              <Link to={`mailto:${item.contact_email_primary}`}>
                                {item.contact_email_primary}
                              </Link>
                            ) : null}
                            <br />
                            {item.contact_email_secondary ? (
                              <Link
                                to={`mailto:${item.contact_email_secondary}`}
                              >
                                {item.contact_email_secondary}
                              </Link>
                            ) : null}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="contact__info--items">
                      <h3 className="contact__info--content__title text-white mb-15">
                        Office Location
                      </h3>
                      {item.contact_address_primary ? (
                        <div className="contact__info--items__inner d-flex align-items-center">
                          <div className="contact__info--icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="31.57"
                              height="31.13"
                              viewBox="0 0 31.57 31.13"
                            >
                              <path
                                id="ic_account_balance_24px"
                                d="M5.323,14.341V24.718h4.985V14.341Zm9.969,0V24.718h4.985V14.341ZM2,32.13H33.57V27.683H2ZM25.262,14.341V24.718h4.985V14.341ZM17.785,1,2,8.412v2.965H33.57V8.412Z"
                                transform="translate(-2 -1)"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                          <div className="contact__info--content">
                            <p className="contact__info--content__desc text-white">
                              {" "}
                              {item.contact_address_primary}
                            </p>
                          </div>
                        </div>
                      ) : null}

                      {item.contact_address_secondary ? (
                        <div className="contact__info--items__inner d-flex align-items-center">
                          <div className="contact__info--icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="31.57"
                              height="31.13"
                              viewBox="0 0 31.57 31.13"
                            >
                              <path
                                id="ic_account_balance_24px"
                                d="M5.323,14.341V24.718h4.985V14.341Zm9.969,0V24.718h4.985V14.341ZM2,32.13H33.57V27.683H2ZM25.262,14.341V24.718h4.985V14.341ZM17.785,1,2,8.412v2.965H33.57V8.412Z"
                                transform="translate(-2 -1)"
                                fill="currentColor"
                              />
                            </svg>
                          </div>
                          <div className="contact__info--content">
                            <p className="contact__info--content__desc text-white">
                              {" "}
                              {item.contact_address_secondary}
                            </p>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="contact__info--items">
                      <h3 className="contact__info--content__title text-white mb-15">
                        Follow Us
                      </h3>
                      <ul className="contact__info--social d-flex">
                        {socialIcons &&
                          socialIcons.map((item) => (
                            <li
                              className="contact__info--social__list"
                              key={item.media_id}
                            >
                              <a
                                className="contact__info--social__icon"
                                target="_blank"
                                href={item.media_url}
                                rel="noreferrer"
                              >
                                <img
                                  src={item.media_image}
                                  alt={item.media_title}
                                  title={item.media_title}
                                  className="social-icons"
                                />
                                <span className="visually-hidden">
                                  {item.media_title}
                                </span>
                              </a>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>
      ) : (
        <Loader />
      )}
    </>
  );
};
