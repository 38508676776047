/* eslint-disable import/first */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { FeaturedCategories } from "../components/FeaturedCategorySlider/FeaturedCategories";
import { FeaturedBrands } from "../components/Brands/FeaturedBrandsSlider";
import defaultImage from "../assets/img/productdefault.png";
import { ProductListSkelton } from "../components/Skeltons/ProductListSkelton";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/components/pagination/pagination.min.css";
import SwiperCore, { Navigation } from "swiper";
import { HeroSlider } from "../components/HeroSlider/Slider";
import { OfferSlider } from "../components/HeroSlider/OfferSlider";
import { getFeaturedSubCategoriesList } from "../api/SubCategoriesApi";
import { ShopSlider } from "../components/Shop/ShopSlider";
import { NearByVMSlider } from "../components/VendingMachines/NearByVMSlider";
import { AllProducts } from "../components/Products/AllProducts";
import { ProductCard } from "../components/Products/ProductCard";
import { useErrorBoundary } from "react-error-boundary";
import { NoProductsFound } from "../components/Products/NoProductsFound";

SwiperCore.use([Navigation]);

export const Home = () => {
  const { showBoundary } = useErrorBoundary();
  const [subCatProducts, setSubCatProducts] = useState([]);
  const getSubCatProducts = async () => {
    await getFeaturedSubCategoriesList()
      .then((result) => {
        if (result.data.status === 1) {
          // console.log(result.data.sub_categories)
          setSubCatProducts(result.data.sub_categories);
        }
      })
      .catch((error) => {
        showBoundary(error);
      });
  };

  useEffect(() => {
    getSubCatProducts();
  }, []);
  const [activeTab, setActiveTab] = useState(1);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <>
      {/* ============== Store SLider Start ===============*/}
      {/* <NearByStoresSlider /> */}
      <ShopSlider />
      {/* ============= Store SLider End ================= */}
      {/* ============= Vending Machine Slider start ================= */}
      <NearByVMSlider />
      {/* ============= Vending Machine Slider start ================= */}
      <AllProducts />
      {/* ============== Categories Start ===============*/}
      <FeaturedCategories />
      {/* ============= Categories End ================= */}
      {/*============= SLider Start =============*/}
      <HeroSlider />
      {/* ============= SLider Start  ==================*/}
      {/* =========== Top Products =========== */}
      <section className="product__section section--padding pt-0">
        <div className="container">
          <div className="section__heading text-center mb-40">
            <span className="section__heading--subtitle">
              Recently added our store
            </span>
            <h2 className="section__heading--maintitle">Top Rated Product</h2>
          </div>
          {subCatProducts.length === 0 ? (
            <div className="row">
              <div className="col-lg-12 text-center">
                <h4 className="fw-bold h2 text__primary">No Products Found</h4>
              </div>
            </div>
          ) : null}
          <div className="custom-navtabs">
            <ul className="nav nav-pills mb-35" id="pills-tab" role="tablist">
              {subCatProducts &&
                subCatProducts.map((item, index) => (
                  <>
                    {item.products.length === 0 ? (
                      ""
                    ) : (
                      <li
                        className="nav-item"
                        role="presentation"
                        key={item.sub_category_id}
                      >
                        <button
                          className={
                            activeTab === index ? "nav-link active" : "nav-link"
                          }
                          id={`pills-${item.sub_category_id}-tab`}
                          data-bs-toggle="pill"
                          data-bs-target={`#pills-${item.sub_category_id}`}
                          type="button"
                          role="tab"
                          aria-controls={`pills-${item.sub_category_id}`}
                          aria-selected="true"
                        >
                          {item.sub_category_title}
                        </button>
                      </li>
                    )}
                  </>
                ))}
            </ul>
          </div>
          <div className="tab-content" id="pills-tabContent">
            {subCatProducts &&
              subCatProducts.map((item, index) => (
                <div
                  className={
                    activeTab === index
                      ? "tab-pane fade show active"
                      : "tab-pane fade show"
                  }
                  id={`pills-${item.sub_category_id}`}
                  role="tabpanel"
                  aria-labelledby={`pills-${item.sub_category_id}-tab`}
                  key={item.sub_category_id}
                >
                  <div className="product__section--inner product__section--style3__inner mt-5 pt-5">
                    <div className="row row-cols-lg-4 row-cols-md-3 row-cols-2 mb--n28">
                      {item.products.length !== 0 &&
                        item.products.slice(0, 11).map((products, index) => {
                          return (
                            <div key={index}>
                              <ProductCard item={products} />
                            </div>
                          );
                        })}
                      {item.products.length === 0 && (
                        <div className="col-lg-12 col-sm-12 col-xs-12 mt-5">
                          <NoProductsFound />
                        </div>
                      )}
                      {item.products.length !== 0 &&
                      item.products.length >= 12 ? (
                        <div className="col mb-30">
                          <div className="product__categories--grid__items product__bg d-flex justify-content-center align-items-center h-100">
                            <div className="product__categories--grid__content">
                              <h3 className="product__categories--grid__content--maintitle">
                                <Link
                                  to={`/sub-categories-detail/${item.sub_category_id}`}
                                >
                                  View More
                                </Link>
                              </h3>
                              <span className="product__categories--grid__content--subtitle">
                                {item.products.length} items
                              </span>
                              <div className="product__categories--grid__icon text-center">
                                <Link
                                  className="product__categories--grid__icon--link"
                                  to={`/sub-categories-detail/${item.sub_category_id}`}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15.995"
                                    height="10.979"
                                    viewBox="0 0 15.995 10.979"
                                  >
                                    <path
                                      d="M212.706,299.839a.425.425,0,0,0,0-.6l-3.458-3.458a.425.425,0,0,1,0-.6l1.008-1.008a.425.425,0,0,1,.6,0l5.065,5.065a.425.425,0,0,1,0,.6l-5.065,5.066a.425.425,0,0,1-.6,0l-1.008-1.008a.425.425,0,0,1,0-.6Zm-6.305-.3a2.215,2.215,0,1,0,2.216-2.216A2.215,2.215,0,0,0,206.4,299.541Zm-3.634,0a1.6,1.6,0,1,0,1.6-1.605A1.6,1.6,0,0,0,202.767,299.541Zm-2.717,0a1.154,1.154,0,1,0,1.154-1.154A1.155,1.155,0,0,0,200.05,299.541Z"
                                      transform="translate(-200.05 -294.05)"
                                      fill="currentColor"
                                    />
                                  </svg>
                                  <span className="visually-hidden">
                                    categories icon
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
      {/* ======= Top Produts end ======*/}

      {/* Adds Section start*/}
      <OfferSlider />
      {/* Adds Section end */}
      {/* =========== Brands Products =========== */}
      <FeaturedBrands />
      {/* ======= Brands Produts end ======*/}
    </>
  );
};
