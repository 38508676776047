import shipimage from "../assets/img/other/shipping1.png";

export const OurServiceStrip = () => {
  return (
    <>
      <section className="shipping__section2 shipping__style3">
        <div className="container">
          <div className="shipping__section2--inner shipping__style3--inner d-flex justify-content-between">
            <div className="shipping__items2 d-flex align-items-center">
              <div className="shipping__items2--icon">
                <img
                  className="display-block"
                  src={shipimage}
                  alt="shipping img"
                />
              </div>
              <div className="shipping__items2--content">
                <h2 className="shipping__items2--content__title h3">
                  Shipping
                </h2>
                <p className="shipping__items2--content__desc">
                  From handpicked sellers
                </p>
              </div>
            </div>
            <div className="shipping__items2 d-flex align-items-center">
              <div className="shipping__items2--icon">
                <img
                  className="display-block"
                  src={shipimage}
                  alt="shipping img"
                />
              </div>
              <div className="shipping__items2--content">
                <h2 className="shipping__items2--content__title h3">Payment</h2>
                <p className="shipping__items2--content__desc">
                  Visa, Paypal, Master
                </p>
              </div>
            </div>
            <div className="shipping__items2 d-flex align-items-center">
              <div className="shipping__items2--icon">
                <img
                  className="display-block"
                  src={shipimage}
                  alt="shipping img"
                />
              </div>
              <div className="shipping__items2--content">
                <h2 className="shipping__items2--content__title h3">Return</h2>
                <p className="shipping__items2--content__desc">
                  30 day guarantee
                </p>
              </div>
            </div>
            <div className="shipping__items2 d-flex align-items-center">
              <div className="shipping__items2--icon">
                <img
                  className="display-block"
                  src={shipimage}
                  alt="shipping img"
                />
              </div>
              <div className="shipping__items2--content">
                <h2 className="shipping__items2--content__title h3">Support</h2>
                <p className="shipping__items2--content__desc">
                  Support every time
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
