import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import defaultImage from "../assets/img/default.png";
import { getSpecificSubCategoriesData } from "../api/SubCategoriesApi";
import { DetailPageSkelton } from "../components/Skeltons/DetailSkelton";
import { ProductListSkelton } from "../components/Skeltons/ProductListSkelton";
import "swiper/components/pagination/pagination.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { ProductCard } from "../components/Products/ProductCard";
import { useErrorBoundary } from "react-error-boundary";
SwiperCore.use([Navigation]);
var CryptoJS = require("crypto-js");

export const SubCategoriesDetail = () => {
  const { id } = useParams();
  const { showBoundary } = useErrorBoundary();
  const [subCategoryDetail, setsubCategoryDetail] = useState([]);
  const [displayState, setdisplayState] = useState(false);
  const [catData, setcatData] = useState([]);
  const [visible, setVisible] = useState(12);
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 12);
  };
  var categoryData = [];
  useEffect(() => {
    let catData = JSON.parse(sessionStorage.getItem("cat_data"));
    if (catData) {
      var bytes = CryptoJS.AES.decrypt(catData, "gokart@123");
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      if (decryptedData !== "") {
        setcatData(decryptedData);
      }
    }
    if (id) {
      getsubCategoryData(id);
    }
  }, [id]);
  var subCategoryArray = [];
  const getsubCategoryData = async () => {
    await getSpecificSubCategoriesData(id)
      .then((result) => {
        if (result.data.status === 1) {
          subCategoryArray.push(result.data.sub_category);
          setsubCategoryDetail(subCategoryArray);
          setdisplayState(true);
        }
      })
      .catch((error) => {
        showBoundary(error);
      });
  };

  const [imageIndex, setImageIndex] = useState(0);
  const getImage = (index) => {
    setImageIndex(index);
  };
  console.log("Cat data".catData);
  return (
    <>
      {displayState ? (
        <section className="product__section product__categories--section section--padding pt-5">
          <div className="container">
            {subCategoryDetail &&
              subCategoryDetail.map((item) => (
                <div key={item.sub_category_id}>
                  <div className="product__details--section section--padding pt-0">
                    <div className="container">
                      <div className="row mb-5">
                        <div className="col-lg-12">
                          <div className="breadcrumb__content">
                            <ul className="breadcrumb__content--menu d-flex">
                              <li className="breadcrumb__content--menu__items">
                                <Link to="/">Home</Link>
                              </li>
                              <li className="breadcrumb__content--menu__items">
                                <Link to="/all-categories">All Categories</Link>
                              </li>
                              {catData &&
                                catData.map((category, index) => {
                                  return (
                                    <li
                                      className="breadcrumb__content--menu__items"
                                      key={index}
                                    >
                                      <Link
                                        to={`/categories-detail/${category.category_id}`}
                                      >
                                        {category.category_title}
                                      </Link>
                                    </li>
                                  );
                                })}
                              <li className="breadcrumb__content--menu__items text__primary">
                                <span>{item.sub_category_title}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="row row-cols-lg-2 row-cols-md-2">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12 col-sm-12">
                          <div className="product__details--media">
                            <div className="product__media--preview__items">
                              <div
                                id="carouselIndicators"
                                className="carousel slide"
                                data-bs-ride="carousel"
                              >
                                <div className="carousel-inner">
                                  {item.sub_category_images &&
                                    item.sub_category_images.map(
                                      (subCatImage, index) => {
                                        return (
                                          <div
                                            className={
                                              index === imageIndex
                                                ? "carousel-item active"
                                                : "carousel-item"
                                            }
                                            key={index}
                                          >
                                            <img
                                              src={subCatImage}
                                              className="d-block w-100"
                                              alt={index}
                                            />
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                                <button
                                  className="carousel-control-prev"
                                  type="button"
                                  data-bs-target="#carouselIndicators"
                                  data-bs-slide="prev"
                                >
                                  <span
                                    className="carousel-control-prev-icon"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="visually-hidden">
                                    Previous
                                  </span>
                                </button>
                                <button
                                  className="carousel-control-next"
                                  type="button"
                                  data-bs-target="#carouselIndicators"
                                  data-bs-slide="next"
                                >
                                  <span
                                    className="carousel-control-next-icon"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="visually-hidden">Next</span>
                                </button>
                              </div>
                              <Swiper
                                effect={"fade"}
                                centeredSlides={false}
                                slidesPerView={6}
                                spaceBetween={30}
                                autoplay={false}
                                breakpoints={{
                                  0: {
                                    slidesPerView: 1,
                                  },
                                  400: {
                                    slidesPerView: 2,
                                  },
                                  639: {
                                    slidesPerView: 2,
                                  },
                                  865: {
                                    slidesPerView: 2,
                                  },
                                  1000: {
                                    slidesPerView: 3,
                                  },
                                  1500: {
                                    slidesPerView: 4,
                                  },
                                  1700: {
                                    slidesPerView: 5,
                                  },
                                  2560: {
                                    slidesPerView: 5,
                                  },
                                }}
                                navigation={true}
                                className="product__media--nav swiper"
                              >
                                <div className="swiper-wrapper">
                                  {item.sub_category_images.length === 0 ? (
                                    <div className="d-flex justify-content-center align-items-center mb-40">
                                      <h2 className="section__heading--maintitle text__primary">
                                        No images Found
                                      </h2>
                                    </div>
                                  ) : (
                                    item.sub_category_images.map(
                                      (subCatImage, index) => {
                                        return (
                                          <SwiperSlide
                                            className="swiper-slide "
                                            key={index}
                                            onClick={() => {
                                              getImage(index);
                                            }}
                                          >
                                            <div className="product__media--nav__items">
                                              <img
                                                className="product__media--nav__items--img"
                                                src={subCatImage}
                                                alt={index}
                                              />
                                            </div>
                                          </SwiperSlide>
                                        );
                                      }
                                    )
                                  )}
                                </div>
                              </Swiper>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12 col-sm-12">
                          <div className="product__details--info">
                            <h2 className="product__details--info__title mb-15">
                              {item.sub_category_title}
                            </h2>
                            <p className="product__details--info__desc mb-20">
                              {item.sub_category_description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {item.products.length !== 0 ? (
                    <div className="section__heading d-flex justify-content-between align-items-center mb-40">
                      <h2 className="section__heading--maintitle">
                        {item.sub_category_title} Products{" "}
                      </h2>
                    </div>
                  ) : null}
                  <div className="row row-md-reverse">
                    <div className="col-xl-12 col-lg-12">
                      <div className="shop__product--wrapper">
                        <div className="tab_content">
                          <div
                            id="product_grid"
                            className="tab_pane active show"
                          >
                            <div className="product__section--inner product__section--style3__inner">
                              <div className="row row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-3 row-cols-sm-3 row-cols-2 mb--n30">
                                {item.products.length === 0 ? (
                                  <div className="d-flex justify-content-center align-items-center mb-40">
                                    <h2 className="section__heading--maintitle text__primary">
                                      No Products Found
                                    </h2>
                                  </div>
                                ) : (
                                  item.products
                                    .slice(0, visible)
                                    .map((products, index) => {
                                      return (
                                        <div key={index}>
                                          <ProductCard item={products} />
                                        </div>
                                      );
                                    })
                                )}
                              </div>
                              {item.products.length > 12 ? (
                                <div className="row mt-5 pt-5">
                                  <div className="col d-flex justify-content-center align-items-center">
                                    <button
                                      className="btn btn-success"
                                      onClick={showMoreItems}
                                    >
                                      Load More
                                    </button>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </section>
      ) : (
        <div>
          <DetailPageSkelton />
          <div className="container">
            <div className="product__section--inner product__section--style3__inner">
              <div className="row row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-3 row-cols-sm-3 row-cols-2 mb--n30">
                <ProductListSkelton />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
