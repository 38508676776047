import { Link, useLocation } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";

import navLogo from "../assets/img/logo/nav-log.png";
import currencyIcon from "../assets/img/icon/usd-icon.png";
import locationIcon from "../assets/img/icon/location.png";
import { getSocialMediaData } from "../api/WebsiteManagement";
import { getAllCategoriesData } from "../api/CategoriesApi";
// import { geusertLocation} from '../api/WebsiteManagement';
import { locationPinCode } from "../App";
import { useErrorBoundary } from "react-error-boundary";

const Header = () => {
  const location = useLocation();
  const [hideElements, setHideElements] = useState("");

  const [catshow, setShow] = useState(false);
  const { showBoundary } = useErrorBoundary();
  const [mobileSidebarShow, setmobileSidebarShow] = useState(false);
  const [mobileSearch, setMobileSearch] = useState(false);
  const [mobileCart, setMobileCart] = useState(false);
  const [subCatShow, setsubCatShow] = useState(false);
  const [productShow, setProductShow] = useState(false);

  // API
  const [socialIcons, setSocialIcons] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [subCatIndex, setSubCatIndex] = useState(0);
  const [productIndex, setproductIndex] = useState(0);

  useEffect(() => {
    getSocialIcons();
    getCategoriesData();
    // getLocation();
  }, [hideElements]);

  const getSocialIcons = async () => {
    await getSocialMediaData()
      .then((result) => {
        if (result.data.status === 1) {
          let socialIconsArray = result.data.medias;
          setSocialIcons(socialIconsArray);
          // setmediaDisplayState(true);
        }
      })
      .catch((error) => {
        showBoundary(error);
      });
  };

  const getCategoriesData = async () => {
    await getAllCategoriesData()
      .then((result) => {
        if (result.data.status === 1) {
          let allCategoriesArray = result.data.categories;
          // console.log(allCategoriesArray);
          setCategoriesData(allCategoriesArray);
          // setCatDisplayState(true);
        }
      })
      .catch((error) => {
        showBoundary(error);
      });
  };

  const drodownHandler = () => {
    setShow(!catshow);
    setsubCatShow(!subCatShow);
    setProductShow(!productShow);
  };

  // const subCategoriesShow = () =>{
  //   setsubCatShow(!subCatShow)
  // }
  // var locationArray = [];
  const [locationData, setLocationData] = useContext(locationPinCode);
  // console.log(locationData);
  //  const getLocation = async () =>{
  //    await geusertLocation().then((result)=>{
  //     // console.log(result.data)
  //     if(result.data.status === 1){
  //       locationArray.push(result.data);
  //       setLocationData(locationArray)
  //     }
  //    })
  //  }
  // SEARCH HANDLER
  // var searchInput;
  const [searchInput, setSearchInput] = useState();
  const [searchDropdown, setSearchDropdown] = useState(false);
  const searchHandler = (value) => {
    setSearchInput(value);
    //  console.log('Input value', searchInput);
    const pathname = location.pathname;
    let shopPath = "/all-shops";
    let vmPath = "/all-vending-machines";
    let catPath = "/all-categories";
    let brandPath = "/all-brands";
    let generalPath = "/all-products";
    if (pathname === shopPath) {
      setHideElements("shops");
    } else if (pathname === vmPath) {
      setHideElements("vms");
    } else if (pathname === catPath) {
      setHideElements("cat");
    } else if (pathname === brandPath) {
      setHideElements("brand");
    } else if (pathname === generalPath) {
      setHideElements("general");
    }
  };
  return (
    <>
      <header className="header__section header__transparent">
        <div className="header__topbar bg__primary2">
          <div className="container">
            <div className="header__topbar--inner d-flex align-items-center justify-content-between">
              <p className="wellcome__text text-white m-0">
                Welcome to Vending Grocery!
              </p>
              <div className="header__topbar--right d-flex align-items-center">
                <div className="language__currency d-none d-lg-block">
                  <ul className="d-flex align-items-center">
                    <li className="language__currency--list">
                      <span
                        className="account__currency--link text-white"
                        href="#"
                      >
                        <img src={locationIcon} alt="currency" />
                        {locationData &&
                          locationData.map((item, index) => (
                            <span key={index}>
                              <span>{item.zip_code}</span>
                            </span>
                          ))}
                      </span>
                    </li>
                    <li className="language__currency--list">
                      <a
                        className="account__currency--link text-white"
                        href="#"
                      >
                        <img src={currencyIcon} alt="currency" />
                        <span> USD </span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="9.797"
                          height="6.05"
                          viewBox="0 0 9.797 6.05"
                        >
                          <path
                            d="M14.646,8.59,10.9,12.329,7.151,8.59,6,9.741l4.9,4.9,4.9-4.9Z"
                            transform="translate(-6 -8.59)"
                            fill="currentColor"
                            opacity="0.7"
                          />
                        </svg>
                      </a>
                      <div className="dropdown__currency">
                        <ul>
                          <li className="currency__items">
                            <a className="currency__text" href="#">
                              CAD
                            </a>
                          </li>
                          <li className="currency__items">
                            <a className="currency__text" href="#">
                              CNY
                            </a>
                          </li>
                          <li className="currency__items">
                            <a className="currency__text" href="#">
                              EUR
                            </a>
                          </li>
                          <li className="currency__items">
                            <a className="currency__text" href="#">
                              GBP
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
                <ul className="header__widget--social d-flex">
                  {socialIcons &&
                    socialIcons.map((item) => (
                      <li
                        className="header__widget--social__list"
                        key={item.media_id}
                      >
                        <a
                          className="header__widget--social__icon"
                          target="_blank"
                          href={item.media_url}
                        >
                          <img
                            src={item.media_image}
                            alt={item.media_title}
                            title={item.media_title}
                          />
                          <span className="visually-hidden">
                            {item.media_title}
                          </span>
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="main__header header__sticky">
          <div className="container">
            <div className="main__header--inner position__relative d-flex justify-content-between align-items-center">
              <div className="offcanvas__header--menu__open ">
                <span
                  className="offcanvas__header--menu__open--btn cursor-pointer"
                  onClick={() => {
                    setmobileSidebarShow(!mobileSidebarShow);
                    document.body.classList.add("mobile_menu_open");
                  }}
                  data-offcanvas
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ionicon offcanvas__header--menu__open--svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      strokeWidth="32"
                      d="M80 160h352M80 256h352M80 352h352"
                    />
                  </svg>
                  <span className="visually-hidden">Offcanvas Menu Open</span>
                </span>
              </div>
              <div className="main__logo">
                <h1 className="main__logo--title">
                  <Link className="main__logo--link" to="/">
                    <img
                      className="main__logo--img"
                      src={navLogo}
                      width={194}
                      alt="logo-img"
                    />
                  </Link>
                </h1>
              </div>
              <div className="header__search--widget d-none d-lg-block header__sticky--none">
                <form className="d-flex header__search--form" action="#">
                  <div className="header__search--box">
                    <label>
                      <input
                        className="header__search--input"
                        placeholder="Search Products"
                        type="text"
                        value={searchInput}
                        onChange={(e) => {
                          searchHandler(e.target.value);
                          setSearchDropdown(!searchDropdown);
                        }}
                      />
                    </label>
                    <button
                      className="header__search--button bg__secondary text-white"
                      type="submit"
                    >
                      Search
                    </button>
                  </div>
                </form>
                {searchDropdown && (
                  <div style={{ position: "relative" }}>
                    <ul className="header__search_dropdown">
                      {searchInput == "" ? null : (
                        <li className="header__sub--menu__items">
                          <a className="header__sub--menu__link">
                            "{searchInput}"
                          </a>
                        </li>
                      )}

                      {hideElements === "shops" ? (
                        ""
                      ) : (
                        <li className="header__sub--menu__items">
                          <Link
                            to={`/all-shops/${searchInput}`}
                            className="header__sub--menu__link"
                            onClick={() => {
                              setSearchDropdown(!searchDropdown);
                            }}
                          >
                            Search by{" "}
                            <span className="bg__secondary px-3 py-1 rounded fs-4 text-white">
                              Shops
                            </span>
                          </Link>
                        </li>
                      )}
                      {hideElements === "vms" ? (
                        ""
                      ) : (
                        <li className="header__sub--menu__items">
                          <Link
                            className="header__sub--menu__link"
                            to={`/all-vending-machines/${searchInput}`}
                            onClick={() => {
                              setSearchDropdown(!searchDropdown);
                            }}
                          >
                            Search by{" "}
                            <span className="bg__secondary px-3 py-1 rounded fs-4 text-white">
                              Vending Machines
                            </span>
                          </Link>
                        </li>
                      )}
                      {hideElements === "brand" ? (
                        ""
                      ) : (
                        <li className="header__sub--menu__items">
                          <Link
                            className="header__sub--menu__link"
                            to={`/all-brands/${searchInput}`}
                            onClick={() => {
                              setSearchDropdown(!searchDropdown);
                            }}
                          >
                            Search by{" "}
                            <span className="bg__secondary px-3 py-1 rounded fs-4 text-white">
                              Brands
                            </span>
                          </Link>
                        </li>
                      )}
                      {hideElements === "cat" ? (
                        ""
                      ) : (
                        <li className="header__sub--menu__items">
                          <Link
                            className="header__sub--menu__link"
                            to={`/all-categories/${searchInput}`}
                            onClick={() => {
                              setSearchDropdown(!searchDropdown);
                            }}
                          >
                            Search by{" "}
                            <span className="bg__secondary px-3 py-1 rounded fs-4 text-white">
                              Categories
                            </span>
                          </Link>
                        </li>
                      )}
                      {hideElements === "general" ? (
                        ""
                      ) : (
                        <li className="header__sub--menu__items">
                          <Link
                            to={`/all-products/${searchInput}`}
                            className="header__sub--menu__link"
                            onClick={() => {
                              setSearchDropdown(!searchDropdown);
                            }}
                          >
                            Search by{" "}
                            <span className="bg__secondary px-3 py-1 rounded fs-4 text-white">
                              General
                            </span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
              <div className="header__menu d-none d-lg-block header__sticky--block">
                <nav className="header__menu--navigation">
                  <ul className="d-flex">
                    <li className="header__menu--items">
                      <Link className="header__menu--link" to="/faq">
                        FAQ{" "}
                      </Link>
                    </li>
                    <li className="header__menu--items">
                      <Link className="header__menu--link" to="/about-us">
                        About us{" "}
                      </Link>
                    </li>
                    <li className="header__menu--items">
                      <Link className="header__menu--link" to="/contact-us">
                        Contact us
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
              {/* ================================== */}
              <div className="header__account header__sticky--none">
                <ul className="d-flex">
                  <li className="header__account--items d-none d-lg-block">
                    <Link className="header__account--btn" to="/login">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.51"
                        height="19.443"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="32"
                        />
                        <path
                          d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
                          fill="none"
                          stroke="currentColor"
                          strokeMiterlimit="10"
                          strokeWidth="32"
                        />
                      </svg>
                      <span className="visually-hidden">My account</span>
                    </Link>
                  </li>
                  <li className="header__account--items  header__account--search__items mobile__d--block d-sm-2-none">
                    <a
                      className="header__account--btn search__open--btn"
                      href="#"
                      data-offcanvas
                    >
                      <svg
                        className="product__items--action__btn--svg"
                        xmlns="http://www.w3.org/2000/svg"
                        width="22.51"
                        height="20.443"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
                          fill="none"
                          stroke="currentColor"
                          strokeMiterlimit="10"
                          strokeWidth="32"
                        />
                        <path
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          strokeWidth="32"
                          d="M338.29 338.29L448 448"
                        />
                      </svg>
                      <span className="visually-hidden">Search</span>
                    </a>
                  </li>
                  <li className="header__account--items">
                    <Link
                      className="header__account--btn minicart__open--btn"
                      to="/cart"
                      data-offcanvas
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.706"
                        height="15.534"
                        viewBox="0 0 14.706 13.534"
                      >
                        <g transform="translate(0 0)">
                          <g>
                            <path
                              data-name="Path 16787"
                              d="M4.738,472.271h7.814a.434.434,0,0,0,.414-.328l1.723-6.316a.466.466,0,0,0-.071-.4.424.424,0,0,0-.344-.179H3.745L3.437,463.6a.435.435,0,0,0-.421-.353H.431a.451.451,0,0,0,0,.9h2.24c.054.257,1.474,6.946,1.555,7.33a1.36,1.36,0,0,0-.779,1.242,1.326,1.326,0,0,0,1.293,1.354h7.812a.452.452,0,0,0,0-.9H4.74a.451.451,0,0,1,0-.9Zm8.966-6.317-1.477,5.414H5.085l-1.149-5.414Z"
                              transform="translate(0 -463.248)"
                              fill="#fefefe"
                            />
                            <path
                              data-name="Path 16788"
                              d="M5.5,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,5.5,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,6.793,478.352Z"
                              transform="translate(-1.191 -466.622)"
                              fill="#fefefe"
                            />
                            <path
                              data-name="Path 16789"
                              d="M13.273,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,13.273,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,14.566,478.352Z"
                              transform="translate(-2.875 -466.622)"
                              fill="#fefefe"
                            />
                          </g>
                        </g>
                      </svg>
                      <span className="items__count">5</span>
                    </Link>
                  </li>
                  <li className="header__account--items d-none d-lg-block">
                    <Link className="header__account--btn" to="/wish-list">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18.541"
                        height="15.557"
                        viewBox="0 0 18.541 15.557"
                      >
                        <path
                          d="M71.775,135.51a5.153,5.153,0,0,1,1.267-1.524,4.986,4.986,0,0,1,6.584.358,4.728,4.728,0,0,1,1.174,4.914,10.458,10.458,0,0,1-2.132,3.808,22.591,22.591,0,0,1-5.4,4.558c-.445.282-.9.549-1.356.812a.306.306,0,0,1-.254.013,25.491,25.491,0,0,1-6.279-4.8,11.648,11.648,0,0,1-2.52-4.009,4.957,4.957,0,0,1,.028-3.787,4.629,4.629,0,0,1,3.744-2.863,4.782,4.782,0,0,1,5.086,2.447c.013.019.025.034.057.076Z"
                          transform="translate(-62.498 -132.915)"
                          fill="currentColor"
                        />
                      </svg>
                      <span className="items__count">3</span>
                    </Link>
                  </li>
                </ul>
              </div>
              {/* ================================== */}
              <div className="header__account header__sticky--block">
                <ul className="d-flex">
                  <li className="header__account--items  header__account--search__items d-sm-2-none">
                    <a
                      className="header__account--btn search__open--btn"
                      href="#"
                      data-offcanvas
                    >
                      <svg
                        className="product__items--action__btn--svg"
                        xmlns="http://www.w3.org/2000/svg"
                        width="22.51"
                        height="20.443"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
                          fill="none"
                          stroke="currentColor"
                          strokeMiterlimit="10"
                          strokeWidth="32"
                        />
                        <path
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeMiterlimit="10"
                          strokeWidth="32"
                          d="M338.29 338.29L448 448"
                        />
                      </svg>
                      <span className="visually-hidden">Search</span>
                    </a>
                  </li>
                  <li className="header__account--items d-none d-lg-block">
                    <Link className="header__account--btn" to="/wish-list">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18.541"
                        height="15.557"
                        viewBox="0 0 18.541 15.557"
                      >
                        <path
                          d="M71.775,135.51a5.153,5.153,0,0,1,1.267-1.524,4.986,4.986,0,0,1,6.584.358,4.728,4.728,0,0,1,1.174,4.914,10.458,10.458,0,0,1-2.132,3.808,22.591,22.591,0,0,1-5.4,4.558c-.445.282-.9.549-1.356.812a.306.306,0,0,1-.254.013,25.491,25.491,0,0,1-6.279-4.8,11.648,11.648,0,0,1-2.52-4.009,4.957,4.957,0,0,1,.028-3.787,4.629,4.629,0,0,1,3.744-2.863,4.782,4.782,0,0,1,5.086,2.447c.013.019.025.034.057.076Z"
                          transform="translate(-62.498 -132.915)"
                          fill="currentColor"
                        />
                      </svg>
                      <span className="items__count">3</span>
                    </Link>
                  </li>
                  <li className="header__account--items d-none d-lg-block">
                    <Link className="header__account--btn" to="/login">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.51"
                        height="19.443"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="32"
                        />
                        <path
                          d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
                          fill="none"
                          stroke="currentColor"
                          strokeMiterlimit="10"
                          strokeWidth="32"
                        />
                      </svg>
                      <span className="visually-hidden">My account</span>
                    </Link>
                  </li>
                  <li className="header__account--items">
                    <Link
                      className="header__account--btn minicart__open--btn"
                      to="/cart"
                      data-offcanvas
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.706"
                        height="15.534"
                        viewBox="0 0 14.706 13.534"
                      >
                        <g transform="translate(0 0)">
                          <g>
                            <path
                              data-name="Path 16787"
                              d="M4.738,472.271h7.814a.434.434,0,0,0,.414-.328l1.723-6.316a.466.466,0,0,0-.071-.4.424.424,0,0,0-.344-.179H3.745L3.437,463.6a.435.435,0,0,0-.421-.353H.431a.451.451,0,0,0,0,.9h2.24c.054.257,1.474,6.946,1.555,7.33a1.36,1.36,0,0,0-.779,1.242,1.326,1.326,0,0,0,1.293,1.354h7.812a.452.452,0,0,0,0-.9H4.74a.451.451,0,0,1,0-.9Zm8.966-6.317-1.477,5.414H5.085l-1.149-5.414Z"
                              transform="translate(0 -463.248)"
                              fill="#fefefe"
                            />
                            <path
                              data-name="Path 16788"
                              d="M5.5,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,5.5,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,6.793,478.352Z"
                              transform="translate(-1.191 -466.622)"
                              fill="#fefefe"
                            />
                            <path
                              data-name="Path 16789"
                              d="M13.273,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,13.273,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,14.566,478.352Z"
                              transform="translate(-2.875 -466.622)"
                              fill="#fefefe"
                            />
                          </g>
                        </g>
                      </svg>
                      <span className="items__count">5</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Bottom Header  */}
        <div className="header__bottom bg__secondary">
          <div className="container">
            <div className="header__bottom--inner position__relative d-flex align-items-center">
              <div className="categories__menu">
                <div
                  className="categories__menu--header text-white d-flex align-items-center"
                  onClick={drodownHandler}
                >
                  <svg
                    className="categories__list--icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="21.007"
                    height="16.831"
                    viewBox="0 0 21.007 16.831"
                  >
                    <path
                      id="listine-dots"
                      d="M20.66,99.786a1.036,1.036,0,0,0-.347-.13H4.227a2.013,2.013,0,0,1,0,3.012q7.988,0,15.976,0h.063a.7.7,0,0,0,.454-.162.9.9,0,0,0,.286-.452v-1.765A.861.861,0,0,0,20.66,99.786ZM3.323,101.162A1.662,1.662,0,1,1,1.662,99.5,1.661,1.661,0,0,1,3.323,101.162Zm16.99,3H4.227a2.013,2.013,0,0,1,0,3.012q7.988,0,15.976,0h.063a.7.7,0,0,0,.454-.164.9.9,0,0,0,.286-.452v-1.765a.861.861,0,0,0-.347-.5A1.082,1.082,0,0,0,20.314,104.161Zm-16.99,1.506a1.662,1.662,0,1,1-1.662-1.662A1.663,1.663,0,0,1,3.323,105.668Zm16.99,3H4.227a2.013,2.013,0,0,1,0,3.012q7.988,0,15.976,0h.063a.7.7,0,0,0,.454-.164.9.9,0,0,0,.286-.45v-1.767a.861.861,0,0,0-.347-.5A1.083,1.083,0,0,0,20.314,108.663Zm-16.99,1.506a1.662,1.662,0,1,1-1.662-1.662A1.663,1.663,0,0,1,3.323,110.169Zm16.99,2.993H4.227a2.013,2.013,0,0,1,0,3.012q7.988,0,15.976,0h.063a.687.687,0,0,0,.454-.162.9.9,0,0,0,.286-.452v-1.765a.861.861,0,0,0-.347-.5A1.035,1.035,0,0,0,20.314,113.163Zm-16.99,1.506a1.662,1.662,0,1,1-1.662-1.662A1.661,1.661,0,0,1,3.323,114.669Z"
                      transform="translate(0 -99.5)"
                      fill="currentColor"
                    />
                  </svg>
                  <span className="categories__menu--title">
                    All Categories
                  </span>
                  <svg
                    className="categories__arrowdown--icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12.355"
                    height="8.394"
                    viewBox="0 0 10.355 6.394"
                  >
                    <path
                      d="M15.138,8.59l-3.961,3.952L7.217,8.59,6,9.807l5.178,5.178,5.178-5.178Z"
                      transform="translate(-6 -8.59)"
                      fill="currentColor"
                    />
                  </svg>
                </div>
                {catshow && (
                  <div className="dropdown__categories--menu active">
                    <ul className="d-none d-lg-block">
                      {categoriesData &&
                        categoriesData.map((item, index) => (
                          <li
                            className="categories__menu--items"
                            key={item.category_id}
                          >
                            <Link
                              className="categories__menu--link"
                              to={`/categories-detail/${item.category_id}`}
                              onClick={drodownHandler}
                            >
                              <svg
                                className="categories__menu--svgicon"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  d="M408 64H104a56.16 56.16 0 00-56 56v192a56.16 56.16 0 0056 56h40v80l93.72-78.14a8 8 0 015.13-1.86H408a56.16 56.16 0 0056-56V120a56.16 56.16 0 00-56-56z"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeLinejoin="round"
                                  strokeWidth="32"
                                />
                              </svg>{" "}
                              {item.category_title}
                              {item.sub_categories.length !== 0 ? (
                                <svg
                                  className="categories__menu--right__arrow--icon"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="17.007"
                                  height="16.831"
                                  viewBox="0 0 512 512"
                                >
                                  <path
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="48"
                                    d="M184 112l144 144-144 144"
                                  />
                                </svg>
                              ) : null}
                            </Link>
                            {item.sub_categories.length !== 0 ? (
                              <ul className="categories__submenu">
                                {item.sub_categories.length !== 0 &&
                                  item.sub_categories.map(
                                    (subcategorie, index) => {
                                      return (
                                        <li
                                          className="categories__submenu--items"
                                          key={subcategorie.sub_category_id}
                                        >
                                          <Link
                                            className="categories__submenu--items__text"
                                            to={`/sub-categories-detail/${subcategorie.sub_category_id}`}
                                            onClick={drodownHandler}
                                          >
                                            {subcategorie.sub_category_title}
                                            {subcategorie.products.length !==
                                            0 ? (
                                              <svg
                                                className="categories__menu--right__arrow--icon"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="17.007"
                                                height="16.831"
                                                viewBox="0 0 512 512"
                                              >
                                                <path
                                                  fill="none"
                                                  stroke="currentColor"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                  strokeWidth="48"
                                                  d="M184 112l144 144-144 144"
                                                />
                                              </svg>
                                            ) : null}
                                          </Link>
                                          <ul className="categories__submenu--child">
                                            {subcategorie.products.length !==
                                              0 &&
                                              subcategorie.products.map(
                                                (products, index) => {
                                                  return (
                                                    <li
                                                      className="categories__submenu--child__items"
                                                      key={products.product_id}
                                                      onClick={drodownHandler}
                                                    >
                                                      <Link
                                                        className="categories__submenu--child__items--link"
                                                        to={`/product-details/${products.product_id}`}
                                                      >
                                                        {products.product_title}
                                                      </Link>
                                                    </li>
                                                  );
                                                }
                                              )}
                                          </ul>
                                        </li>
                                      );
                                    }
                                  )}
                              </ul>
                            ) : null}
                          </li>
                        ))}
                      <li className="categories__menu--items">
                        <Link
                          className="categories__menu--link"
                          to="/all-products"
                          onClick={drodownHandler}
                        >
                          <svg
                            className="categories__menu--svgicon"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              d="M408 64H104a56.16 56.16 0 00-56 56v192a56.16 56.16 0 0056 56h40v80l93.72-78.14a8 8 0 015.13-1.86H408a56.16 56.16 0 0056-56V120a56.16 56.16 0 00-56-56z"
                              fill="none"
                              stroke="currentColor"
                              strokeLinejoin="round"
                              strokeWidth="32"
                            />
                          </svg>{" "}
                          View All products
                        </Link>
                      </li>
                    </ul>
                    <nav className="category__mobile--menu">
                      <ul className="category__mobile--menu_ul">
                        {categoriesData &&
                          categoriesData.map((categories, index) => (
                            <li
                              className={
                                subCatIndex === index
                                  ? "categories__menu--items active"
                                  : "categories__menu--items"
                              }
                              key={categories.category_id}
                            >
                              <Link
                                className="categories__menu--link"
                                to={`/categories-detail/${categories.category_id}`}
                                onClick={drodownHandler}
                              >
                                <svg
                                  className="categories__menu--svgicon"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                >
                                  <path
                                    d="M408 64H104a56.16 56.16 0 00-56 56v192a56.16 56.16 0 0056 56h40v80l93.72-78.14a8 8 0 015.13-1.86H408a56.16 56.16 0 0056-56V120a56.16 56.16 0 00-56-56z"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeLinejoin="round"
                                    strokeWidth="32"
                                  />
                                </svg>{" "}
                                {categories.category_title}
                              </Link>
                              {categories.sub_categories.length !== 0 ? (
                                <button
                                  className={
                                    subCatShow && subCatIndex === index
                                      ? "category__sub--menu_toggle active"
                                      : "category__sub--menu_toggle"
                                  }
                                  onClick={() => {
                                    setsubCatShow(!subCatShow);
                                    setSubCatIndex(index);
                                  }}
                                ></button>
                              ) : null}
                              <ul
                                className="category__sub--menu"
                                style={
                                  (subCatShow && subCatIndex) === index
                                    ? {
                                        boxSizing: "border-box",
                                        display: "block",
                                      }
                                    : null
                                }
                              >
                                {categories.sub_categories.length !== 0 &&
                                  categories.sub_categories.map(
                                    (subCategories, index) => {
                                      return (
                                        <li
                                          className={
                                            productIndex === index
                                              ? "categories__submenu--items active"
                                              : "categories__submenu--items"
                                          }
                                          key={subCategories.sub_category_id}
                                        >
                                          <Link
                                            className="categories__submenu--items__text"
                                            to={`/sub-categories-detail/${subCategories.sub_category_id}`}
                                            onClick={drodownHandler}
                                          >
                                            <svg
                                              className="categories__menu--svgicon"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 512 512"
                                            >
                                              <circle
                                                cx="256"
                                                cy="184"
                                                r="120"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeLinejoin="round"
                                                strokeWidth="32"
                                              ></circle>
                                              <circle
                                                cx="344"
                                                cy="328"
                                                r="120"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeLinejoin="round"
                                                strokeWidth="32"
                                              ></circle>
                                              <circle
                                                cx="168"
                                                cy="328"
                                                r="120"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeLinejoin="round"
                                                strokeWidth="32"
                                              ></circle>
                                            </svg>{" "}
                                            {""}
                                            {subCategories.sub_category_title}
                                          </Link>
                                          {subCategories.products.length !==
                                          0 ? (
                                            <button
                                              className={
                                                productShow &&
                                                productIndex === index
                                                  ? "category__sub--menu_toggle active"
                                                  : "category__sub--menu_toggle"
                                              }
                                              onClick={() => {
                                                setProductShow(!productShow);
                                                setproductIndex(index);
                                              }}
                                            ></button>
                                          ) : null}
                                          <ul
                                            className="category__sub--menu"
                                            style={
                                              (productShow && productIndex) ===
                                              index
                                                ? {
                                                    boxSizing: "border-box",
                                                    display: "block",
                                                  }
                                                : null
                                            }
                                          >
                                            {subCategories.products.length !==
                                              0 &&
                                              subCategories.products.map(
                                                (products, index) => {
                                                  return (
                                                    <li
                                                      className="categories__submenu--child__items product-list-title"
                                                      key={products.product_id}
                                                    >
                                                      <Link
                                                        className="categories__submenu--child__items--link"
                                                        to={`/product-details/${products.product_id}`}
                                                        onClick={drodownHandler}
                                                      >
                                                        {products.product_title}{" "}
                                                      </Link>
                                                    </li>
                                                  );
                                                }
                                              )}
                                          </ul>
                                        </li>
                                      );
                                    }
                                  )}
                              </ul>
                            </li>
                          ))}

                        {/* <li className="categories__menu--items">
                          <a className="categories__menu--link" href="shop.html">
                            <svg
                              className="categories__menu--svgicon"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                d="M215.08 156.92c-4.89-24-10.77-56.27-10.77-73.23A51.36 51.36 0 01256 32h0c28.55 0 51.69 23.69 51.69 51.69 0 16.5-5.85 48.95-10.77 73.23M215.08 355.08c-4.91 24.06-10.77 56.16-10.77 73.23A51.36 51.36 0 00256 480h0c28.55 0 51.69-23.69 51.69-51.69 0-16.54-5.85-48.93-10.77-73.23M355.08 215.08c24.06-4.91 56.16-10.77 73.23-10.77A51.36 51.36 0 01480 256h0c0 28.55-23.69 51.69-51.69 51.69-16.5 0-48.95-5.85-73.23-10.77M156.92 215.07c-24-4.89-56.25-10.76-73.23-10.76A51.36 51.36 0 0032 256h0c0 28.55 23.69 51.69 51.69 51.69 16.5 0 48.95-5.85 73.23-10.77"
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeMiterlimit="10"
                                strokeWidth="32"
                              />
                              <path
                                d="M296.92 156.92c13.55-20.48 32.3-47.25 44.37-59.31a51.35 51.35 0 0173.1 0h0c20.19 20.19 19.8 53.3 0 73.1-11.66 11.67-38.67 30.67-59.31 44.37M156.92 296.92c-20.48 13.55-47.25 32.3-59.31 44.37a51.35 51.35 0 000 73.1h0c20.19 20.19 53.3 19.8 73.1 0 11.67-11.66 30.67-38.67 44.37-59.31M355.08 296.92c20.48 13.55 47.25 32.3 59.31 44.37a51.35 51.35 0 010 73.1h0c-20.19 20.19-53.3 19.8-73.1 0-11.69-11.69-30.66-38.65-44.37-59.31M215.08 156.92c-13.53-20.43-32.38-47.32-44.37-59.31a51.35 51.35 0 00-73.1 0h0c-20.19 20.19-19.8 53.3 0 73.1 11.61 11.61 38.7 30.68 59.31 44.37"
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeMiterlimit="10"
                                strokeWidth="32"
                              />
                              <circle
                                cx="256"
                                cy="256"
                                r="64"
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeMiterlimit="10"
                                strokeWidth="32"
                              />
                            </svg>{" "}
                            Vegetables
                          </a>
                        </li>
                        <li className="categories__menu--items">
                          <a className="categories__menu--link" href="shop.html">
                            <svg
                              className="categories__menu--svgicon"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                d="M408 64H104a56.16 56.16 0 00-56 56v192a56.16 56.16 0 0056 56h40v80l93.72-78.14a8 8 0 015.13-1.86H408a56.16 56.16 0 0056-56V120a56.16 56.16 0 00-56-56z"
                                fill="none"
                                stroke="currentColor"
                                strokeLinejoin="round"
                                strokeWidth="32"
                              />
                            </svg>{" "}
                            Organics
                          </a>
                          <ul className="category__sub--menu">
                            <li className="categories__submenu--items">
                              <a
                                className="categories__submenu--items__text"
                                href="shop.html"
                              >
                                Hot Offers
                              </a>
                              <ul className="category__sub--menu">
                                <li className="categories__submenu--child__items">
                                  <a
                                    className="categories__submenu--child__items--link"
                                    href="shop.html"
                                  >
                                    Apple Juice{" "}
                                  </a>
                                </li>
                                <li className="categories__submenu--child__items">
                                  <a
                                    className="categories__submenu--child__items--link"
                                    href="shop.html"
                                  >
                                    Oil and Vinegar{" "}
                                  </a>
                                </li>
                                <li className="categories__submenu--child__items">
                                  <a
                                    className="categories__submenu--child__items--link"
                                    href="shop.html"
                                  >
                                    Bread and Juice{" "}
                                  </a>
                                </li>
                                <li className="categories__submenu--child__items">
                                  <a
                                    className="categories__submenu--child__items--link"
                                    href="shop.html"
                                  >
                                    AFresh Seafood{" "}
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li className="categories__submenu--items">
                              <a
                                className="categories__submenu--items__text"
                                href="shop.html"
                              >
                                Fresh Meat
                              </a>
                              <ul className="category__sub--menu">
                                <li className="categories__submenu--child__items">
                                  <a
                                    className="categories__submenu--child__items--link"
                                    href="shop.html"
                                  >
                                    Apple Juice{" "}
                                  </a>
                                </li>
                                <li className="categories__submenu--child__items">
                                  <a
                                    className="categories__submenu--child__items--link"
                                    href="shop.html"
                                  >
                                    Oil and Vinegar{" "}
                                  </a>
                                </li>
                                <li className="categories__submenu--child__items">
                                  <a
                                    className="categories__submenu--child__items--link"
                                    href="shop.html"
                                  >
                                    Bread and Juice{" "}
                                  </a>
                                </li>
                                <li className="categories__submenu--child__items">
                                  <a
                                    className="categories__submenu--child__items--link"
                                    href="shop.html"
                                  >
                                    AFresh Seafood{" "}
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li className="categories__submenu--items">
                              <a
                                className="categories__submenu--items__text"
                                href="shop.html"
                              >
                                Nature Food
                              </a>
                              <ul className="category__sub--menu">
                                <li className="categories__submenu--child__items">
                                  <a
                                    className="categories__submenu--child__items--link"
                                    href="shop.html"
                                  >
                                    Apple Juice{" "}
                                  </a>
                                </li>
                                <li className="categories__submenu--child__items">
                                  <a
                                    className="categories__submenu--child__items--link"
                                    href="shop.html"
                                  >
                                    Oil and Vinegar{" "}
                                  </a>
                                </li>
                                <li className="categories__submenu--child__items">
                                  <a
                                    className="categories__submenu--child__items--link"
                                    href="shop.html"
                                  >
                                    Bread and Juice{" "}
                                  </a>
                                </li>
                                <li className="categories__submenu--child__items">
                                  <a
                                    className="categories__submenu--child__items--link"
                                    href="shop.html"
                                  >
                                    AFresh Seafood{" "}
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li className="categories__submenu--items">
                              <a
                                className="categories__submenu--items__text"
                                href="shop.html"
                              >
                                Laura Mercier
                              </a>
                              <ul className="category__sub--menu">
                                <li className="categories__submenu--child__items">
                                  <a
                                    className="categories__submenu--child__items--link"
                                    href="shop.html"
                                  >
                                    Apple Juice{" "}
                                  </a>
                                </li>
                                <li className="categories__submenu--child__items">
                                  <a
                                    className="categories__submenu--child__items--link"
                                    href="shop.html"
                                  >
                                    Oil and Vinegar{" "}
                                  </a>
                                </li>
                                <li className="categories__submenu--child__items">
                                  <a
                                    className="categories__submenu--child__items--link"
                                    href="shop.html"
                                  >
                                    Bread and Juice{" "}
                                  </a>
                                </li>
                                <li className="categories__submenu--child__items">
                                  <a
                                    className="categories__submenu--child__items--link"
                                    href="shop.html"
                                  >
                                    AFresh Seafood{" "}
                                  </a>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li className="categories__menu--items">
                          <a className="categories__menu--link" href="shop.html">
                            <svg
                              className="categories__menu--svgicon"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <circle
                                cx="256"
                                cy="184"
                                r="120"
                                fill="none"
                                stroke="currentColor"
                                strokeLinejoin="round"
                                strokeWidth="32"
                              />
                              <circle
                                cx="344"
                                cy="328"
                                r="120"
                                fill="none"
                                stroke="currentColor"
                                strokeLinejoin="round"
                                strokeWidth="32"
                              />
                              <circle
                                cx="168"
                                cy="328"
                                r="120"
                                fill="none"
                                stroke="currentColor"
                                strokeLinejoin="round"
                                strokeWidth="32"
                              />
                            </svg>{" "}
                            Beauty & Care
                          </a>
                        </li>
                        <li className="categories__menu--items">
                          <a className="categories__menu--link" href="shop.html">
                            <svg
                              className="categories__menu--svgicon"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="32"
                                strokeLinejoin="round"
                                d="M240 152c-50.71 12.21-94.15 52.31-120.3 73.43a261.14 261.14 0 00-23.81-19.58C59.53 179.29 16 176 16 176s11.37 51.53 41.36 79.83C27.37 284.14 16 335.67 16 335.67s43.53-3.29 79.89-29.85a259.18 259.18 0 0023.61-19.41c26.1 21.14 69.74 61.34 120.5 73.59l-16 56c39.43-6.67 78.86-35.51 94.72-48.25C448 362 496 279 496 256c0-22-48-106-176.89-111.73C303.52 131.78 263.76 102.72 224 96z"
                              />
                              <circle cx="416" cy="239.99" r="16" />
                              <path
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="32"
                                strokeLinecap="round"
                                strokeMiterlimit="20"
                                d="M378.37 356a199.22 199.22 0 010-200"
                              />
                            </svg>{" "}
                            Fresh Fish
                          </a>
                        </li>
                        <li className="categories__menu--items">
                          <a className="categories__menu--link" href="shop.html">
                            <svg
                              className="categories__menu--svgicon"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                d="M321.89 171.42C233 114 141 155.22 56 65.22c-19.8-21-8.3 235.5 98.1 332.7 77.79 71 197.9 63.08 238.4-5.92s18.28-163.17-70.61-220.58zM173 253c86 81 175 129 292 147"
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="32"
                              />
                            </svg>{" "}
                            Nature
                          </a>
                        </li>
                        <li className="categories__menu--items">
                          <a className="categories__menu--link" href="shop.html">
                            <svg
                              className="categories__menu--svgicon"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <circle
                                cx="256"
                                cy="184"
                                r="120"
                                fill="none"
                                stroke="currentColor"
                                strokeLinejoin="round"
                                strokeWidth="32"
                              />
                              <circle
                                cx="344"
                                cy="328"
                                r="120"
                                fill="none"
                                stroke="currentColor"
                                strokeLinejoin="round"
                                strokeWidth="32"
                              />
                              <circle
                                cx="168"
                                cy="328"
                                r="120"
                                fill="none"
                                stroke="currentColor"
                                strokeLinejoin="round"
                                strokeWidth="32"
                              />
                            </svg>{" "}
                            Fresh Berries
                          </a>
                        </li>
                        <li className="categories__menu--items">
                          <a className="categories__menu--link" href="shop.html">
                            <svg
                              className="categories__menu--svgicon"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                d="M322 416c0 35.35-20.65 64-56 64H134c-35.35 0-56-28.65-56-64M336 336c17.67 0 32 17.91 32 40h0c0 22.09-14.33 40-32 40H64c-17.67 0-32-17.91-32-40h0c0-22.09 14.33-40 32-40"
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeMiterlimit="10"
                                strokeWidth="32"
                              />
                              <path
                                d="M344 336H179.31a8 8 0 00-5.65 2.34l-26.83 26.83a4 4 0 01-5.66 0l-26.83-26.83a8 8 0 00-5.65-2.34H56a24 24 0 01-24-24h0a24 24 0 0124-24h288a24 24 0 0124 24h0a24 24 0 01-24 24zM64 276v-.22c0-55 45-83.78 100-83.78h72c55 0 100 29 100 84v-.22M241 112l7.44 63.97"
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeMiterlimit="10"
                                strokeWidth="32"
                              />
                              <path
                                d="M256 480h139.31a32 32 0 0031.91-29.61L463 112"
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeMiterlimit="10"
                                strokeWidth="32"
                              />
                              <path
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="32"
                                d="M368 112l16-64 47-16"
                              />
                              <path
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeMiterlimit="10"
                                strokeWidth="32"
                                d="M224 112h256"
                              />
                            </svg>{" "}
                            Bread & Bakery
                          </a>
                        </li> */}
                      </ul>
                    </nav>
                  </div>
                )}
              </div>
              <div className="header__right--area d-flex justify-content-between align-items-center">
                <div className="header__menu">
                  <nav className="header__menu--navigation">
                    <ul className="d-flex">
                      <li className="header__menu--items">
                        <Link
                          className="header__menu--link text-white"
                          to="/faq"
                        >
                          FAQ{" "}
                        </Link>
                      </li>
                      <li className="header__menu--items">
                        <Link
                          className="header__menu--link text-white"
                          to="/about-us"
                        >
                          About us{" "}
                        </Link>
                      </li>
                      <li className="header__menu--items">
                        <Link
                          className="header__menu--link text-white"
                          to="/contact-us"
                        >
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="header__right--info d-flex align-items-center">
                  <div className="suport__contact d-flex align-items-center">
                    <svg
                      className="suport__contact--icon text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      width="36.725"
                      height="36.743"
                      viewBox="0 0 36.725 36.743"
                    >
                      <path
                        id="headphone-alt-2"
                        d="M28.893,18.469c-.026-2.873.1-5.754-.761-8.565-1.587-5.21-5.306-7.742-10.781-7.272-4.681.4-7.588,2.715-8.785,7.573a24.031,24.031,0,0,0,.2,13.3,11.447,11.447,0,0,0,6.254,7.253c.658.3,1.091.408,1.595-.356a3.732,3.732,0,0,1,4.38-1.334,3.931,3.931,0,1,1-4.582,5.82,2.989,2.989,0,0,0-1.782-1.466c-4.321-1.573-6.842-4.869-8.367-9.032a1.686,1.686,0,0,0-1.238-1.275,7.046,7.046,0,0,1-3.718-2.447A5.739,5.739,0,0,1,3.242,11.83,5.338,5.338,0,0,0,6.318,7.957C7.644,3.033,11.62.193,16.845.02a19.923,19.923,0,0,1,6.324.544c4.479,1.3,6.783,4.52,7.72,8.881a1.966,1.966,0,0,0,1.389,1.723,6.235,6.235,0,0,1,4.439,6.324,5.211,5.211,0,0,1-1.33,3.27,7.98,7.98,0,0,1-5.449,2.774c-.731.077-1.124-.051-1.069-.952.085-1.367.022-2.745.026-4.115Z"
                        transform="translate(0.006 0.01)"
                        fill="currentColor"
                      />
                    </svg>
                    <p className="suport__contact--text text-white">
                      <span className="suport__text--24">24/7 Suport</span>
                      <a
                        className="suport__contact--number"
                        href="tel:+919036600966"
                      >
                        +91 90366 00966 
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Mobile menu --> */}
        {mobileSidebarShow && (
          <div className="offcanvas__header open">
            <div className="offcanvas__inner">
              <div className="offcanvas__logo">
                <a className="offcanvas__logo_link" href="index.html">
                  <img
                    src={navLogo}
                    alt="Grocee Logo"
                    width="158"
                    height="36"
                  />
                </a>
                <button
                  className="offcanvas__close--btn"
                  data-offcanvas
                  onClick={() => {
                    setmobileSidebarShow(false);
                    document.body.classList.remove("mobile_menu_open");
                  }}
                >
                  close
                </button>
              </div>
              <nav className="offcanvas__menu">
                <ul className="offcanvas__menu_ul">
                  <li className="offcanvas__menu_li">
                    <Link className="offcanvas__menu_item" to="/faq">
                      F.A.Q
                    </Link>
                  </li>
                  <li className="offcanvas__menu_li">
                    <Link className="offcanvas__menu_item" to="/about-us">
                      About Us
                    </Link>
                  </li>
                  <li className="offcanvas__menu_li">
                    <Link className="offcanvas__menu_item" to="/contact-us">
                      Contact Us
                    </Link>
                  </li>
                </ul>
                <div className="offcanvas__account--items">
                  <Link
                    className="offcanvas__account--items__btn d-flex align-items-center"
                    to="/login"
                  >
                    <span className="offcanvas__account--items__icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.51"
                        height="19.443"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="32"
                        />
                        <path
                          d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
                          fill="none"
                          stroke="currentColor"
                          strokeMiterlimit="10"
                          strokeWidth="32"
                        />
                      </svg>
                    </span>
                    <span className="offcanvas__account--items__label">
                      Login / Register
                    </span>
                  </Link>
                </div>
              </nav>
            </div>
          </div>
        )}
        {/* <!-- Mobile menu  End --> */}

        {/* <!-- Mobile stikcy toolbar --> */}
        <div className="offcanvas__stikcy--toolbar">
          <ul className="d-flex justify-content-between">
            <li className="offcanvas__stikcy--toolbar__list">
              <Link className="offcanvas__stikcy--toolbar__btn" to="/">
                <span className="offcanvas__stikcy--toolbar__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    width="21.51"
                    height="21.443"
                    viewBox="0 0 22 17"
                  >
                    <path
                      fill="currentColor"
                      d="M20.9141 7.93359c.1406.11719.2109.26953.2109.45703 0 .14063-.0469.25782-.1406.35157l-.3516.42187c-.1172.14063-.2578.21094-.4219.21094-.1406 0-.2578-.04688-.3515-.14062l-.9844-.77344V15c0 .3047-.1172.5625-.3516.7734-.2109.2344-.4687.3516-.7734.3516h-4.5c-.3047 0-.5742-.1172-.8086-.3516-.2109-.2109-.3164-.4687-.3164-.7734v-3.6562h-2.25V15c0 .3047-.11719.5625-.35156.7734-.21094.2344-.46875.3516-.77344.3516h-4.5c-.30469 0-.57422-.1172-.80859-.3516-.21094-.2109-.31641-.4687-.31641-.7734V8.46094l-.94922.77344c-.11719.09374-.24609.14062-.38672.14062-.16406 0-.30468-.07031-.42187-.21094l-.35157-.42187C.921875 8.625.875 8.50781.875 8.39062c0-.1875.070312-.33984.21094-.45703L9.73438.832031C10.1094.527344 10.5312.375 11 .375s.8906.152344 1.2656.457031l8.6485 7.101559zm-3.7266 6.50391V7.05469L11 1.99219l-6.1875 5.0625v7.38281h3.375v-3.6563c0-.3046.10547-.5624.31641-.7734.23437-.23436.5039-.35155.80859-.35155h3.375c.3047 0 .5625.11719.7734.35155.2344.211.3516.4688.3516.7734v3.6563h3.375z"
                    ></path>
                  </svg>
                </span>
                <span className="offcanvas__stikcy--toolbar__label">Home</span>
              </Link>
            </li>
            <li className="offcanvas__stikcy--toolbar__list">
              <Link className="offcanvas__stikcy--toolbar__btn" to="/all-shops">
                <span className="offcanvas__stikcy--toolbar__icon">
                  <svg
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.51"
                    height="17.443"
                    viewBox="0 0 448 512"
                  >
                    <path d="M416 32H32A32 32 0 0 0 0 64v384a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32V64a32 32 0 0 0-32-32zm-16 48v152H248V80zm-200 0v152H48V80zM48 432V280h152v152zm200 0V280h152v152z"></path>
                  </svg>
                </span>
                <span className="offcanvas__stikcy--toolbar__label">Shop</span>
              </Link>
            </li>
            <li className="offcanvas__stikcy--toolbar__list ">
              <span
                className="offcanvas__stikcy--toolbar__btn search__open--btn"
                onClick={() => {
                  document.body.classList.add("predictive__search--box_active");
                  setMobileSearch(true);
                }}
                data-offcanvas
              >
                <span className="offcanvas__stikcy--toolbar__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22.51"
                    height="20.443"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
                      fill="none"
                      stroke="currentColor"
                      strokeMiterlimit="10"
                      strokeWidth="32"
                    />
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      strokeWidth="32"
                      d="M338.29 338.29L448 448"
                    />
                  </svg>
                </span>
                <span className="offcanvas__stikcy--toolbar__label">
                  Search
                </span>
              </span>
            </li>
            <li className="offcanvas__stikcy--toolbar__list">
              <Link
                className="offcanvas__stikcy--toolbar__btn minicart__open--btn"
                to="/cart"
                data-offcanvas
              >
                <span className="offcanvas__stikcy--toolbar__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.51"
                    height="15.443"
                    viewBox="0 0 18.51 15.443"
                  >
                    <path
                      d="M79.963,138.379l-13.358,0-.56-1.927a.871.871,0,0,0-.6-.592l-1.961-.529a.91.91,0,0,0-.226-.03.864.864,0,0,0-.226,1.7l1.491.4,3.026,10.919a1.277,1.277,0,1,0,1.844,1.144.358.358,0,0,0,0-.049h6.163c0,.017,0,.034,0,.049a1.277,1.277,0,1,0,1.434-1.267c-1.531-.247-7.783-.55-7.783-.55l-.205-.8h7.8a.9.9,0,0,0,.863-.651l1.688-5.943h.62a.936.936,0,1,0,0-1.872Zm-9.934,6.474H68.568c-.04,0-.1.008-.125-.085-.034-.118-.082-.283-.082-.283l-1.146-4.037a.061.061,0,0,1,.011-.057.064.064,0,0,1,.053-.025h1.777a.064.064,0,0,1,.063.051l.969,4.34,0,.013a.058.058,0,0,1,0,.019A.063.063,0,0,1,70.03,144.853Zm3.731-4.41-.789,4.359a.066.066,0,0,1-.063.051h-1.1a.064.064,0,0,1-.063-.051l-.789-4.357a.064.064,0,0,1,.013-.055.07.07,0,0,1,.051-.025H73.7a.06.06,0,0,1,.051.025A.064.064,0,0,1,73.76,140.443Zm3.737,0L76.26,144.8a.068.068,0,0,1-.063.049H74.684a.063.063,0,0,1-.051-.025.064.064,0,0,1-.013-.055l.973-4.357a.066.066,0,0,1,.063-.051h1.777a.071.071,0,0,1,.053.025A.076.076,0,0,1,77.5,140.448Z"
                      transform="translate(-62.393 -135.3)"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                <span className="offcanvas__stikcy--toolbar__label">Cart</span>
                <span className="items__count">3</span>
              </Link>
            </li>
            <li className="offcanvas__stikcy--toolbar__list">
              <Link className="offcanvas__stikcy--toolbar__btn" to="/wish-list">
                <span className="offcanvas__stikcy--toolbar__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.541"
                    height="15.557"
                    viewBox="0 0 18.541 15.557"
                  >
                    <path
                      d="M71.775,135.51a5.153,5.153,0,0,1,1.267-1.524,4.986,4.986,0,0,1,6.584.358,4.728,4.728,0,0,1,1.174,4.914,10.458,10.458,0,0,1-2.132,3.808,22.591,22.591,0,0,1-5.4,4.558c-.445.282-.9.549-1.356.812a.306.306,0,0,1-.254.013,25.491,25.491,0,0,1-6.279-4.8,11.648,11.648,0,0,1-2.52-4.009,4.957,4.957,0,0,1,.028-3.787,4.629,4.629,0,0,1,3.744-2.863,4.782,4.782,0,0,1,5.086,2.447c.013.019.025.034.057.076Z"
                      transform="translate(-62.498 -132.915)"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                <span className="offcanvas__stikcy--toolbar__label">
                  Wishlist
                </span>
                <span className="items__count">3</span>
              </Link>
            </li>
          </ul>
        </div>
        {/* <!-- Mobile stikcy toolbar --> */}

        {/* <!-- Cart SIdebar minicart --> */}
        {mobileCart && (
          <div className="offCanvas__minicart">
            <div className="minicart__header ">
              <div className="minicart__header--top d-flex justify-content-between align-items-center">
                <h3 className="minicart__title"> Shopping Cart</h3>
                <button
                  className="minicart__close--btn"
                  aria-label="minicart close btn"
                  data-offcanvas
                >
                  <svg
                    className="minicart__close--icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="32"
                      d="M368 368L144 144M368 144L144 368"
                    />
                  </svg>
                </button>
              </div>
              <p className="minicart__header--desc">
                The organic foods products are limited
              </p>
            </div>
            <div className="minicart__product">
              <div className="minicart__product--items d-flex">
                <div className="minicart__thumb">
                  <a href="product-details.html">
                    <img
                      src="assets/img/product/product1.png"
                      alt="prduct-img"
                    />
                  </a>
                </div>
                <div className="minicart__text">
                  <h4 className="minicart__subtitle">
                    <a href="product-details.html">The is Garden Vegetable.</a>
                  </h4>
                  <span className="color__variant">
                    <b>Color:</b> Beige
                  </span>
                  <div className="minicart__price">
                    <span className="current__price">$125.00</span>
                    <span className="old__price">$140.00</span>
                  </div>
                  <div className="minicart__text--footer d-flex align-items-center">
                    <div className="quantity__box minicart__quantity">
                      <button
                        type="button"
                        className="quantity__value decrease"
                        aria-label="quantity value"
                        value="Decrease Value"
                      >
                        -
                      </button>
                      <label>
                        <input
                          type="number"
                          className="quantity__number"
                          value="1"
                          data-counter
                        />
                      </label>
                      <button
                        type="button"
                        className="quantity__value increase"
                        aria-label="quantity value"
                        value="Increase Value"
                      >
                        +
                      </button>
                    </div>
                    <button className="minicart__product--remove" type="button">
                      Remove
                    </button>
                  </div>
                </div>
              </div>
              <div className="minicart__product--items d-flex">
                <div className="minicart__thumb">
                  <a href="product-details.html">
                    <img
                      src="assets/img/product/product2.png"
                      alt="prduct-img"
                    />
                  </a>
                </div>
                <div className="minicart__text">
                  <h4 className="minicart__subtitle">
                    <a href="product-details.html">Fresh Tomatoe is organic.</a>
                  </h4>
                  <span className="color__variant">
                    <b>Color:</b> Green
                  </span>
                  <div className="minicart__price">
                    <span className="current__price">$115.00</span>
                    <span className="old__price">$130.00</span>
                  </div>
                  <div className="minicart__text--footer d-flex align-items-center">
                    <div className="quantity__box minicart__quantity">
                      <button
                        type="button"
                        className="quantity__value decrease"
                        aria-label="quantity value"
                        value="Decrease Value"
                      >
                        -
                      </button>
                      <label>
                        <input
                          type="number"
                          className="quantity__number"
                          value="1"
                          data-counter
                        />
                      </label>
                      <button
                        type="button"
                        className="quantity__value increase"
                        aria-label="quantity value"
                        value="Increase Value"
                      >
                        +
                      </button>
                    </div>
                    <button className="minicart__product--remove" type="button">
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="minicart__amount">
              <div className="minicart__amount_list d-flex justify-content-between">
                <span>Sub Total:</span>
                <span>
                  <b>$240.00</b>
                </span>
              </div>
              <div className="minicart__amount_list d-flex justify-content-between">
                <span>Total:</span>
                <span>
                  <b>$240.00</b>
                </span>
              </div>
            </div>
            <div className="minicart__conditions text-center">
              <input
                className="minicart__conditions--input"
                id="accept"
                type="checkbox"
              />
              <label className="minicart__conditions--label" htmlFor="accept">
                I agree with the{" "}
                <a
                  className="minicart__conditions--link"
                  href="privacy-policy.html"
                >
                  Privacy Policy
                </a>
              </label>
            </div>
            <div className="minicart__button d-flex justify-content-center">
              <a className="btn minicart__button--link" href="cart.html">
                View cart
              </a>
              <a className="btn minicart__button--link" href="checkout.html">
                Checkout
              </a>
            </div>
          </div>
        )}
        {/* <!-- Cart SIdebar minicart --> */}

        {/* <!-- Start serch box area --> */}
        {mobileSearch && (
          <div className="predictive__search--box active">
            <div className="predictive__search--box__inner">
              <h2 className="predictive__search--title">Search Products</h2>
              <form className="predictive__search--form" action="#">
                <label>
                  <input
                    className="predictive__search--input"
                    placeholder="Search Products"
                    type="text"
                  />
                </label>
                <button
                  className="predictive__search--button"
                  aria-label="search button"
                >
                  <svg
                    className="product__items--action__btn--svg"
                    xmlns="http://www.w3.org/2000/svg"
                    width="30.51"
                    height="25.443"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
                      fill="none"
                      stroke="currentColor"
                      strokeMiterlimit="10"
                      strokeWidth="32"
                    />
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      strokeWidth="32"
                      d="M338.29 338.29L448 448"
                    />
                  </svg>{" "}
                </button>
              </form>
            </div>
            <button
              className="predictive__search--close__btn"
              aria-label="search close"
              onClick={() => {
                document.body.classList.remove(
                  "predictive__search--box_active"
                );
                setMobileSearch(false);
              }}
              data-offcanvas
            >
              <svg
                className="predictive__search--close__icon"
                xmlns="http://www.w3.org/2000/svg"
                width="40.51"
                height="30.443"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="32"
                  d="M368 368L144 144M368 144L144 368"
                />
              </svg>
            </button>
          </div>
        )}
        {/* <!-- End serch box area --> */}
      </header>
    </>
  );
};

export default Header;
