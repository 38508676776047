import axios from "axios";

var backendUrl = process.env.REACT_APP_BACKEND_URL;

export async function getProductbyId(productId) {
  return await axios.get(backendUrl + "product/" + productId);
}

export async function getproductList() {
  return await axios.get(backendUrl + "product/products/available/");
}

export async function getallFeaturedProdcuts() {
  return await axios.get(backendUrl + "product/products/featured/1");
}
