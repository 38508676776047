import axios from "axios";

var backendUrl = process.env.REACT_APP_BACKEND_URL;

export async function getFilteredBrands(type) {
  return await axios.get(backendUrl + "brand/brands/" + type);
}

export async function getAllBrands() {
  return await axios.get(backendUrl + "brand");
}

export async function getSpecificBrand(id) {
  return await axios.get(backendUrl + "brand/" + id);
}
