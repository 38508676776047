import { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { data } from "jquery";

import { locationPinCode } from "../App";
import { subScribeNewsLetter } from "../api/WebsiteManagement";
import newsletterPopupImage from "../assets/img/banner/newsletter-popup-thumb.png";

export const Newsettler = () => {
  const [popupShow, setpopupShow] = useState(false);
  const [zipcodemanual, setZipcodeManual] = useState(false);

  const [locationData, setLocationData] = useContext(locationPinCode);
  useEffect(() => {
    setTimeout(() => {
      document.body.classList.add("overlay__active");
      setpopupShow(true);
    }, 3000);
  }, []);
  const closePopup = () => {
    document.body.classList.remove("overlay__active");
    setpopupShow(false);
  };
  const {
    register,
    handleSubmit,
    reset,
    onBlur,
    onChange,
    formState: { errors },
  } = useForm({
    defaultValues: {
      subscriber_email: "",
    },
  });

  const subScriberEmail = async (data) => {
    await subScribeNewsLetter(data)
      .then((result) => {
        if (result.data.status === 1) {
          toast.success(result.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          reset();
          closePopup();
        } else {
          toast.warning(result.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // console.log(subscribeForm.data);
  };

  const isValidEmail = (email) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );

  const handleEmailValidation = (email) => {
    const isValid = isValidEmail(email);
    const validityChanged =
      (errors.email && isValid) || (!errors.email && !isValid);
    if (validityChanged) {
    }

    return isValid;
  };

  const zipcodeFunction = async (data) => {
    console.log(data);
  };
  return (
    <>
      {popupShow && (
        <div
          className="newsletter__popup newsletter__show animate__animated animate__slideInDown"
          data-animation="slideInUp"
        >
          <div id="boxes" className="newsletter__popup--inner">
            <button
              className="newsletter__popup--close__btn"
              aria-label="search close button"
              onClick={closePopup}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="32"
                  d="M368 368L144 144M368 144L144 368"
                ></path>
              </svg>
            </button>
            <div className="box newsletter__popup--box d-flex align-items-center">
              <div className="newsletter__popup--thumbnail">
                <img
                  className="newsletter__popup--thumbnail__img display-block"
                  src={newsletterPopupImage}
                  alt="newsletter-popup-thumb"
                />
              </div>
              <div className="newsletter__popup--box__right">
                <div>
                  <span className="fs-4">
                    This is the acquired Zip code from you location
                  </span>
                  {locationData &&
                    locationData.map((item, index) => (
                      <span key={index}>
                        <span className="fs-1 text__primary fw-bold">
                          {item.zip_code}
                        </span>
                      </span>
                    ))}
                  <div className="mt-4">
                    <span className="">
                      Would you like to continue with this zip code ?
                    </span>
                    <button
                      className="btn btn-success me-3 mt-4"
                      onClick={closePopup}
                    >
                      Yes
                    </button>
                    <button
                      className="btn btn-danger bg__primary mt-4"
                      onClick={() => {
                        setZipcodeManual(!zipcodemanual);
                      }}
                    >
                      No
                    </button>
                  </div>
                </div>
                {zipcodemanual && (
                  <div className="newsletter__popup--content my-4">
                    <div
                      className="newsletter__popup--subscribe"
                      id="frm_subscribe"
                    >
                      <form
                        className="newsletter__popup--subscribe__form"
                        noValidate
                        onSubmit={zipcodeFunction}
                      >
                        <input
                          className="newsletter__popup--subscribe__input"
                          type="text"
                          onChange={onChange}
                          onBlur={onBlur}
                          placeholder="Enter your Zipcode"
                          // {...register("zipcode", {
                          //   required: true,
                          // })}
                        />
                        {/* {errors.zipcode && (
                        <span className="mt-2 text__primary">
                          Please Enter Proper Zipcode Address
                        </span>
                      )} */}
                        <button
                          className="newsletter__popup--subscribe__btn"
                          onClick={closePopup}
                        >
                          Continue
                        </button>
                      </form>
                    </div>
                  </div>
                )}
                <h2 className="newsletter__popup--title mt-4">
                  Join Our Newsletter
                </h2>
                <div className="newsletter__popup--content">
                  <label className="newsletter__popup--content--desc">
                    Enter your email address to subscribe our notification of
                    our new post &amp; features by email.
                  </label>
                  <div
                    className="newsletter__popup--subscribe"
                    id="frm_subscribe"
                  >
                    <form
                      className="newsletter__popup--subscribe__form"
                      noValidate
                      onSubmit={handleSubmit(subScriberEmail)}
                    >
                      <input
                        className="newsletter__popup--subscribe__input"
                        type="text"
                        onChange={onChange}
                        onBlur={onBlur}
                        placeholder="Enter you email address here..."
                        {...register("subscriber_email", {
                          required: true,
                          validate: handleEmailValidation,
                        })}
                      />
                      {errors.subscriber_email && (
                        <span className="mt-2 text__primary">
                          Please Enter Proper Email Address
                        </span>
                      )}
                      <button className="newsletter__popup--subscribe__btn">
                        Subscribe
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
