import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useErrorBoundary } from "react-error-boundary";
import defaultImage from "../assets/img/default.png";
import {
  getSpecificCategoriesData,
  getSubCategoriesByCategoryId,
} from "../api/CategoriesApi";
import { getAllSubCategoriesData } from "../api/SubCategoriesApi";
import { SliderSkelton } from "../components/Skeltons/SliderSkelton";
import { DetailPageSkelton } from "../components/Skeltons/DetailSkelton";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/components/pagination/pagination.min.css";
import SwiperCore, { Navigation } from "swiper";

SwiperCore.use([Navigation]);
var CryptoJS = require("crypto-js");

export const CategoriesDetails = () => {
  const { id } = useParams();
  const { showBoundary } = useErrorBoundary();

  const [categoryDetails, setCategoryDetails] = useState([]);
  const [subCategoriesList, setsubCategoriesList] = useState([]);
  const [featuredSubCategoriesList, setfeaturedSubCategoriesList] = useState(
    []
  );
  const [subcategoriesState, setsubcategoriesState] = useState(true);
  const [subCatdisplayState, setSubCatDisplayState] = useState(false);
  const [catdisplayState, setCatDisplayState] = useState(false);
  const [allSubCatvisible, setAllSubCatVisible] = useState(12);
  const [featureSubCatVisible, setfeatureSubCatVisible] = useState(12);
  useEffect(() => {
    if (id) {
      getCategoryDetailsbyId(id);
      getSubCatbyCategoryId(id);
    }
    // getAllSubCategories();
  }, [id]);
  var categoryData = [];
  const getCategoryDetailsbyId = async (id) => {
    await getSpecificCategoriesData(id)
      .then((result) => {
        // console.log("RESULT", result.data);
        categoryData.push(result.data.categories);
        setCategoryDetails(result.data.categories);
        if (result.data.categories) {
          // console.log(categoryDetails);
          var ciphertext = CryptoJS.AES.encrypt(
            JSON.stringify(result.data.categories),
            "gokart@123"
          ).toString();
          sessionStorage.setItem("cat_data", JSON.stringify(ciphertext));
          setCatDisplayState(true);
        }
      })
      .catch((error) => {
        showBoundary(error);
      });
  };
  var featuredSubCat = [];
  // const getAllSubCategories = async () => {
  //   await getAllSubCategoriesData()
  //     .then((result) => {
  //       if (result.data.status === 1) {
  //         let subCategoriesArray = result.data.sub_categories;
  //         for (var i = 0; i < subCategoriesArray.length; i++) {
  //           if (subCategoriesArray[i].sub_category_state === 1) {
  //             featuredSubCat.push(subCategoriesArray[i]);
  //           }
  //         }
  //         setsubCategoriesList(subCategoriesArray);
  //         setfeaturedSubCategoriesList(featuredSubCat);
  //         setSubCatDisplayState(true);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  const getSubCatbyCategoryId = async (id) => {
    await getSubCategoriesByCategoryId(id)
      .then((result) => {
        // console.log("SubCat Data",result.data);
        if (result.data.status === 1) {
          let subCategoriesArray = result.data.sub_categories;
          for (var i = 0; i < subCategoriesArray.length; i++) {
            if (subCategoriesArray[i].sub_category_state === 1) {
              featuredSubCat.push(subCategoriesArray[i]);
            }
          }
          setsubCategoriesList(subCategoriesArray);
          setfeaturedSubCategoriesList(featuredSubCat);
          setSubCatDisplayState(true);
        }
      })
      .catch((err) => {
        showBoundary(err);
      });
  };

  const allSubCategories = () => {
    setsubcategoriesState(true);
  };
  const featuredSubCategories = () => {
    setsubcategoriesState(false);
  };
  const showMoreFeatureSubCatItems = () => {
    setfeatureSubCatVisible((prevValue) => prevValue + 12);
  };

  const [imageIndex, setImageIndex] = useState(0);
  const getImage = (index) => {
    setImageIndex(index);
  };
  return (
    <>
      <section className="product__section product__categories--section section--padding pt-5">
        <div className="container">
          {/* ===================== Category Detailss Start ============= */}
          {catdisplayState ? (
            <div>
              {categoryDetails &&
                categoryDetails.map((item) => (
                  <div
                    className="product__details--section section--padding pt-0"
                    key={item.category_id}
                  >
                    <div className="container">
                      <div className="row mb-5">
                        <div className="col-xl-12 col-sm-12 col-xs-12">
                          <div className="breadcrumb__content">
                            <ul className="breadcrumb__content--menu d-flex">
                              <li className="breadcrumb__content--menu__items">
                                <Link to="/">Home</Link>
                              </li>
                              <li className="breadcrumb__content--menu__items">
                                <Link to="/all-categories">All Categories</Link>
                              </li>
                              <li className="breadcrumb__content--menu__items text__primary">
                                <span>{item.category_title}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="row row-cols-lg-2 row-cols-md-2">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12 col-sm-12">
                          <div className="product__details--media">
                            <div className="product__media--preview__items">
                              <div
                                id="carouselIndicators"
                                className="carousel slide"
                                data-bs-ride="carousel"
                              >
                                <div className="carousel-inner">
                                  {item.category_images &&
                                    item.category_images.map(
                                      (catImage, index) => {
                                        return (
                                          <div
                                            className={
                                              index === imageIndex
                                                ? "carousel-item active"
                                                : "carousel-item"
                                            }
                                            key={index}
                                          >
                                            <img
                                              src={catImage}
                                              className="d-block w-100"
                                              alt={index}
                                            />
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                                <button
                                  className="carousel-control-prev"
                                  type="button"
                                  data-bs-target="#carouselIndicators"
                                  data-bs-slide="prev"
                                >
                                  <span
                                    className="carousel-control-prev-icon"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="visually-hidden">
                                    Previous
                                  </span>
                                </button>
                                <button
                                  className="carousel-control-next"
                                  type="button"
                                  data-bs-target="#carouselIndicators"
                                  data-bs-slide="next"
                                >
                                  <span
                                    className="carousel-control-next-icon"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="visually-hidden">Next</span>
                                </button>
                              </div>
                              <Swiper
                                effect={"fade"}
                                centeredSlides={false}
                                slidesPerView={6}
                                spaceBetween={30}
                                autoplay={false}
                                breakpoints={{
                                  0: {
                                    slidesPerView: 1,
                                  },
                                  400: {
                                    slidesPerView: 2,
                                  },
                                  639: {
                                    slidesPerView: 2,
                                  },
                                  865: {
                                    slidesPerView: 2,
                                  },
                                  1000: {
                                    slidesPerView: 3,
                                  },
                                  1500: {
                                    slidesPerView: 4,
                                  },
                                  1700: {
                                    slidesPerView: 5,
                                  },
                                  2560: {
                                    slidesPerView: 5,
                                  },
                                }}
                                navigation={true}
                                className="product__media--nav swiper"
                              >
                                <div className="swiper-wrapper">
                                  {item.category_images.length === 0 ? (
                                    <div className="d-flex justify-content-center align-items-center mb-40">
                                      <h2 className="section__heading--maintitle text__primary">
                                        No images Found
                                      </h2>
                                    </div>
                                  ) : (
                                    item.category_images.map(
                                      (catImage, index) => {
                                        return (
                                          <SwiperSlide
                                            className="swiper-slide "
                                            key={index}
                                            onClick={() => {
                                              getImage(index);
                                            }}
                                          >
                                            <div className="product__media--nav__items">
                                              <img
                                                className="product__media--nav__items--img"
                                                src={catImage}
                                                alt={index}
                                              />
                                            </div>
                                          </SwiperSlide>
                                        );
                                      }
                                    )
                                  )}
                                </div>
                              </Swiper>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12 col-sm-12">
                          <div className="product__details--info">
                            <h2 className="product__details--info__title mb-15">
                              {item.category_title}
                            </h2>
                            <p className="product__details--info__desc mb-20">
                              {item.category_description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <DetailPageSkelton />
          )}
          {/* ===================== Category Detailss End ============= */}
          {/* ===================== Sorting Conatiner start ============= */}
          <div className="shop__header bg__gray--color d-flex align-items-center justify-content-between mb-30">
            <div className="product__view--mode d-flex align-items-center">
              <div className="product__tab--one product__grid--column__buttons d-flex justify-content-center">
                <button
                  className={
                    subcategoriesState
                      ? "product__grid--column__button active"
                      : "product__grid--column__button"
                  }
                  aria-label="grid btn"
                  data-toggle="tab"
                  data-target="#product_grid"
                  onClick={allSubCategories}
                >
                  All SubCategories
                </button>
                <button
                  className={
                    subcategoriesState
                      ? "product__grid--column__button"
                      : "product__grid--column__button active"
                  }
                  aria-label="list btn"
                  data-toggle="tab"
                  data-target="#product_list"
                  onClick={featuredSubCategories}
                >
                  Featured SubCategories
                </button>
              </div>
            </div>
          </div>
          {/* ===================== Sorting Conatiner End ============= */}
          {subCatdisplayState ? (
            <div>
              <div className="row">
                {subCategoriesList.length !== 0 && subcategoriesState && (
                  <div className="section__heading d-flex justify-content-between align-items-center mb-40">
                    <h3 className="section__heading--maintitle fw-bold">
                      All SubCategories{" "}
                    </h3>
                  </div>
                )}
                {subCategoriesList.length === 0 && subcategoriesState && (
                  <div className="section__heading d-flex justify-content-between align-items-center mb-40">
                    <h3 className="section__heading--maintitle fw-bold text__primary">
                      SubCategories Not Found{" "}
                    </h3>
                  </div>
                )}

                {subCategoriesList &&
                  subcategoriesState &&
                  subCategoriesList.slice(0, allSubCatvisible).map((item) => (
                    <div
                      className="col-lg-2 col-xs-6 col-sm-6 mb-30"
                      key={item.sub_category_id}
                    >
                      <div className="categories2__product--items text-center position__relative">
                        {item.sub_category_state === 1 ? (
                          <div className="product__badge">
                            <span className="product__badge--items sale">
                              Featured
                            </span>
                          </div>
                        ) : null}
                        {item.sub_category_images ? (
                          <div className="categories2__product--thumbnail">
                            <Link
                              className="categories2__product--link display-block"
                              to={`/sub-categories-detail/${item.sub_category_id}`}
                            >
                              <img
                                className="categories2__product--img display-block cat-list-img rounded-circle"
                                src={item.sub_category_images[0]}
                                alt="categories-img"
                              />
                            </Link>
                          </div>
                        ) : (
                          <div className="categories2__product--thumbnail">
                            <Link
                              className="categories2__product--link display-block"
                              to={`/sub-categories-detail/${item.sub_category_id}`}
                            >
                              <img
                                className="categories2__product--img display-block cat-list-img rounded-circle"
                                src={defaultImage}
                                alt="categories-img"
                              />
                            </Link>
                          </div>
                        )}
                        <div className="product__categories--content2">
                          <h3 className="product__categories--content2__maintitle">
                            <Link
                              to={`/sub-categories-detail/${item.sub_category_id}`}
                            >
                              {item.sub_category_title}
                            </Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                  ))}
                {/* ===============All Sub Categories End ================ */}
              </div>
              {subCategoriesList &&
              !subcategoriesState &&
              subCategoriesList.length > 12 ? (
                <div className="row mt-5 pt-5">
                  <div className="col d-flex justify-content-center align-items-center">
                    <button
                      className="btn btn-success"
                      onClick={showMoreFeatureSubCatItems}
                    >
                      Load More
                    </button>
                  </div>
                </div>
              ) : null}
              {/* ===============Featured Sub Categories Start ================ */}
              <div className="row">
                {featuredSubCategoriesList.length !== 0 &&
                  !subcategoriesState && (
                    <div className="section__heading d-flex justify-content-between align-items-center mb-40">
                      <h3 className="section__heading--maintitle fw-bold">
                        Featured SubCategories{" "}
                      </h3>
                    </div>
                  )}
                {featuredSubCategoriesList.length === 0 &&
                !subcategoriesState ? (
                  <div className="section__heading d-flex justify-content-between align-items-center mb-40">
                    <h3 className="section__heading--maintitle fw-bold text__primary">
                      Featured SubCategories Not Found
                    </h3>
                  </div>
                ) : null}
                {featuredSubCategoriesList &&
                  !subcategoriesState &&
                  featuredSubCategoriesList
                    .slice(0, featureSubCatVisible)
                    .map((item) => (
                      <div
                        className="col-lg-2 col-xs-6 col-sm-6"
                        key={item.sub_category_id}
                      >
                        <div className="categories2__product--items text-center">
                          {item.sub_category_images ? (
                            <div className="categories2__product--thumbnail">
                              <Link
                                className="categories2__product--link display-block"
                                to={`/sub-categories-detail/${item.sub_category_id}`}
                              >
                                <img
                                  className="categories2__product--img display-block cat-list-img rounded-circle"
                                  src={item.sub_category_images[0]}
                                  alt="categories-img"
                                />
                              </Link>
                            </div>
                          ) : (
                            <div className="categories2__product--thumbnail">
                              <Link
                                className="categories2__product--link display-block"
                                to={`/sub-categories-detail/${item.sub_category_id}`}
                              >
                                <img
                                  className="categories2__product--img display-block cat-list-img rounded-circle"
                                  src={defaultImage}
                                  alt="categories-img"
                                />
                              </Link>
                            </div>
                          )}
                          <div className="product__categories--content2">
                            <h3 className="product__categories--content2__maintitle">
                              <Link
                                to={`/sub-categories-detail/${item.sub_category_id}`}
                              >
                                {item.sub_category_title}
                              </Link>
                            </h3>
                          </div>
                        </div>
                      </div>
                    ))}

                {/* ===============Featured Sub Categories End ================ */}
              </div>
              {featuredSubCategoriesList &&
              !subcategoriesState &&
              featuredSubCategoriesList.length > 12 ? (
                <div className="row mt-5 pt-5">
                  <div className="col d-flex justify-content-center align-items-center">
                    <button
                      className="btn btn-success"
                      onClick={showMoreFeatureSubCatItems}
                    >
                      Load More
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          ) : (
            <SliderSkelton />
          )}
        </div>
      </section>
    </>
  );
};
