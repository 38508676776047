import axios from "axios";

var backendUrl = process.env.REACT_APP_BACKEND_URL;

export async function getCategoriesData(type) {
  return await axios.get(backendUrl + "category/categories/" + type);
}

export async function getAllCategoriesData() {
  return await axios.get(backendUrl + "category/");
}

export async function getSpecificCategoriesData(categoryId) {
  return await axios.get(backendUrl + "category/" + categoryId);
}

export async function getSubCategoriesByCategoryId(categoryId) {
  return await axios.get(backendUrl + "category/subcategories/" + categoryId);
}
