import React from "react";

export const NoProductsFound = () => {
  return (
    <>
      <div className="row mt-5 pt-5">
        <div className="col">
          <h3 className="product__details--info__title mb-15 fw-bold text__primary">
            No Products Found
          </h3>
        </div>
      </div>
    </>
  );
};
