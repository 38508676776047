import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import defaultImage from "../../assets/img/default.png";
import { getFilteredBrands } from "../../api/BrandsApi";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/components/pagination/pagination.min.css";
import { SliderSkelton } from "../Skeltons/SliderSkelton";
import SwiperCore, { Navigation } from "swiper";
import { NoBrandsFound } from "./NoBrandsFound";

SwiperCore.use([Navigation]);

export const FeaturedBrands = () => {
  const { showBoundary } = useErrorBoundary();
  const [featuredBrands, setFeaturedBrands] = useState([]);
  const [displayState, setDisplayState] = useState(false);
  useEffect(() => {
    getFeaturedBrands();
  }, []);
  const getFeaturedBrands = async () => {
    await getFilteredBrands(1)
      .then((result) => {
        if (result.data.status === 1) {
          // console.log(result.data);
          let featuredBrandArray = result.data.brands;
          setFeaturedBrands(featuredBrandArray);
          setDisplayState(true);
        }
      })
      .catch((error) => {
        showBoundary(error);
      });
  };
  return (
    <>
      {displayState ? (
        <section className="product__section product__categories--section section--padding pt-5">
          <div className="container">
            <div className="section__heading d-flex justify-content-between align-items-center mb-40">
              <h2 className="section__heading--maintitle font-18">
                Featured Brands{" "}
              </h2>
              <div>
                <Link to="/all-brands">
                  View All <i className="fa fa-angle-double-right fs-3"></i>
                </Link>
              </div>
            </div>
            {featuredBrands.length === 0 ? <NoBrandsFound /> : null}
            <Swiper
              effect={"fade"}
              centeredSlides={false}
              slidesPerView={6}
              spaceBetween={10}
              autoplay={false}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                },
                400: {
                  slidesPerView: 2,
                },
                639: {
                  slidesPerView: 2,
                },
                865: {
                  slidesPerView: 2,
                },
                1000: {
                  slidesPerView: 6,
                },
                1500: {
                  slidesPerView: 12,
                },
                1700: {
                  slidesPerView: 12,
                },
                2560: {
                  slidesPerView: 12,
                },
              }}
              navigation={featuredBrands.length > 12 ? true : false}
              className="product__section--inner categories2__swiper--activation swiper"
            >
              <div className="swiper-wrapper">
                {featuredBrands &&
                  featuredBrands.map((item) => (
                    <SwiperSlide className="swiper-slide " key={item.brand_id}>
                      <div className="categories2__product--items text-center">
                        {item.brand_images ? (
                          <div className="categories2__product--thumbnail">
                            <Link
                              className="categories2__product--link display-block "
                              to={`/brand-detail/${item.brand_id}`}
                            >
                              <img
                                className="categories2__product--img display-block cat-img rounded-circle"
                                src={item.brand_images[0]}
                                alt="categories-img"
                              />
                            </Link>
                          </div>
                        ) : (
                          <div className="categories2__product--thumbnail">
                            <Link
                              className="categories2__product--link display-block"
                              to={`/brand-detail/${item.brand_id}`}
                            >
                              <img
                                className="categories2__product--img display-block cat-img rounded-circle"
                                src={defaultImage}
                                alt="categories-img"
                              />
                            </Link>
                          </div>
                        )}

                        <div className="product__categories--content2">
                          <h3 className="product__categories--content2__maintitle product-list-title">
                            <Link to={`/brand-detail/${item.brand_id}`}>
                              {item.brand_title}
                            </Link>
                          </h3>
                          {/* {item.brand_state === 1 && (
                            <span className="product__categories--content__subtitle text__primary">
                              Featured
                            </span>
                          )} */}
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
              </div>
            </Swiper>
          </div>
        </section>
      ) : (
        <SliderSkelton />
      )}
    </>
  );
};
