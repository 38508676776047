import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { geAllFAQ } from "../api/WebsiteManagement";
import { useErrorBoundary } from "react-error-boundary";
import { error } from "jquery";
export const Faq = () => {
  const [allFaqData, setAllFaqData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [faqList, setFaqList] = useState(false);
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    getAllFaqData();
  }, []);

  const getAllFaqData = async () => {
    await geAllFAQ()
      .then((result) => {
        if (result.data.status === 1) {
          console.log(result.data);
          setAllFaqData(result.data.faq);
        }
      })
      .catch((error) => {
        showBoundary(error);
      });
  };

  const faqDetail = (index) => {
    setFaqList(index);
  };
  return (
    <>
      <section className="faq__section section--padding pt-5">
        <div className="container">
          <div className="row mb-30">
            <div className="col">
              <div className="breadcrumb__content">
                <ul className="breadcrumb__content--menu d-flex">
                  <li className="breadcrumb__content--menu__items">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb__content--menu__items text__primary">
                    <span>Frequently Asked Questions</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="faq__section--inner">
            <div className="face__step one border-bottom" id="accordionExample">
              <h2 className="face__step--title h3 mb-30">
                Frequently Asked Questions
              </h2>
              <div className="row">
                {allFaqData &&
                  allFaqData.map((item, index) => (
                    <div className="col-lg-6" key={item.faq_id}>
                      <div
                        className={
                          faqList === index
                            ? "accordion__container active"
                            : "accordion__container"
                        }
                      >
                        <div className="accordion__items">
                          <h3 className="accordion__items--title">
                            <button
                              className="faq__accordion--btn accordion__items--button"
                              onClick={() => {
                                faqDetail(index);
                              }}
                            >
                              {item.faq_question}
                              <svg
                                className="accordion__items--button__icon"
                                xmlns="http://www.w3.org/2000/svg"
                                width="20.355"
                                height="13.394"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  d="M98 190.06l139.78 163.12a24 24 0 0036.44 0L414 190.06c13.34-15.57 2.28-39.62-18.22-39.62h-279.6c-20.5 0-31.56 24.05-18.18 39.62z"
                                  fill="currentColor"
                                />
                              </svg>
                            </button>
                          </h3>
                          <div
                            className="accordion__items--body"
                            style={{
                              boxSizing: faqList === index ? "border-box" : "",
                              display: faqList === index ? "block" : "",
                            }}
                          >
                            {/* dangerouslySetInnerHTML={{
                                __html: item.faq_answer,
                              }} */}
                            <div className="accordion__items--body__desc">
                              {item.faq_answer}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                {/* <div className="col-lg-6">
                  <div className="accordion__container">
                    <div className="accordion__items">
                      <h3 className="accordion__items--title">
                        <button className="faq__accordion--btn accordion__items--button">
                          Do you ship internationally??
                          <svg
                            className="accordion__items--button__icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.355"
                            height="13.394"
                            viewBox="0 0 512 512"
                          >
                            <path
                              d="M98 190.06l139.78 163.12a24 24 0 0036.44 0L414 190.06c13.34-15.57 2.28-39.62-18.22-39.62h-279.6c-20.5 0-31.56 24.05-18.18 39.62z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      </h3>
                      <div className="accordion__items--body">
                        <p className="accordion__items--body__desc">
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit. Donec odio. Quisque volutpat mattis eros. Nullam
                          malesuada erat ut turpis. Suspendisse urna nibh,
                          viverra non, semper suscipit, posuere a, pede. Donec
                          nec justo eget felis facilisis fermentum. Aliquam
                          porttitor mauris sit amet orci. Aenean dignissim
                          felis.
                        </p>
                      </div>
                    </div>
                    <div className="accordion__items">
                      <h3 className="accordion__items--title">
                        <button className="faq__accordion--btn accordion__items--button">
                          How will my parcel be delivered?
                          <svg
                            className="accordion__items--button__icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.355"
                            height="13.394"
                            viewBox="0 0 512 512"
                          >
                            <path
                              d="M98 190.06l139.78 163.12a24 24 0 0036.44 0L414 190.06c13.34-15.57 2.28-39.62-18.22-39.62h-279.6c-20.5 0-31.56 24.05-18.18 39.62z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      </h3>
                      <div className="accordion__items--body">
                        <p className="accordion__items--body__desc">
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit. Donec odio. Quisque volutpat mattis eros. Nullam
                          malesuada erat ut turpis. Suspendisse urna nibh,
                          viverra non, semper suscipit, posuere a, pede. Donec
                          nec justo eget felis facilisis fermentum. Aliquam
                          porttitor mauris sit amet orci. Aenean dignissim
                          felis.
                        </p>
                      </div>
                    </div>
                    <div className="accordion__items">
                      <h3 className="accordion__items--title">
                        <button className="faq__accordion--btn accordion__items--button">
                          How do I know if something is organic?
                          <svg
                            className="accordion__items--button__icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.355"
                            height="13.394"
                            viewBox="0 0 512 512"
                          >
                            <path
                              d="M98 190.06l139.78 163.12a24 24 0 0036.44 0L414 190.06c13.34-15.57 2.28-39.62-18.22-39.62h-279.6c-20.5 0-31.56 24.05-18.18 39.62z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      </h3>
                      <div className="accordion__items--body">
                        <p className="accordion__items--body__desc">
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit. Donec odio. Quisque volutpat mattis eros. Nullam
                          malesuada erat ut turpis. Suspendisse urna nibh,
                          viverra non, semper suscipit, posuere a, pede. Donec
                          nec justo eget felis facilisis fermentum. Aliquam
                          porttitor mauris sit amet orci. Aenean dignissim
                          felis.
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            {/* <div
              className="face__step one border-bottom"
              id="accordionExample2"
            >
              <h2 className="face__step--title h3 mb-30">
                Payment Information
              </h2>
              <div className="row">
                <div className="col-lg-6">
                  <div className="accordion__container">
                    <div className="accordion__items">
                      <h3 className="accordion__items--title">
                        <button className="faq__accordion--btn accordion__items--button">
                          What payment types can I use?
                          <svg
                            className="accordion__items--button__icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.355"
                            height="13.394"
                            viewBox="0 0 512 512"
                          >
                            <path
                              d="M98 190.06l139.78 163.12a24 24 0 0036.44 0L414 190.06c13.34-15.57 2.28-39.62-18.22-39.62h-279.6c-20.5 0-31.56 24.05-18.18 39.62z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      </h3>
                      <div className="accordion__items--body">
                        <p className="accordion__items--body__desc">
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit. Donec odio. Quisque volutpat mattis eros. Nullam
                          malesuada erat ut turpis. Suspendisse urna nibh,
                          viverra non, semper suscipit, posuere a, pede. Donec
                          nec justo eget felis facilisis fermentum. Aliquam
                          porttitor mauris sit amet orci. Aenean dignissim
                          felis.
                        </p>
                      </div>
                    </div>
                    <div className="accordion__items">
                      <h3 className="accordion__items--title">
                        <button className="faq__accordion--btn accordion__items--button">
                          Can I pay by Gift Card?
                          <svg
                            className="accordion__items--button__icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.355"
                            height="13.394"
                            viewBox="0 0 512 512"
                          >
                            <path
                              d="M98 190.06l139.78 163.12a24 24 0 0036.44 0L414 190.06c13.34-15.57 2.28-39.62-18.22-39.62h-279.6c-20.5 0-31.56 24.05-18.18 39.62z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      </h3>
                      <div className="accordion__items--body">
                        <p className="accordion__items--body__desc">
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit. Donec odio. Quisque volutpat mattis eros. Nullam
                          malesuada erat ut turpis. Suspendisse urna nibh,
                          viverra non, semper suscipit, posuere a, pede. Donec
                          nec justo eget felis facilisis fermentum. Aliquam
                          porttitor mauris sit amet orci. Aenean dignissim
                          felis.
                        </p>
                      </div>
                    </div>
                    <div className="accordion__items">
                      <h3 className="accordion__items--title">
                        <button className="faq__accordion--btn accordion__items--button">
                          can't make a payment
                          <svg
                            className="accordion__items--button__icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.355"
                            height="13.394"
                            viewBox="0 0 512 512"
                          >
                            <path
                              d="M98 190.06l139.78 163.12a24 24 0 0036.44 0L414 190.06c13.34-15.57 2.28-39.62-18.22-39.62h-279.6c-20.5 0-31.56 24.05-18.18 39.62z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      </h3>
                      <div className="accordion__items--body">
                        <p className="accordion__items--body__desc">
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit. Donec odio. Quisque volutpat mattis eros. Nullam
                          malesuada erat ut turpis. Suspendisse urna nibh,
                          viverra non, semper suscipit, posuere a, pede. Donec
                          nec justo eget felis facilisis fermentum. Aliquam
                          porttitor mauris sit amet orci. Aenean dignissim
                          felis.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="accordion__container">
                    <div className="accordion__items">
                      <h3 className="accordion__items--title">
                        <button className="faq__accordion--btn accordion__items--button">
                          Has my payment gone through?
                          <svg
                            className="accordion__items--button__icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.355"
                            height="13.394"
                            viewBox="0 0 512 512"
                          >
                            <path
                              d="M98 190.06l139.78 163.12a24 24 0 0036.44 0L414 190.06c13.34-15.57 2.28-39.62-18.22-39.62h-279.6c-20.5 0-31.56 24.05-18.18 39.62z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      </h3>
                      <div className="accordion__items--body">
                        <p className="accordion__items--body__desc">
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit. Donec odio. Quisque volutpat mattis eros. Nullam
                          malesuada erat ut turpis. Suspendisse urna nibh,
                          viverra non, semper suscipit, posuere a, pede. Donec
                          nec justo eget felis facilisis fermentum. Aliquam
                          porttitor mauris sit amet orci. Aenean dignissim
                          felis.
                        </p>
                      </div>
                    </div>
                    <div className="accordion__items">
                      <h3 className="accordion__items--title">
                        <button className="faq__accordion--btn accordion__items--button">
                          Tracking my order
                          <svg
                            className="accordion__items--button__icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.355"
                            height="13.394"
                            viewBox="0 0 512 512"
                          >
                            <path
                              d="M98 190.06l139.78 163.12a24 24 0 0036.44 0L414 190.06c13.34-15.57 2.28-39.62-18.22-39.62h-279.6c-20.5 0-31.56 24.05-18.18 39.62z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      </h3>
                      <div className="accordion__items--body">
                        <p className="accordion__items--body__desc">
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit. Donec odio. Quisque volutpat mattis eros. Nullam
                          malesuada erat ut turpis. Suspendisse urna nibh,
                          viverra non, semper suscipit, posuere a, pede. Donec
                          nec justo eget felis facilisis fermentum. Aliquam
                          porttitor mauris sit amet orci. Aenean dignissim
                          felis.
                        </p>
                      </div>
                    </div>
                    <div className="accordion__items">
                      <h3 className="accordion__items--title">
                        <button className="faq__accordion--btn accordion__items--button">
                          Haven’t received my order
                          <svg
                            className="accordion__items--button__icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.355"
                            height="13.394"
                            viewBox="0 0 512 512"
                          >
                            <path
                              d="M98 190.06l139.78 163.12a24 24 0 0036.44 0L414 190.06c13.34-15.57 2.28-39.62-18.22-39.62h-279.6c-20.5 0-31.56 24.05-18.18 39.62z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      </h3>
                      <div className="accordion__items--body">
                        <p className="accordion__items--body__desc">
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit. Donec odio. Quisque volutpat mattis eros. Nullam
                          malesuada erat ut turpis. Suspendisse urna nibh,
                          viverra non, semper suscipit, posuere a, pede. Donec
                          nec justo eget felis facilisis fermentum. Aliquam
                          porttitor mauris sit amet orci. Aenean dignissim
                          felis.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="face__step one" id="accordionExample3">
              <h2 className="face__step--title h3 mb-30">Orders and Returns</h2>
              <div className="row">
                <div className="col-lg-6">
                  <div className="accordion__container">
                    <div className="accordion__items">
                      <h3 className="accordion__items--title">
                        <button className="faq__accordion--btn accordion__items--button">
                          How can I return an item?
                          <svg
                            className="accordion__items--button__icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.355"
                            height="13.394"
                            viewBox="0 0 512 512"
                          >
                            <path
                              d="M98 190.06l139.78 163.12a24 24 0 0036.44 0L414 190.06c13.34-15.57 2.28-39.62-18.22-39.62h-279.6c-20.5 0-31.56 24.05-18.18 39.62z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      </h3>
                      <div className="accordion__items--body">
                        <p className="accordion__items--body__desc">
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit. Donec odio. Quisque volutpat mattis eros. Nullam
                          malesuada erat ut turpis. Suspendisse urna nibh,
                          viverra non, semper suscipit, posuere a, pede. Donec
                          nec justo eget felis facilisis fermentum. Aliquam
                          porttitor mauris sit amet orci. Aenean dignissim
                          felis.
                        </p>
                      </div>
                    </div>
                    <div className="accordion__items">
                      <h3 className=" accordion__items--title">
                        <button className="faq__accordion--btn accordion__items--button">
                          What Shipping Methods Are Available?
                          <svg
                            className="accordion__items--button__icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.355"
                            height="13.394"
                            viewBox="0 0 512 512"
                          >
                            <path
                              d="M98 190.06l139.78 163.12a24 24 0 0036.44 0L414 190.06c13.34-15.57 2.28-39.62-18.22-39.62h-279.6c-20.5 0-31.56 24.05-18.18 39.62z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      </h3>
                      <div className="accordion__items--body">
                        <p className="accordion__items--body__desc">
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit. Donec odio. Quisque volutpat mattis eros. Nullam
                          malesuada erat ut turpis. Suspendisse urna nibh,
                          viverra non, semper suscipit, posuere a, pede. Donec
                          nec justo eget felis facilisis fermentum. Aliquam
                          porttitor mauris sit amet orci. Aenean dignissim
                          felis.
                        </p>
                      </div>
                    </div>
                    <div className="accordion__items">
                      <h3 className="accordion__items--title">
                        <button className="faq__accordion--btn accordion__items--button">
                          How can i make refund from your website?
                          <svg
                            className="accordion__items--button__icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.355"
                            height="13.394"
                            viewBox="0 0 512 512"
                          >
                            <path
                              d="M98 190.06l139.78 163.12a24 24 0 0036.44 0L414 190.06c13.34-15.57 2.28-39.62-18.22-39.62h-279.6c-20.5 0-31.56 24.05-18.18 39.62z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      </h3>
                      <div className="accordion__items--body">
                        <p className="accordion__items--body__desc">
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit. Donec odio. Quisque volutpat mattis eros. Nullam
                          malesuada erat ut turpis. Suspendisse urna nibh,
                          viverra non, semper suscipit, posuere a, pede. Donec
                          nec justo eget felis facilisis fermentum. Aliquam
                          porttitor mauris sit amet orci. Aenean dignissim
                          felis.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="accordion__container">
                    <div className="accordion__items">
                      <h3 className="accordion__items--title">
                        <button className="faq__accordion--btn accordion__items--button">
                          I am a new user. How should I start?
                          <svg
                            className="accordion__items--button__icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.355"
                            height="13.394"
                            viewBox="0 0 512 512"
                          >
                            <path
                              d="M98 190.06l139.78 163.12a24 24 0 0036.44 0L414 190.06c13.34-15.57 2.28-39.62-18.22-39.62h-279.6c-20.5 0-31.56 24.05-18.18 39.62z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      </h3>
                      <div className="accordion__items--body">
                        <p className="accordion__items--body__desc">
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit. Donec odio. Quisque volutpat mattis eros. Nullam
                          malesuada erat ut turpis. Suspendisse urna nibh,
                          viverra non, semper suscipit, posuere a, pede. Donec
                          nec justo eget felis facilisis fermentum. Aliquam
                          porttitor mauris sit amet orci. Aenean dignissim
                          felis.
                        </p>
                      </div>
                    </div>
                    <div className="accordion__items">
                      <h3 className="accordion__items--title">
                        <button className="faq__accordion--btn accordion__items--button">
                          What payment methods are accepted?
                          <svg
                            className="accordion__items--button__icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.355"
                            height="13.394"
                            viewBox="0 0 512 512"
                          >
                            <path
                              d="M98 190.06l139.78 163.12a24 24 0 0036.44 0L414 190.06c13.34-15.57 2.28-39.62-18.22-39.62h-279.6c-20.5 0-31.56 24.05-18.18 39.62z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      </h3>
                      <div className="accordion__items--body">
                        <p className="accordion__items--body__desc">
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit. Donec odio. Quisque volutpat mattis eros. Nullam
                          malesuada erat ut turpis. Suspendisse urna nibh,
                          viverra non, semper suscipit, posuere a, pede. Donec
                          nec justo eget felis facilisis fermentum. Aliquam
                          porttitor mauris sit amet orci. Aenean dignissim
                          felis.
                        </p>
                      </div>
                    </div>
                    <div className="accordion__items">
                      <h3 className="accordion__items--title">
                        <button className="faq__accordion--btn accordion__items--button">
                          Do you ship internationally?
                          <svg
                            className="accordion__items--button__icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.355"
                            height="13.394"
                            viewBox="0 0 512 512"
                          >
                            <path
                              d="M98 190.06l139.78 163.12a24 24 0 0036.44 0L414 190.06c13.34-15.57 2.28-39.62-18.22-39.62h-279.6c-20.5 0-31.56 24.05-18.18 39.62z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                      </h3>
                      <div className="accordion__items--body">
                        <p className="accordion__items--body__desc">
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit. Donec odio. Quisque volutpat mattis eros. Nullam
                          malesuada erat ut turpis. Suspendisse urna nibh,
                          viverra non, semper suscipit, posuere a, pede. Donec
                          nec justo eget felis facilisis fermentum. Aliquam
                          porttitor mauris sit amet orci. Aenean dignissim
                          felis.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};
