import axios from "axios";

var backendUrl = process.env.REACT_APP_BACKEND_URL;

export async function getAllSubCategoriesData() {
  return await axios.get(backendUrl + "subcategory/");
}

export async function getFeaturedSubCategoriesList() {
  return await axios.get(backendUrl + "subcategory/subcategories/1");
}

export async function getSpecificSubCategoriesData(subCatId) {
  return await axios.get(backendUrl + "subcategory/" + subCatId);
}
