import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { DetailPageSkelton } from "../../components/Skeltons/DetailSkelton";
import { ProductListSkelton } from "../../components/Skeltons/ProductListSkelton";
import { useErrorBoundary } from "react-error-boundary";
import {
  getSpecificVendingMachine,
  getAllProductsOfVendingMachine,
} from "../../api/VendingMachine";
import { ProductCard } from "../../components/Products/ProductCard";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/components/pagination/pagination.min.css";
import SwiperCore, { Navigation } from "swiper";

SwiperCore.use([Navigation]);

export const VendingMachineDetail = () => {
  const { id } = useParams();
  const { showBoundary } = useErrorBoundary();
  const [vmDetail, setVMDetail] = useState([]);
  const [displayState, setdisplayState] = useState(false);
  const [vmModelPopup, setvmModelPopup] = useState(false);
  const [visible, setVisible] = useState(12);
  const [vmProducts, setVMProducts] = useState([]);

  useEffect(() => {
    if (id) {
      getVendingMachineData(id);
      getVendingMachineProducts(id);
    }
  }, []);
  var specificVMData = [];
  const getVendingMachineData = async (vmId) => {
    await getSpecificVendingMachine(vmId)
      .then((val) => {
        specificVMData.push(val.data.vm);
        setVMDetail(specificVMData);
        setdisplayState(true);
      })
      .catch((err) => {
        showBoundary(err);
      });
  };

  const getVendingMachineProducts = async (vmid) => {
    await getAllProductsOfVendingMachine(vmid)
      .then((result) => {
        if (result.data.status === 1) {
          // console.log(result.data.products)
          setVMProducts(result.data.products);
        }
      })
      .catch((err) => {
        showBoundary(err);
      });
  };
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 12);
  };
  const [imageIndex, setImageIndex] = useState(0);
  const getImage = (index) => {
    setImageIndex(index);
  };
  return (
    <>
      {displayState ? (
        <section className="product__section product__categories--section section--padding pt-5">
          <div className="container">
            {vmDetail &&
              vmDetail.map((item) => (
                <div key={item.vm_id}>
                  <div className="product__details--section section--padding pt-0">
                    <div className="container">
                      <div className="row mb-5">
                        <div className="col">
                          <div className="breadcrumb__content">
                            <ul className="breadcrumb__content--menu d-flex">
                              <li className="breadcrumb__content--menu__items">
                                <Link to="/">Home</Link>
                              </li>
                              <li className="breadcrumb__content--menu__items">
                                <Link to="/all-vending-machines">
                                  All Vending Machines
                                </Link>
                              </li>
                              <li className="breadcrumb__content--menu__items text__primary">
                                <span>{item.vm_name}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* <div className="col">
                          <div className="product__details--media">
                            <div className="product__media--preview__items">
                              <div
                                id="carouselIndicators"
                                className="carousel slide"
                                data-bs-ride="carousel"
                              >
                                <div className="carousel-inner">
                                  {item.vm_images.map((vmImage, index) => {
                                    return (
                                      <div
                                        className={
                                          index === imageIndex
                                            ? "carousel-item active"
                                            : "carousel-item"
                                        }
                                        key={index}
                                      >
                                        <img
                                          src={vmImage}
                                          className="d-block w-100"
                                          alt={index}
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                                <button
                                  className="carousel-control-prev"
                                  type="button"
                                  data-bs-target="#carouselIndicators"
                                  data-bs-slide="prev"
                                >
                                  <span
                                    className="carousel-control-prev-icon"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="visually-hidden">
                                    Previous
                                  </span>
                                </button>
                                <button
                                  className="carousel-control-next"
                                  type="button"
                                  data-bs-target="#carouselIndicators"
                                  data-bs-slide="next"
                                >
                                  <span
                                    className="carousel-control-next-icon"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="visually-hidden">Next</span>
                                </button>
                              </div>
                              <Swiper
                                effect={"fade"}
                                centeredSlides={false}
                                slidesPerView={6}
                                spaceBetween={30}
                                autoplay={false}
                                breakpoints={{
                                  0: {
                                    slidesPerView: 1,
                                  },
                                  400: {
                                    slidesPerView: 2,
                                  },
                                  639: {
                                    slidesPerView: 2,
                                  },
                                  865: {
                                    slidesPerView: 2,
                                  },
                                  1000: {
                                    slidesPerView: 3,
                                  },
                                  1500: {
                                    slidesPerView: 4,
                                  },
                                  1700: {
                                    slidesPerView: 5,
                                  },
                                  2560: {
                                    slidesPerView: 5,
                                  },
                                }}
                                navigation={true}
                                className="product__media--nav swiper"
                              >
                                <div className="swiper-wrapper">
                                  {item.vm_images.length === 0 ? (
                                    <div className="d-flex justify-content-center align-items-center mb-40">
                                      <h2 className="section__heading--maintitle text__primary">
                                        No images Found
                                      </h2>
                                    </div>
                                  ) : (
                                    item.vm_images.map((vmImage, index) => {
                                      return (
                                        <SwiperSlide
                                          className="swiper-slide "
                                          key={index}
                                          onClick={() => {
                                            getImage(index);
                                          }}
                                        >
                                          <div className="product__media--nav__items">
                                            <img
                                              className="product__media--nav__items--img"
                                              src={vmImage}
                                              alt={item.vm_name}
                                              title={item.vm_name}
                                            />
                                          </div>
                                        </SwiperSlide>
                                      );
                                    })
                                  )}
                                </div>
                              </Swiper>
                            </div>
                          </div>
                        </div> */}
                        <div className="col">
                          <div className="product__details--info">
                            <div className="d-flex justify-content-between align-items-center mb-15">
                              <h2 className="product__details--info__title">
                                {item.vm_name}
                              </h2>
                              <button
                                className="btn deals__banner--content__btn"
                                onClick={() => {
                                  setvmModelPopup(true);
                                }}
                              >
                                View VM Details
                              </button>
                            </div>
                            <p className="product__details--info__desc mb-20">
                              {item.vm_description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {/* ===================== Product List start ============= */}
            <div className="row row-md-reverse">
              <div className="shop__product--wrapper">
                <div className="tab_content">
                  <div id="product_grid" className="tab_pane active show">
                    <div className="product__section--inner product__section--style3__inner">
                      <div className="row row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-3 row-cols-sm-3 row-cols-2 mb--n30">
                        {vmProducts.length === 0 ? (
                          <div className="mb-40">
                            <h2 className="section__heading--maintitle text__primary">
                              No Products Found
                            </h2>
                          </div>
                        ) : (
                          vmProducts
                            .slice(0, visible)
                            .map((products, index) => {
                              return (
                                <div key={index}>
                                  <ProductCard item={products} />
                                </div>
                              );
                            })
                        )}
                      </div>
                      {vmProducts.length > 12 ? (
                        <div className="row mt-5 pt-5">
                          <div className="col d-flex justify-content-center align-items-center">
                            <button
                              className="btn btn-success"
                              onClick={showMoreItems}
                            >
                              Load More
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ===================== Product List End ============= */}
            {/*===================== VM POPUP START =====================*/}
            {vmModelPopup && (
              <div
                className="modal fade is-visible"
                id="storeDataModel"
                tabIndex="-1"
                ariaLabelledby="storeDataModelLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog quickview__main--wrapper">
                  <div className="modal-header quickview__header">
                    <button
                      className="close-modal quickview__close--btn"
                      ariaLabel="close modal"
                      onClick={() => {
                        setvmModelPopup(false);
                      }}
                    >
                      ✕{" "}
                    </button>
                  </div>
                  <div className="quickview__inner mt-5">
                    {vmDetail &&
                      vmDetail.map((item, index) => {
                        return (
                          <div className="row" key={index}>
                            <div className="col">
                              <div className="product__details--media">
                                <div className="product__media--preview__items">
                                  <div
                                    id="carouselIndicators"
                                    className="carousel slide"
                                    data-bs-ride="carousel"
                                  >
                                    <div className="carousel-inner">
                                      {item.vm_images.map((vmImage, index) => {
                                        return (
                                          <div
                                            className={
                                              index === imageIndex
                                                ? "carousel-item active"
                                                : "carousel-item"
                                            }
                                            key={index}
                                          >
                                            <img
                                              src={vmImage}
                                              className="d-block w-100"
                                              alt={index}
                                            />
                                          </div>
                                        );
                                      })}
                                    </div>
                                    <button
                                      className="carousel-control-prev"
                                      type="button"
                                      data-bs-target="#carouselIndicators"
                                      data-bs-slide="prev"
                                    >
                                      <span
                                        className="carousel-control-prev-icon"
                                        aria-hidden="true"
                                      ></span>
                                      <span className="visually-hidden">
                                        Previous
                                      </span>
                                    </button>
                                    <button
                                      className="carousel-control-next"
                                      type="button"
                                      data-bs-target="#carouselIndicators"
                                      data-bs-slide="next"
                                    >
                                      <span
                                        className="carousel-control-next-icon"
                                        aria-hidden="true"
                                      ></span>
                                      <span className="visually-hidden">
                                        Next
                                      </span>
                                    </button>
                                  </div>
                                  <Swiper
                                    effect={"fade"}
                                    centeredSlides={false}
                                    slidesPerView={6}
                                    spaceBetween={30}
                                    autoplay={false}
                                    breakpoints={{
                                      0: {
                                        slidesPerView: 1,
                                      },
                                      400: {
                                        slidesPerView: 2,
                                      },
                                      639: {
                                        slidesPerView: 2,
                                      },
                                      865: {
                                        slidesPerView: 2,
                                      },
                                      1000: {
                                        slidesPerView: 3,
                                      },
                                      1500: {
                                        slidesPerView: 4,
                                      },
                                      1700: {
                                        slidesPerView: 5,
                                      },
                                      2560: {
                                        slidesPerView: 5,
                                      },
                                    }}
                                    navigation={true}
                                    className="product__media--nav swiper"
                                  >
                                    <div className="swiper-wrapper">
                                      {item.vm_images.length === 0 ? (
                                        <div className="d-flex justify-content-center align-items-center mb-40">
                                          <h2 className="section__heading--maintitle text__primary">
                                            No images Found
                                          </h2>
                                        </div>
                                      ) : (
                                        item.vm_images.map((vmImage, index) => {
                                          return (
                                            <SwiperSlide
                                              className="swiper-slide "
                                              key={index}
                                              onClick={() => {
                                                getImage(index);
                                              }}
                                            >
                                              <div className="product__media--nav__items">
                                                <img
                                                  className="product__media--nav__items--img"
                                                  src={vmImage}
                                                  alt={item.vm_name}
                                                  title={item.vm_name}
                                                />
                                              </div>
                                            </SwiperSlide>
                                          );
                                        })
                                      )}
                                    </div>
                                  </Swiper>
                                </div>
                              </div>
                            </div>
                            <div className="col">
                              <div className="product__details--info">
                                <h2 className="product__details--info__title mb-15">
                                  {item.vm_name}
                                </h2>
                                <p className="product__details--info__desc mb-20">
                                  {item.vm_description}
                                </p>
                                <div className="product__variant">
                                  {item.vm_shipping_zip_codes.length !== 0 && (
                                    <div className="product__variant--list mb-15">
                                      <p className="product__variant--title mb-8">
                                        Store Shipping Zip COdes:{" "}
                                      </p>
                                      {item.vm_shipping_zip_codes.length !==
                                        0 &&
                                        item.vm_shipping_zip_codes.map(
                                          (zipcodes) => (
                                            <span className="bg-success p-2 me-3 text-white">
                                              {zipcodes}
                                            </span>
                                          )
                                        )}
                                    </div>
                                  )}
                                  {item.vm_address && (
                                    <div className="product__variant--list mb-15">
                                      <p className="product__variant--title mb-8">
                                        Address:{" "}
                                      </p>
                                      <address>{item.vm_address}</address>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
            {/*===================== VM POPUP END =====================*/}
          </div>
        </section>
      ) : (
        <div>
          <DetailPageSkelton />
          <div className="container">
            <div className="product__section--inner product__section--style3__inner">
              <div className="row row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-3 row-cols-sm-3 row-cols-2 mb--n30">
                <ProductListSkelton />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
