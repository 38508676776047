import React from "react";

export function ErrorComponent(props) {
  const { error, resetErrorBoundary } = props;
  return (
    <>
      <div className="container my-auto">
        <div className="row">
          <div className="col">
            <h1 className="text-danger">Error Occured</h1>
            <p className="text-warning">{error.message}</p>
            <button onClick={resetErrorBoundary}>Reload</button>
          </div>
        </div>
      </div>
    </>
  );
}
