/* eslint-disable import/first */
import { useEffect, useState } from "react";

import Loader from "../../layouts/Loader";
import { ProuctDetailSidePanel } from "../../components/Products/ProductDetailSidepanel";
import { Link, useParams } from "react-router-dom";
import { getProductbyId } from "../../api/ProductsApi";
import { getSpecificSubCategoriesData } from "../../api/SubCategoriesApi";
import defaultImage from "../../assets/img/default.png";
import Moment from "moment";
import { getSpecificVendingMachine } from "../../api/VendingMachine";
import { ProductCard } from "../../components/Products/ProductCard";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/components/pagination/pagination.min.css";
import "./ProductDetails.css";
import { getSpecificBrand } from "../../api/BrandsApi";
import { getShopbyId } from "../../api/Shops";
import SwiperCore, { Navigation } from "swiper";
import { useErrorBoundary } from "react-error-boundary";
import { NoVMFound } from "../../components/VendingMachines/NoVMFound";
import { NoShopFound } from "../../components/Shop/NoShopFound";

SwiperCore.use([Navigation]);

export const ProductDetails = () => {
  const { id } = useParams();
  const { showBoundary } = useErrorBoundary();
  const [productDetails, setproductDetails] = useState([]);
  const [displayState, setdisplayState] = useState(false);
  const [brandData, setBrandData] = useState([]);
  const [subCatData, setSubCatData] = useState([]);
  const [storeShop, setStoreShop] = useState(false);
  const [storeVendingMachine, setStoreVendingMachine] = useState(false);

  const [cartNumber, setCartNumber] = useState(1);
  useEffect(() => {
    if (id) {
      getproductData(id);
    }
  }, [id]);
  var productsArray = [];
  var shopsList = [];
  var vendingMachineList = [];
  var subCategory_id;
  const getproductData = async () => {
    await getProductbyId(id)
      .then(async (result) => {
        if (result.data.status === 1) {
          productsArray.push(result.data.product);
          // console.log(productsArray);
          for (var i = 0; i < productsArray.length; i++) {
            var product_stores = productsArray[i].product_stores;
            for (var j = 0; j < product_stores.length; j++) {
              var shop_list = product_stores[j].store_shops;
              for (var k = 0; k < shop_list.length; k++) {
                shopsList.push(shop_list[k]);
              }
              var vm_list = product_stores[j].store_vending_machines;
              for (var l = 0; l < vm_list.length; l++) {
                vendingMachineList.push(vm_list[l]);
              }
            }
          }
          setproductDetails(productsArray);
          if (shopsList.length !== 0) {
            setStoreShop(true);
          }
          if (vendingMachineList.length !== 0) {
            setStoreVendingMachine(true);
          }

          setdisplayState(true);
          let brand_id = result.data.product.product_brand;
          subCategory_id = result.data.product.product_sub_category;
          if (brand_id) {
            await brandsData(brand_id);
          }
          if (subCategory_id) {
            await subCategoryData(subCategory_id);
          }
        }
      })
      .catch((error) => {
        showBoundary(error);
      });
  };

  // BrandbyiD ApI Start
  var brandArray = [];
  const brandsData = async (brandId) => {
    await getSpecificBrand(brandId)
      .then((result) => {
        if (result.data.status === 1) {
          brandArray.push(result.data.brand);
          setBrandData(brandArray);
        }
      })
      .catch((error) => {
        showBoundary(error);
      });
  };
  // BrandbyiD ApI End
  // SubCatbyiD ApI Start
  var subCatArray = [];
  const subCategoryData = async (subCatId) => {
    await getSpecificSubCategoriesData(subCatId)
      .then((result) => {
        if (result.data.status === 1) {
          subCatArray.push(result.data.sub_category);
          setSubCatData(subCatArray);
        }
      })
      .catch((error) => {
        showBoundary(error);
      });
  };
  // SubCatbyiD ApI End
  //  Dynamic Image Change Click
  const [imageIndex, setImageIndex] = useState(0);
  const getImage = (index) => {
    setImageIndex(index);
  };

  var specificShopData = [];
  const [shopSpecificData, setShopSpecificData] = useState([]);
  const [shopModelPopup, setShopModelpopup] = useState(false);
  const getClickedShopData = async (shopid) => {
    await getShopbyId(shopid)
      .then((result) => {
        console.log(result.data);
        if (result.data.status === 1) {
          specificShopData.push(result.data.shop);
          setShopSpecificData(specificShopData);
        }
      })
      .catch((err) => {
        showBoundary(err);
      });
  };

  // clicked VENDINGDATA ;
  var SpecificVMData = [];
  const [vmModelPopup, setVmModelpopup] = useState(false);
  const [specificVMData, setSpecificVMData] = useState([]);
  const getClickedVmData = async (vm_id) => {
    await getSpecificVendingMachine(vm_id)
      .then((val) => {
        SpecificVMData.push(val.data.vm);
        //  console.log("VM DATA",SpecificVMData);
        setSpecificVMData(SpecificVMData);
      })
      .catch((err) => {
        showBoundary(err);
      });
  };

  //  Cart Function
  const decreaseCartNumber = () => {
    console.log();
  };

  const increaseCartNumber = () => {
    console.log();
  };

  return (
    <>
      {displayState ? (
        <section className="product__details--section  section--padding pt-5">
          <div className="container-fluid">
            <div className="row mb-5">
              <div className="col">
                <div className="breadcrumb__content">
                  <ul className="breadcrumb__content--menu d-flex flex-wrap">
                    <li className="breadcrumb__content--menu__items">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb__content--menu__items">
                      <Link to="/all-products">All Products</Link>
                    </li>
                    {productDetails &&
                      productDetails.map((item, index) => (
                        <li
                          className="breadcrumb__content--menu__items text__primary"
                          key={index}
                        >
                          <span>{item.product_title}</span>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="row row-md-reverse">
              {displayState ? (
                <ProuctDetailSidePanel item={productDetails} />
              ) : null}
              {productDetails &&
                displayState &&
                productDetails.map((item, index) => (
                  <div className="col-xl-9 col-lg-8" key={index}>
                    <div className="product__details--sidebar__wrapper">
                      <div className="product__sidebar--wrapper__top section--padding py-0">
                        <div className="row row-cols-xl-2 row-cols-md-2 row-cols-sm-2 row-cols-1">
                          <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div className="product__details--media details__media--position">
                              <div
                                id="carouselIndicators"
                                className="carousel slide"
                                data-bs-ride="carousel"
                              >
                                <div className="carousel-inner">
                                  {item.product_images.map(
                                    (productImage, index) => {
                                      return (
                                        <div
                                          className={
                                            index === imageIndex
                                              ? "carousel-item active"
                                              : "carousel-item"
                                          }
                                          key={index}
                                        >
                                          <img
                                            src={productImage}
                                            className="d-block w-100"
                                            alt={index}
                                          />
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                                <button
                                  className="carousel-control-prev"
                                  type="button"
                                  data-bs-target="#carouselIndicators"
                                  data-bs-slide="prev"
                                >
                                  <span
                                    className="carousel-control-prev-icon"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="visually-hidden">
                                    Previous
                                  </span>
                                </button>
                                <button
                                  className="carousel-control-next"
                                  type="button"
                                  data-bs-target="#carouselIndicators"
                                  data-bs-slide="next"
                                >
                                  <span
                                    className="carousel-control-next-icon"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="visually-hidden">Next</span>
                                </button>
                              </div>
                              <Swiper
                                effect={"fade"}
                                centeredSlides={false}
                                slidesPerView={6}
                                spaceBetween={10}
                                autoplay={false}
                                breakpoints={{
                                  0: {
                                    slidesPerView: 2,
                                  },
                                  400: {
                                    slidesPerView: 4,
                                  },
                                  639: {
                                    slidesPerView: 4,
                                  },
                                  865: {
                                    slidesPerView: 2,
                                  },
                                  1000: {
                                    slidesPerView: 5,
                                  },
                                  1500: {
                                    slidesPerView: 5,
                                  },
                                  1700: {
                                    slidesPerView: 5,
                                  },
                                  2560: {
                                    slidesPerView: 5,
                                  },
                                }}
                                navigation={true}
                                className="product__media--nav swiper"
                              >
                                <div className="swiper-wrapper">
                                  {item.product_images.length === 0 ? (
                                    <div className="d-flex justify-content-center align-items-center mb-40">
                                      <h2 className="section__heading--maintitle text__primary">
                                        No images Found
                                      </h2>
                                    </div>
                                  ) : (
                                    item.product_images.map(
                                      (productImage, index) => {
                                        return (
                                          <SwiperSlide
                                            className="swiper-slide "
                                            key={index}
                                            onClick={() => {
                                              getImage(index);
                                            }}
                                          >
                                            <div className="product__media--nav__items">
                                              <img
                                                className="product__media--nav__items--img"
                                                src={productImage}
                                                alt="product-nav-img"
                                              />
                                            </div>
                                          </SwiperSlide>
                                        );
                                      }
                                    )
                                  )}
                                </div>
                              </Swiper>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div className="product__details--info">
                              <form action="#">
                                <h2 className="product__details--info__title mb-15">
                                  {item.product_title}
                                </h2>
                                <div className="product__details--info__price mb-15">
                                  <span className="current__price">
                                    $ {item.product_actual_price}
                                  </span>
                                </div>
                                <div className="product__items--rating d-flex align-items-center mb-15">
                                  <ul className="d-flex">
                                    <li className="product__items--rating__list">
                                      <span className="product__items--rating__icon">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16.105"
                                          height="14.732"
                                          viewBox="0 0 10.105 9.732"
                                        >
                                          <path
                                            data-name="star - Copy"
                                            d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                            transform="translate(0 -0.018)"
                                            fill="currentColor"
                                          />
                                        </svg>
                                      </span>
                                    </li>
                                    <li className="product__items--rating__list">
                                      <span className="product__items--rating__icon">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16.105"
                                          height="14.732"
                                          viewBox="0 0 10.105 9.732"
                                        >
                                          <path
                                            data-name="star - Copy"
                                            d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                            transform="translate(0 -0.018)"
                                            fill="currentColor"
                                          />
                                        </svg>
                                      </span>
                                    </li>
                                    <li className="product__items--rating__list">
                                      <span className="product__items--rating__icon">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16.105"
                                          height="14.732"
                                          viewBox="0 0 10.105 9.732"
                                        >
                                          <path
                                            data-name="star - Copy"
                                            d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                            transform="translate(0 -0.018)"
                                            fill="currentColor"
                                          />
                                        </svg>
                                      </span>
                                    </li>
                                    <li className="product__items--rating__list">
                                      <span className="product__items--rating__icon">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16.105"
                                          height="14.732"
                                          viewBox="0 0 10.105 9.732"
                                        >
                                          <path
                                            data-name="star - Copy"
                                            d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                            transform="translate(0 -0.018)"
                                            fill="currentColor"
                                          />
                                        </svg>
                                      </span>
                                    </li>
                                    <li className="product__items--rating__list">
                                      <span className="product__items--rating__icon">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16.105"
                                          height="14.732"
                                          viewBox="0 0 10.105 9.732"
                                        >
                                          <path
                                            data-name="star - Copy"
                                            d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                            transform="translate(0 -0.018)"
                                            fill="#c7c5c2"
                                          />
                                        </svg>
                                      </span>
                                    </li>
                                  </ul>
                                  <span className="product__items--rating__count--number text__primary">
                                    (24)
                                  </span>
                                  {item.product_views === 0 ? (
                                    ""
                                  ) : (
                                    <span className="product__items--rating__count--number">
                                      <i className="fa fa-eye"></i> &nbsp;
                                      <span className="text__primary">
                                        {item.product_views}
                                      </span>
                                    </span>
                                  )}
                                </div>
                                <p className="product__details--info__desc mb-20">
                                  {item.product_description}
                                </p>
                                {/* <div className="product__variant--list mb-10">
                                    <fieldset className="variant__input--fieldset">
                                        <legend className="product__variant--title mb-8">Color :</legend>
                                        <div className="variant__color d-flex">
                                            <div className="variant__color--list">
                                                <input id="color-red1" name="color" type="radio" checked=""/>
                                                <label className="variant__color--value red" for="color-red1" title="Red">
                                                  <img className="variant__color--value__img" src={defaultImage} alt="variant-color-img"/></label>
                                            </div>
                                            <div className="variant__color--list">
                                                <input id="color-red2" name="color" type="radio"/>
                                                <label className="variant__color--value red" for="color-red2" title="Black"><img className="variant__color--value__img" src={defaultImage} alt="variant-color-img"/></label>
                                            </div>
                                            <div className="variant__color--list">
                                                <input id="color-red3" name="color" type="radio"/>
                                                <label className="variant__color--value red" for="color-red3" title="Pink"><img className="variant__color--value__img" src={defaultImage} alt="variant-color-img"/></label>
                                            </div>
                                            <div className="variant__color--list">
                                                <input id="color-red4" name="color" type="radio"/>
                                                <label className="variant__color--value red" for="color-red4" title="Orange"><img className="variant__color--value__img" src={defaultImage} alt="variant-color-img"/></label>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div> */}
                                <div className="product__variant--list mb-20">
                                  <fieldset className="variant__input--fieldset">
                                    <legend className="product__variant--title mb-8">
                                      Weight :
                                    </legend>
                                    <ul className="variant__size d-flex">
                                      <li className="variant__size--list red">
                                        <input
                                          id="weight1"
                                          name="weight"
                                          type="radio"
                                          checked=""
                                          onChange={(event) => {
                                            console.log(event);
                                          }}
                                        />
                                        {item.product_scale === 0 && (
                                          <label
                                            className="variant__size--value red"
                                            htmlFor="weight1"
                                          >
                                            {item.product_weight} grams
                                          </label>
                                        )}
                                        {item.product_scale === 1 && (
                                          <label
                                            className="variant__size--value red"
                                            htmlFor="weight1"
                                          >
                                            {item.product_weight} Kg
                                          </label>
                                        )}
                                        {item.product_scale === 2 && (
                                          <label
                                            className="variant__size--value red"
                                            htmlFor="weight1"
                                          >
                                            {item.product_weight} lb
                                          </label>
                                        )}
                                        {item.product_scale === 3 && (
                                          <label
                                            className="variant__size--value red"
                                            htmlFor="weight1"
                                          >
                                            {item.product_weight} litre
                                          </label>
                                        )}
                                        {item.product_scale === 4 && (
                                          <label
                                            className="variant__size--value red"
                                            htmlFor="weight1"
                                          >
                                            {item.product_weight} ml
                                          </label>
                                        )}
                                      </li>
                                    </ul>
                                  </fieldset>
                                </div>
                                <div className="product__variant ">
                                  <div className="product__variant--list mb-15 text-end">
                                    <Link
                                      className="variant__wishlist--icon mb-15"
                                      to="/wish-list"
                                      title="Add to wishlist"
                                    >
                                      <svg
                                        className="quickview__variant--wishlist__svg"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                      >
                                        <path
                                          d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="32"
                                        />
                                      </svg>
                                      Add to Wishlist
                                    </Link>
                                  </div>
                                </div>
                                <div className="product__variant--list mb-1">
                                  <div className="product__details--info__meta">
                                    {(() => {
                                      switch (item.product_packing_type) {
                                        case 0:
                                          return (
                                            <p className="product__details--info__meta--list">
                                              <strong>Packing Type:</strong>{" "}
                                              <span> Paper</span>{" "}
                                            </p>
                                          );
                                        case 1:
                                          return (
                                            <p className="product__details--info__meta--list">
                                              <strong>Packing Type:</strong>{" "}
                                              <span> Plastic</span>{" "}
                                            </p>
                                          );
                                        case 2:
                                          return (
                                            <p className="product__details--info__meta--list">
                                              <strong>Packing Type:</strong>{" "}
                                              <span> Glass</span>{" "}
                                            </p>
                                          );
                                        default:
                                          return null;
                                      }
                                    })()}
                                    {(() => {
                                      if (item.product_type === 1) {
                                        return (
                                          <p className="product__details--info__meta--list">
                                            <strong>Product Type:</strong>{" "}
                                            <span>Non-Fresh</span>{" "}
                                          </p>
                                        );
                                      } else {
                                        return (
                                          <p className="product__details--info__meta--list">
                                            <strong>Product Type:</strong>{" "}
                                            <span>Fresh</span>{" "}
                                          </p>
                                        );
                                      }
                                    })()}
                                  </div>
                                </div>
                                <div className="product__variant--list mb-15">
                                  <div className="product__details--info__meta">
                                    <p className="product__details--info__meta--list">
                                      <strong>Items Per Package:</strong>{" "}
                                      <span>
                                        {" "}
                                        {item.product_items_per_package}
                                      </span>{" "}
                                    </p>
                                    <p className="product__details--info__meta--list">
                                      <strong>SubCategory:</strong> &nbsp;
                                      {subCatData &&
                                        subCatData.map((subCat, index) => {
                                          return (
                                            <span key={index}>
                                              {subCat.sub_category_title}
                                            </span>
                                          );
                                        })}
                                    </p>
                                    <p className="product__details--info__meta--list">
                                      <strong>Brand:</strong> &nbsp;
                                      {brandData &&
                                        brandData.map((brand, index) => {
                                          return (
                                            <span key={index}>
                                              {brand.brand_title}
                                            </span>
                                          );
                                        })}
                                    </p>
                                    {/* <p className="product__details--info__meta--list"><strong>Type:</strong>  <span>Foods</span> </p> */}
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Stores List Start */}
                      <div className="row pt-5">
                        <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                          <h3 className="product__details--info__title mb-15 fw-bold">
                            Available in Shops
                          </h3>
                        </div>
                        {storeShop ? (
                          ""
                        ) : (
                          <div className="mb-40">
                            <NoShopFound />
                          </div>
                        )}
                        {item.product_stores.length === 0
                          ? ""
                          : item.product_stores.map((stores, index) => {
                              return (
                                <>
                                  {/* <div key={index}> */}
                                  {stores.store_shops.length !== 0 &&
                                    stores.store_shops.map((shops, index) => {
                                      return (
                                        <div
                                          className="col-lg-4 col-md-4 col-xs-12 col-sm-12 mb-30"
                                          key={index}
                                        >
                                          <div
                                            className="product__categories--grid__items product__bg align-items-center cursor-pointer"
                                            onClick={() => {
                                              getClickedShopData(shops.shop_id);
                                              setShopModelpopup(true);
                                            }}
                                          >
                                            <div className="position__relative">
                                              <div className="px-2">
                                                <h3 className="product__categories--grid__content--maintitle fs-3  text-success text-wrap-1 mb-10">
                                                  {shops.shop_name}
                                                </h3>
                                                {/* <address className="mb-1">
                                                {shops.shop_address}
                                              </address> */}
                                                <p className="mb-10">
                                                  <span className="fw-bold">
                                                    Quantity:
                                                  </span>{" "}
                                                  {
                                                    shops.shop_product
                                                      .shop_product_quantity
                                                  }
                                                </p>
                                                <div className="">
                                                  <div className="product__details--info__price">
                                                    <span className="fs-1 text-success">
                                                      ${/* {Math.ceil()} */}
                                                      {(
                                                        item.product_actual_price -
                                                        (item.product_actual_price *
                                                          stores.store_product_discount) /
                                                          100
                                                      ).toFixed(2)}
                                                    </span>
                                                    {stores.store_product_discount ===
                                                    0 ? (
                                                      ""
                                                    ) : (
                                                      <>
                                                        <span className="fs-4 fw-bold old__price ml-0 pl-0">
                                                          $
                                                          {
                                                            item.product_actual_price
                                                          }
                                                        </span>
                                                        <span className="text__primary">
                                                          &nbsp;(
                                                          {
                                                            stores.store_product_discount
                                                          }{" "}
                                                          % Discount)
                                                        </span>
                                                      </>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="product__categories--grid__items product__bg align-items-center cursor-pointer">
                                            <div className="position__relative">
                                              <div className="px-2">
                                                <div className="d-flex justify-content-between align-items-center">
                                                  <div className="w-50">
                                                    <div className="quantity__box">
                                                      <button
                                                        type="button"
                                                        className="quantity__value quickview__value--quantity decrease"
                                                        aria-label="quantity value"
                                                        onClick={
                                                          decreaseCartNumber
                                                        }
                                                      >
                                                        -
                                                      </button>
                                                      <label>
                                                        <input
                                                          type="number"
                                                          className="quantity__number quickview__value--number"
                                                          value={cartNumber}
                                                          onChange={(e) =>
                                                            setCartNumber(
                                                              e.target.value
                                                            )
                                                          }
                                                        />
                                                      </label>
                                                      <button
                                                        type="button"
                                                        className="quantity__value quickview__value--quantity increase"
                                                        aria-label="quantity value"
                                                        onClick={
                                                          increaseCartNumber
                                                        }
                                                      >
                                                        +
                                                      </button>
                                                    </div>
                                                  </div>
                                                  <div className="w-50">
                                                    <button
                                                      className="variant__buy--now__btn btn"
                                                      type="submit"
                                                    >
                                                      Add to Cart
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  {/* </div> */}
                                </>
                              );
                            })}
                      </div>
                      {/* Stores List ENd */}
                      {/* Vending Machine List Start */}
                      <div className="row mb-10">
                        {/*Vending Machine heading start  */}
                        <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                          <h3 className="product__details--info__title mb-15 fw-bold">
                            Available in Vending Machine
                          </h3>
                        </div>
                        {storeVendingMachine ? (
                          ""
                        ) : (
                          <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                            <NoVMFound />
                          </div>
                        )}
                        {/*Vending Machine heading end  */}
                        {item.product_stores.length !== 0 &&
                          item.product_stores.map((stores, index) => {
                            return (
                              <>
                                {/* <div key={index}> */}
                                {stores.store_vending_machines.length !== 0 &&
                                  stores.store_vending_machines.map(
                                    (vendinMachine, index) => {
                                      return (
                                        <div
                                          className="col-lg-4 col-md-4 col-xs-12 col-sm-12 mb-30"
                                          key={index}
                                        >
                                          <div
                                            className="product__categories--grid__items product__bg align-items-center cursor-pointer"
                                            onClick={() => {
                                              getClickedVmData(
                                                vendinMachine.vm_id
                                              );
                                              setVmModelpopup(true);
                                            }}
                                          >
                                            <div className="position__relative">
                                              <div className="px-2">
                                                <div
                                                  style={{ display: "block" }}
                                                >
                                                  {stores.store_product_state ===
                                                  0 ? (
                                                    ""
                                                  ) : (
                                                    <div className="text-right mb-3">
                                                      <span className="product__badge--items sale">
                                                        Featured
                                                      </span>
                                                    </div>
                                                  )}
                                                </div>
                                                <h3 className="product__categories--grid__content--maintitle fs-3  text-success text-wrap-1">
                                                  {vendinMachine.vm_name}
                                                </h3>
                                                {/* <address className="mb-1">
                                                    {vendinMachine.vm_address}
                                                  </address> */}
                                                <p className="mb-10">
                                                  <span className="fw-bold">
                                                    Quantity:
                                                  </span>{" "}
                                                  {
                                                    vendinMachine
                                                      .vending_machine_product
                                                      .vm_product_quantity
                                                  }
                                                </p>
                                                <div className="">
                                                  <div className="product__details--info__price">
                                                    <span className="fs-1 text-success">
                                                      $
                                                      {(
                                                        item.product_actual_price -
                                                        (item.product_actual_price *
                                                          stores.store_product_discount) /
                                                          100
                                                      ).toFixed(2)}
                                                    </span>
                                                    {stores.store_product_discount ===
                                                    0 ? (
                                                      ""
                                                    ) : (
                                                      <>
                                                        <span className="fs-4 fw-bold old__price ml-0 pl-0">
                                                          $
                                                          {
                                                            item.product_actual_price
                                                          }
                                                        </span>
                                                        <span className="text__primary">
                                                          &nbsp;(
                                                          {
                                                            stores.store_product_discount
                                                          }{" "}
                                                          % Discount)
                                                        </span>
                                                      </>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {/* Cart Section Start */}
                                          <div className="product__categories--grid__items product__bg align-items-center cursor-pointer">
                                            <div className="position__relative">
                                              <div className="px-2">
                                                <div className="d-flex justify-content-between align-items-center">
                                                  <div className="w-50">
                                                    <div className="quantity__box">
                                                      <button
                                                        type="button"
                                                        className="quantity__value quickview__value--quantity decrease"
                                                        aria-label="quantity value"
                                                        onClick={
                                                          decreaseCartNumber
                                                        }
                                                      >
                                                        -
                                                      </button>
                                                      <label>
                                                        <input
                                                          type="number"
                                                          className="quantity__number quickview__value--number"
                                                          value={cartNumber}
                                                          onChange={(e) =>
                                                            setCartNumber(
                                                              e.target.value
                                                            )
                                                          }
                                                        />
                                                      </label>
                                                      <button
                                                        type="button"
                                                        className="quantity__value quickview__value--quantity increase"
                                                        aria-label="quantity value"
                                                        onClick={
                                                          increaseCartNumber
                                                        }
                                                      >
                                                        +
                                                      </button>
                                                    </div>
                                                  </div>
                                                  <div className="w-50">
                                                    <button
                                                      className="variant__buy--now__btn btn"
                                                      type="submit"
                                                    >
                                                      Add to Cart
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {/* Cart Section End */}
                                        </div>
                                      );
                                    }
                                  )}
                                {/* </div> */}
                              </>
                            );
                          })}
                      </div>
                      {/* Vending Machine List ENd */}
                      {/* =============== tabs Start =================== */}
                      <div
                        className="product__details--tab__section sidebar__tab--section border-radius-10"
                        id="tabContainer"
                      >
                        <ul
                          className="nav nav-pills mb-30 product-detail-tabs"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link active"
                              id="pills-disc-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-disc"
                              type="button"
                              role="tab"
                              aria-controls="pills-disc"
                              aria-selected="true"
                            >
                              Description
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="pills-reviews-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-reviews"
                              type="button"
                              role="tab"
                              aria-controls="pills-reviews"
                              aria-selected="false"
                            >
                              Product Reviews
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="pills-subCat-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-subCat"
                              type="button"
                              role="tab"
                              aria-controls="pills-subCat"
                              aria-selected="false"
                            >
                              Sub Category Info
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="pills-brands-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-brands"
                              type="button"
                              role="tab"
                              aria-controls="pills-brands"
                              aria-selected="false"
                            >
                              Brand Info
                            </button>
                          </li>
                        </ul>
                        <div
                          className="tab-content product__details--tab__inner border-radius-10"
                          id="pills-tabContent"
                        >
                          {/* ============= Discription Start */}
                          <div
                            className="tab-pane fade show active"
                            id="pills-disc"
                            role="tabpanel"
                            aria-labelledby="pills-disc-tab"
                          >
                            <div className="product__tab--content ">
                              <div className="product__tab--content__step mb-30">
                                {/* <h2 className="product__tab--content__title h4 mb-10">Nam provident sequi</h2> */}
                                <p className="product__tab--content__desc">
                                  {item.product_description}
                                </p>
                              </div>
                            </div>
                          </div>
                          {/* ============= Discription End */}
                          {/* ============= Reviews Start */}
                          <div
                            className="tab-pane fade"
                            id="pills-reviews"
                            role="tabpanel"
                            aria-labelledby="pills-reviews-tab"
                          >
                            <div className="product__reviews">
                              <div className="product__reviews--header">
                                <h2 className="product__reviews--header__title h3 mb-20">
                                  Customer Reviews
                                </h2>
                                <div className="reviews__ratting d-flex align-items-center">
                                  <ul className="d-flex">
                                    <li className="reviews__ratting--list">
                                      <span className="reviews__ratting--icon">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="14.105"
                                          height="12.732"
                                          viewBox="0 0 10.105 9.732"
                                        >
                                          <path
                                            data-name="star - Copy"
                                            d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                            transform="translate(0 -0.018)"
                                            fill="currentColor"
                                          />
                                        </svg>
                                      </span>
                                    </li>
                                    <li className="reviews__ratting--list">
                                      <span className="reviews__ratting--icon">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="14.105"
                                          height="12.732"
                                          viewBox="0 0 10.105 9.732"
                                        >
                                          <path
                                            data-name="star - Copy"
                                            d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                            transform="translate(0 -0.018)"
                                            fill="currentColor"
                                          />
                                        </svg>
                                      </span>
                                    </li>
                                    <li className="reviews__ratting--list">
                                      <span className="reviews__ratting--icon">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="14.105"
                                          height="12.732"
                                          viewBox="0 0 10.105 9.732"
                                        >
                                          <path
                                            data-name="star - Copy"
                                            d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                            transform="translate(0 -0.018)"
                                            fill="currentColor"
                                          />
                                        </svg>
                                      </span>
                                    </li>
                                    <li className="reviews__ratting--list">
                                      <span className="reviews__ratting--icon">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="14.105"
                                          height="12.732"
                                          viewBox="0 0 10.105 9.732"
                                        >
                                          <path
                                            data-name="star - Copy"
                                            d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                            transform="translate(0 -0.018)"
                                            fill="currentColor"
                                          />
                                        </svg>
                                      </span>
                                    </li>
                                    <li className="reviews__ratting--list">
                                      <span className="reviews__ratting--icon">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="14.105"
                                          height="12.732"
                                          viewBox="0 0 10.105 9.732"
                                        >
                                          <path
                                            data-name="star - Copy"
                                            d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                            transform="translate(0 -0.018)"
                                            fill="#c7c5c2"
                                          />
                                        </svg>
                                      </span>
                                    </li>
                                  </ul>
                                  <span className="reviews__summary--caption">
                                    Based on 2 reviews
                                  </span>
                                </div>
                                <a
                                  className="actions__newreviews--btn btn"
                                  href="#writereview"
                                >
                                  Write A Review
                                </a>
                              </div>
                              <div className="reviews__comment--area">
                                <div className="reviews__comment--list d-flex">
                                  <div className="reviews__comment--thumb">
                                    <img
                                      src="assets/img/other/comment-thumb1.png"
                                      alt="comment-thumb"
                                    />
                                  </div>
                                  <div className="reviews__comment--content">
                                    <div className="reviews__comment--top d-flex justify-content-between">
                                      <div className="reviews__comment--top__left">
                                        <h3 className="reviews__comment--content__title h4">
                                          Jakes on
                                        </h3>
                                        <ul className="reviews__ratting d-flex">
                                          <li className="reviews__ratting--list">
                                            <span className="reviews__ratting--icon">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="14.105"
                                                height="12.732"
                                                viewBox="0 0 10.105 9.732"
                                              >
                                                <path
                                                  data-name="star - Copy"
                                                  d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                                  transform="translate(0 -0.018)"
                                                  fill="currentColor"
                                                />
                                              </svg>
                                            </span>
                                          </li>
                                          <li className="reviews__ratting--list">
                                            <span className="reviews__ratting--icon">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="14.105"
                                                height="12.732"
                                                viewBox="0 0 10.105 9.732"
                                              >
                                                <path
                                                  data-name="star - Copy"
                                                  d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                                  transform="translate(0 -0.018)"
                                                  fill="currentColor"
                                                />
                                              </svg>
                                            </span>
                                          </li>
                                          <li className="reviews__ratting--list">
                                            <span className="reviews__ratting--icon">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="14.105"
                                                height="12.732"
                                                viewBox="0 0 10.105 9.732"
                                              >
                                                <path
                                                  data-name="star - Copy"
                                                  d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                                  transform="translate(0 -0.018)"
                                                  fill="currentColor"
                                                />
                                              </svg>
                                            </span>
                                          </li>
                                          <li className="reviews__ratting--list">
                                            <span className="reviews__ratting--icon">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="14.105"
                                                height="12.732"
                                                viewBox="0 0 10.105 9.732"
                                              >
                                                <path
                                                  data-name="star - Copy"
                                                  d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                                  transform="translate(0 -0.018)"
                                                  fill="currentColor"
                                                />
                                              </svg>
                                            </span>
                                          </li>
                                          <li className="reviews__ratting--list">
                                            <span className="reviews__ratting--icon">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="14.105"
                                                height="12.732"
                                                viewBox="0 0 10.105 9.732"
                                              >
                                                <path
                                                  data-name="star - Copy"
                                                  d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                                  transform="translate(0 -0.018)"
                                                  fill="#c7c5c2"
                                                />
                                              </svg>
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                      <span className="reviews__comment--content__date">
                                        February 13, 2022
                                      </span>
                                    </div>
                                    <p className="reviews__comment--content__desc">
                                      Lorem ipsum, dolor sit amet consectetur
                                      adipisicing elit. Eos ex repellat officiis
                                      neque. Veniam, rem nesciunt. Assumenda
                                      distinctio, autem error repellat eveniet
                                      ratione dolor facilis accusantium amet
                                      pariatur, non eius!
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div
                                id="writereview"
                                className="reviews__comment--reply__area"
                              >
                                <form action="#">
                                  <h3 className="reviews__comment--reply__title mb-15">
                                    Add a review{" "}
                                  </h3>
                                  <div className="reviews__ratting d-flex align-items-center mb-20">
                                    <ul className="d-flex">
                                      <li className="reviews__ratting--list">
                                        <span className="reviews__ratting--icon">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14.105"
                                            height="12.732"
                                            viewBox="0 0 10.105 9.732"
                                          >
                                            <path
                                              data-name="star - Copy"
                                              d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                              transform="translate(0 -0.018)"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                      </li>
                                      <li className="reviews__ratting--list">
                                        <span className="reviews__ratting--icon">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14.105"
                                            height="12.732"
                                            viewBox="0 0 10.105 9.732"
                                          >
                                            <path
                                              data-name="star - Copy"
                                              d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                              transform="translate(0 -0.018)"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                      </li>
                                      <li className="reviews__ratting--list">
                                        <span className="reviews__ratting--icon">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14.105"
                                            height="12.732"
                                            viewBox="0 0 10.105 9.732"
                                          >
                                            <path
                                              data-name="star - Copy"
                                              d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                              transform="translate(0 -0.018)"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                      </li>
                                      <li className="reviews__ratting--list">
                                        <span className="reviews__ratting--icon">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14.105"
                                            height="12.732"
                                            viewBox="0 0 10.105 9.732"
                                          >
                                            <path
                                              data-name="star - Copy"
                                              d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                              transform="translate(0 -0.018)"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                      </li>
                                      <li className="reviews__ratting--list">
                                        <span className="reviews__ratting--icon">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14.105"
                                            height="12.732"
                                            viewBox="0 0 10.105 9.732"
                                          >
                                            <path
                                              data-name="star - Copy"
                                              d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                              transform="translate(0 -0.018)"
                                              fill="#c7c5c2"
                                            />
                                          </svg>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 mb-10">
                                      <textarea
                                        className="reviews__comment--reply__textarea"
                                        placeholder="Your Comments...."
                                      ></textarea>
                                    </div>
                                    <div className="col-lg-6 col-md-6 mb-15">
                                      <label>
                                        <input
                                          className="reviews__comment--reply__input"
                                          placeholder="Your Name...."
                                          type="text"
                                        />
                                      </label>
                                    </div>
                                    <div className="col-lg-6 col-md-6 mb-15">
                                      <label>
                                        <input
                                          className="reviews__comment--reply__input"
                                          placeholder="Your Email...."
                                          type="email"
                                        />
                                      </label>
                                    </div>
                                  </div>
                                  <button
                                    className="btn text-white"
                                    data-hover="Submit"
                                    type="submit"
                                  >
                                    SUBMIT
                                  </button>
                                </form>
                              </div>
                            </div>
                          </div>
                          {/* ============= Reviews End */}
                          <div
                            className="tab-pane fade"
                            id="pills-subCat"
                            role="tabpanel"
                            aria-labelledby="pills-subCat-tab"
                          >
                            <div className="product__tab--conten">
                              <div className="product__tab--content__step mb-30">
                                {/* <h2 className="product__tab--content__title h4 mb-10">
                                  Nam provident sequi
                                </h2> */}
                                {subCatData &&
                                  subCatData.map((item) => (
                                    <div
                                      className="row"
                                      key={item.sub_category_id}
                                    >
                                      <div className="col-lg-6 col-xs-12 col-sm-12">
                                        {item.sub_category_images.length !==
                                        0 ? (
                                          <div>
                                            <img
                                              src={item.sub_category_images[0]}
                                              alt={item.sub_category_title}
                                              title={item.sub_category_title}
                                              className="img-fluid w-100"
                                            />
                                          </div>
                                        ) : (
                                          <div>
                                            <img
                                              src={defaultImage}
                                              alt={item.sub_category_title}
                                              title={item.sub_category_title}
                                              className="img-fluid w-100"
                                            />
                                          </div>
                                        )}
                                      </div>
                                      <div className="col-lg-6 col-xs-12 col-sm-12">
                                        <div>
                                          <h4 className="h4 fw-bold ">
                                            {item.sub_category_title}
                                            {item.brand_state === 1 ? (
                                              <span className="ms-3 text-break fs-4 text__primary">
                                                Featured
                                              </span>
                                            ) : null}
                                          </h4>

                                          <p className="text-break fs-4">
                                            {item.sub_category_description}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="pills-brands"
                            role="tabpanel"
                            aria-labelledby="pills-brands-tab"
                          >
                            <div className="product__tab--conten">
                              <div className="product__tab--content__step mb-30">
                                {/* <h2 className="product__tab--content__title h4 mb-10">
                                  Brands Information
                                </h2> */}
                                {brandData &&
                                  brandData.map((item) => (
                                    <div className="row" key={item.brand_id}>
                                      <div className="col-lg-6 col-xs-12 col-sm-12">
                                        {item.brand_images ? (
                                          <div>
                                            <img
                                              src={item.brand_images[0]}
                                              alt={item.brand_title}
                                              title={item.brand_title}
                                              className="img-fluid w-100"
                                            />
                                          </div>
                                        ) : (
                                          <div>
                                            <img
                                              src={defaultImage}
                                              alt={item.brand_title}
                                              title={item.brand_title}
                                              className="img-fluid w-100"
                                            />
                                          </div>
                                        )}
                                      </div>
                                      <div className="col-lg-6 col-xs-12 col-sm-12">
                                        <div>
                                          <h4 className="h4 fw-bold ">
                                            {item.brand_title}
                                            {item.brand_state === 1 ? (
                                              <span className="ms-3 text-break fs-4 text__primary">
                                                Featured
                                              </span>
                                            ) : null}
                                          </h4>

                                          <p className="text-break fs-4">
                                            {item.brand_description}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* =============== tabs End =================== */}
                      {/* ============== You may also like these */}
                      <div className="product__section product__section--style3 section--padding pt-5">
                        <div className="section__heading3 mb-40">
                          <h2 className="section__heading3--maintitle">
                            You may also like
                          </h2>
                        </div>
                        <Swiper
                          effect={"fade"}
                          centeredSlides={false}
                          slidesPerView={4}
                          spaceBetween={30}
                          autoplay={false}
                          breakpoints={{
                            0: {
                              slidesPerView: 1,
                            },
                            400: {
                              slidesPerView: 1,
                            },
                            639: {
                              slidesPerView: 1,
                            },
                            865: {
                              slidesPerView: 1,
                            },
                            1000: {
                              slidesPerView: 3,
                            },
                            1500: {
                              slidesPerView: 4,
                            },
                            1700: {
                              slidesPerView: 4,
                            },
                            2560: {
                              slidesPerView: 4,
                            },
                          }}
                          navigation={true}
                          className="product__section--inner product__section--style3__inner swiper"
                        >
                          <div className="swiper-wrapper">
                            {subCatData &&
                              subCatData.map((subcatData, index) => {
                                return (
                                  <div key={index}>
                                    {subcatData.products &&
                                      subcatData.products.map(
                                        (products, index) => {
                                          return (
                                            <SwiperSlide
                                              className="swiper-slide p-2"
                                              key={index}
                                            >
                                              <ProductCard item={products} />
                                            </SwiperSlide>
                                          );
                                        }
                                      )}
                                  </div>
                                );
                              })}
                          </div>
                        </Swiper>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          {/* SPECIFIC SHOP POPUP */}
          {shopModelPopup && (
            <div
              className="modal fade is-visible"
              id="storeDataModel"
              tabIndex="-1"
              aria-labelledby="storeDataModelLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog quickview__main--wrapper">
                <div className="modal-header quickview__header">
                  <button
                    className="close-modal quickview__close--btn"
                    ariaLabel="close modal"
                    onClick={() => {
                      setShopModelpopup(false);
                    }}
                  >
                    ✕{" "}
                  </button>
                </div>
                <div className="quickview__inner mt-5">
                  {shopSpecificData &&
                    shopSpecificData.map((shopData, index) => {
                      return (
                        <div className="row" key={index}>
                          <div className="col">
                            <div className="quickview__info">
                              <h2 className="product__details--info__title mb-15">
                                {shopData.shop_name}
                              </h2>
                              <p className="product__details--info__desc mb-10">
                                {shopData.shop_description}
                              </p>
                              <div className="product__variant">
                                {shopData.shop_address && (
                                  <div className="product__variant--list mb-15">
                                    <p className="product__variant--title mb-8">
                                      Address:{" "}
                                    </p>
                                    <address>{shopData.shop_address}</address>
                                  </div>
                                )}
                                {shopData.shop_shipping_zip_codes.length !==
                                  0 &&
                                  shopData.shop_shipping_zip_codes.map(
                                    (zipcode, index) => {
                                      return (
                                        <span
                                          className="bg-success p-2 me-3 text-white"
                                          key={index}
                                        >
                                          {zipcode}
                                        </span>
                                      );
                                    }
                                  )}
                                {/* {storeData.hasOwnProperty('store_vending_machines') && (
                                <div className="product__variant--list mb-15">
                                  <p className="product__variant--title mb-8">
                                    Vending Missions:{" "}
                                  </p>
                                  {storeData.store_vending_machines.length !==
                                    0 &&
                                    storeData.store_vending_machines.map(
                                      (vending_machines) => (
                                        <span className="bg-success p-2 me-3 text-white">
                                          {vending_machines}
                                        </span>
                                      ),
                                    )}
                                </div>
                              )} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          )}
          {/* SPECIFIC VENDING MACHINE POPUP */}
          {vmModelPopup && (
            <div
              className="modal fade is-visible"
              id="DataModel"
              tabIndex="-1"
              aria-labelledby="ModelLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog quickview__main--wrapper">
                <div className="modal-header quickview__header">
                  <button
                    className="close-modal quickview__close--btn"
                    ariaLabel="close modal"
                    onClick={() => {
                      setVmModelpopup(false);
                    }}
                  >
                    ✕{" "}
                  </button>
                </div>
                <div className="quickview__inner mt-5">
                  {specificVMData.map((vmData, index) => (
                    <div
                      className="row row-cols-lg-2 row-cols-md-2"
                      key={index}
                    >
                      <div className="col-lg-6">
                        {vmData.vm_images.length !== 0 ? (
                          <div>
                            <img
                              src={vmData.vm_images[0]}
                              alt={vmData.vm_name}
                              title={vmData.vm_name}
                            />
                          </div>
                        ) : (
                          <div>
                            <img
                              src={defaultImage}
                              alt={vmData.store_name}
                              title={vmData.store_name}
                            />
                          </div>
                        )}
                      </div>
                      <div className="col">
                        <div className="quickview__info">
                          <h2 className="product__details--info__title mb-15">
                            {vmData.vm_name}
                          </h2>
                          <p className="product__details--info__desc mb-10">
                            {vmData.vm_description}
                          </p>
                          <div className="product__variant">
                            {vmData.vm_shipping_zip_codes !== 0 && (
                              <div className="product__variant--list mb-15">
                                <p className="product__variant--title mb-8">
                                  VM Shipping Zip COdes:{" "}
                                </p>
                                {vmData.vm_shipping_zip_codes !== 0 &&
                                  vmData.vm_shipping_zip_codes.map(
                                    (zipcodes, index) => (
                                      <span
                                        className="bg-success p-2 me-3 text-white"
                                        key={index}
                                      >
                                        {zipcodes}
                                      </span>
                                    )
                                  )}
                              </div>
                            )}
                            {vmData.vm_address && (
                              <div className="product__variant--list mb-15">
                                <p className="product__variant--title mb-8">
                                  Address:{" "}
                                </p>
                                <address>{vmData.vm_address}</address>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </section>
      ) : (
        <Loader />
      )}
    </>
  );
};
