import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { DetailPageSkelton } from "../components/Skeltons/DetailSkelton";
import { ProductListSkelton } from "../components/Skeltons/ProductListSkelton";
import defaultImage from "../assets/img/default.png";
import { getSpecificBrand } from "../api/BrandsApi";
import { ProductCard } from "../components/Products/ProductCard";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/components/pagination/pagination.min.css";
import SwiperCore, { Navigation } from "swiper";
import { useErrorBoundary } from "react-error-boundary";

SwiperCore.use([Navigation]);

export const BrandDetail = () => {
  const { id } = useParams();
  const { showBoundary } = useErrorBoundary();

  const displayData = !id;
  const [Detail, setDetail] = useState([]);
  const [displayState, setdisplayState] = useState(false);
  const [visible, setVisible] = useState(12);

  useEffect(() => {
    if (!displayData) {
      getBrandData(id);
    }
  }, []);
  var brandDataArray = [];
  const getBrandData = async () => {
    await getSpecificBrand(id)
      .then((result) => {
        if (result.data.status === 1) {
          brandDataArray.push(result.data.brand);
          setDetail(brandDataArray);
          setdisplayState(true);
        }
      })
      .catch((error) => {
        showBoundary(error);
      });
  };
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 12);
  };
  const [imageIndex, setImageIndex] = useState(0);
  const getImage = (index) => {
    setImageIndex(index);
  };
  return (
    <>
      {displayState ? (
        <section className="product__section product__categories--section section--padding pt-5">
          <div className="container">
            {Detail &&
              Detail.map((item) => (
                <div key={item.brand_id}>
                  <div className="product__details--section section--padding pt-0">
                    <div className="container">
                      <div className="row mb-5">
                        <div className="col">
                          <div className="breadcrumb__content">
                            <ul className="breadcrumb__content--menu d-flex">
                              <li className="breadcrumb__content--menu__items">
                                <Link to="/">Home</Link>
                              </li>
                              <li className="breadcrumb__content--menu__items">
                                <Link to="/all-brands">All Brands</Link>
                              </li>
                              <li className="breadcrumb__content--menu__items text__primary">
                                <span>{item.brand_title}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="row row-cols-lg-2 row-cols-md-2">
                        <div className="col">
                          <div className="product__details--media">
                            <div className="product__media--preview__items">
                              <div
                                id="carouselIndicators"
                                className="carousel slide"
                                data-bs-ride="carousel"
                              >
                                <div className="carousel-inner">
                                  {item.brand_images.map(
                                    (brandImage, index) => {
                                      return (
                                        <div
                                          className={
                                            index === imageIndex
                                              ? "carousel-item active"
                                              : "carousel-item"
                                          }
                                          key={index}
                                        >
                                          <img
                                            src={brandImage}
                                            className="d-block w-100"
                                            alt={index}
                                          />
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                                <button
                                  className="carousel-control-prev"
                                  type="button"
                                  data-bs-target="#carouselIndicators"
                                  data-bs-slide="prev"
                                >
                                  <span
                                    className="carousel-control-prev-icon"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="visually-hidden">
                                    Previous
                                  </span>
                                </button>
                                <button
                                  className="carousel-control-next"
                                  type="button"
                                  data-bs-target="#carouselIndicators"
                                  data-bs-slide="next"
                                >
                                  <span
                                    className="carousel-control-next-icon"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="visually-hidden">Next</span>
                                </button>
                              </div>
                              <Swiper
                                effect={"fade"}
                                centeredSlides={false}
                                slidesPerView={6}
                                spaceBetween={30}
                                autoplay={false}
                                breakpoints={{
                                  0: {
                                    slidesPerView: 1,
                                  },
                                  400: {
                                    slidesPerView: 2,
                                  },
                                  639: {
                                    slidesPerView: 2,
                                  },
                                  865: {
                                    slidesPerView: 2,
                                  },
                                  1000: {
                                    slidesPerView: 3,
                                  },
                                  1500: {
                                    slidesPerView: 4,
                                  },
                                  1700: {
                                    slidesPerView: 5,
                                  },
                                  2560: {
                                    slidesPerView: 5,
                                  },
                                }}
                                navigation={true}
                                className="product__media--nav swiper"
                              >
                                <div className="swiper-wrapper">
                                  {item.brand_images.length === 0 ? (
                                    <div className="d-flex justify-content-center align-items-center mb-40">
                                      <h2 className="section__heading--maintitle text__primary">
                                        No images Found
                                      </h2>
                                    </div>
                                  ) : (
                                    item.brand_images.map(
                                      (brandImage, index) => {
                                        return (
                                          <SwiperSlide
                                            className="swiper-slide "
                                            key={index}
                                            onClick={() => {
                                              getImage(index);
                                            }}
                                          >
                                            <div className="product__media--nav__items">
                                              <img
                                                className="product__media--nav__items--img"
                                                src={brandImage}
                                                alt="product-nav-img"
                                              />
                                            </div>
                                          </SwiperSlide>
                                        );
                                      }
                                    )
                                  )}
                                </div>
                              </Swiper>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="product__details--info">
                            <h2 className="product__details--info__title mb-15">
                              {item.brand_title}
                            </h2>
                            <p className="product__details--info__desc mb-20">
                              {item.brand_description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row row-md-reverse">
                    {/* ===================== Right SideBar start ============= */}
                    <div className="col-xl-12 col-lg-12">
                      {item.products.length !== 0 ? (
                        <div
                          className="section__heading d-flex justify-content-between align-items-center mb-40"
                          key={item.brand_id}
                        >
                          <h2 className="section__heading--maintitle">
                            {item.brand_title} Products{" "}
                          </h2>
                        </div>
                      ) : null}
                      <div className="shop__product--wrapper">
                        <div className="tab_content">
                          <div
                            id="product_grid"
                            className="tab_pane active show"
                          >
                            <div className="product__section--inner product__section--style3__inner">
                              <div className="row row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-3 row-cols-sm-3 row-cols-2 mb--n30">
                                {item.products.length === 0 ? (
                                  <div className="mb-40">
                                    <h2 className="section__heading--maintitle text__primary">
                                      No Products Found
                                    </h2>
                                  </div>
                                ) : (
                                  item.products
                                    .slice(0, visible)
                                    .map((products) => {
                                      return (
                                        <div key={products.product_id}>
                                          <ProductCard item={products} />
                                        </div>
                                      );
                                    })
                                )}
                              </div>
                              {item.products.length > 12 ? (
                                <div className="row mt-5 pt-5">
                                  <div className="col d-flex justify-content-center align-items-center">
                                    <button
                                      className="btn btn-success"
                                      onClick={showMoreItems}
                                    >
                                      Load More
                                    </button>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ===================== Right SideBar End ============= */}
                  </div>
                </div>
              ))}
          </div>
        </section>
      ) : (
        <div>
          <DetailPageSkelton />
          <div className="container">
            <div className="product__section--inner product__section--style3__inner">
              <div className="row row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-3 row-cols-sm-3 row-cols-2 mb--n30">
                <ProductListSkelton />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
