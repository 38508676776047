import Header from "./Header";
import { Footer } from "./Footer";
import { OurServiceStrip } from "./OurServiceStrip";
import { Newsettler } from "./NewSettler";
// import { useEffect } from 'react';

export const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <main className="main__content_wrapper">
        {children}
        <OurServiceStrip />
      </main>

      <Footer />
      <Newsettler />
    </>
  );
};
