import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { getCategoriesData } from "../../api/CategoriesApi";
import defaultCategoryImage from "../../assets/img/default.png";
import { SliderSkelton } from "../Skeltons/SliderSkelton";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/components/pagination/pagination.min.css";
import SwiperCore, { Navigation } from "swiper";
import { NoCategoriesFound } from "./NoCategoriesFound";

SwiperCore.use([Navigation]);

export const FeaturedCategories = () => {
  const { showBoundary } = useErrorBoundary();

  const [allCategories, setAllCategories] = useState([]);
  const [displayState, setDisplayState] = useState(false);
  const getFeaturedCategoriesData = async () => {
    await getCategoriesData(1)
      .then((result) => {
        let catData = result.data;
        setAllCategories(catData.categories);
        setDisplayState(true);
      })
      .catch((error) => {
        showBoundary(error);
      });
  };

  useEffect(() => {
    getFeaturedCategoriesData();
  }, []);
  return (
    <>
      {displayState ? (
        <section className="product__section product__categories--section section--padding pt-0">
          <div className="container">
            <div className="section__heading d-flex justify-content-between align-items-center mb-40">
              <h2 className="section__heading--maintitle font-18">
                Featured Categories{" "}
              </h2>
              <div>
                <Link to="/all-categories">
                  View All <i className="fa fa-angle-double-right fs-3"></i>
                </Link>
              </div>
            </div>
            {allCategories.length === 0 ? <NoCategoriesFound /> : null}
            <Swiper
              effect={"fade"}
              centeredSlides={false}
              slidesPerView={6}
              spaceBetween={10}
              autoplay={false}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                },
                400: {
                  slidesPerView: 2,
                },
                639: {
                  slidesPerView: 2,
                },
                865: {
                  slidesPerView: 2,
                },
                1000: {
                  slidesPerView: 12,
                },
                1500: {
                  slidesPerView: 12,
                },
                1700: {
                  slidesPerView: 12,
                },
                2560: {
                  slidesPerView: 12,
                },
              }}
              navigation={allCategories.length > 12 ? true : false}
              className="product__section--inner categories2__swiper--activation swiper"
            >
              <div className="swiper-wrapper">
                {allCategories &&
                  allCategories.map((item) => (
                    <SwiperSlide
                      className="swiper-slide "
                      key={item.category_id}
                    >
                      <div
                        className="categories2__product--items text-center"
                        to="/categories-detail/1"
                      >
                        <div className="categories2__product--thumbnail">
                          {item.category_images ? (
                            <Link
                              className="categories2__product--link display-block"
                              to={`/categories-detail/${item.category_id}`}
                            >
                              <img
                                className="categories2__product--img display-block rounded-circle cat-img"
                                src={item.category_images[0]}
                                alt={item.category_title}
                                title={item.category_title}
                              />
                            </Link>
                          ) : (
                            <Link
                              className="categories2__product--link display-block"
                              to={`/categories-detail/${item.category_id}`}
                            >
                              <img
                                className="categories2__product--img display-block rounded-circle cat-img"
                                src={defaultCategoryImage}
                                alt={item.category_title}
                                title={item.category_title}
                              />
                            </Link>
                          )}
                        </div>
                        <div className="product__categories--content2">
                          <h3 className="product__categories--content2__maintitle product-list-title">
                            <Link to={`/categories-detail/${item.category_id}`}>
                              {" "}
                              {item.category_title}
                            </Link>
                          </h3>
                          {/* <span className="product__categories--content__subtitle">7 products</span> */}
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
              </div>
            </Swiper>
          </div>
        </section>
      ) : (
        <SliderSkelton />
      )}
    </>
  );
};
