import axios from "axios";

let backendUrl = process.env.REACT_APP_BACKEND_URL;

export async function getAllVendingMachines() {
  return await axios.get(backendUrl + "vending_machine");
}

export async function getSpecificVendingMachine(id) {
  return await axios.get(backendUrl + "vending_machine/" + id);
}

export async function getAllProductsOfVendingMachine(id) {
  return await axios.get(backendUrl + "vending_machine/products/" + id);
}
