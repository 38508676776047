import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
// import '../src/assets/css/vendor/bootstrap.min.css';
// import '../src/assets/css/plugins/swiper-bundle.min.css';
// import '../src/assets/css/plugins/glightbox.min.css';
import * as $ from "jquery";
import { ScrollToTop } from "./ScrolltoTop";
import "react-loading-skeleton/dist/skeleton.css";
import "react-toastify/dist/ReactToastify.css";

window.jQuery = window.$ = $;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ScrollToTop />
    <App />
  </BrowserRouter>
);
