import { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";

import defaultImage from "../../assets/img/default.png";
import { SidePanel } from "../../components/Sidepanel/SidePanel";
import { getproductList, getallFeaturedProdcuts } from "../../api/ProductsApi";
import { ProductListSkelton } from "../../components/Skeltons/ProductListSkelton";
import { locationPinCode } from "../../App";
import { getSpecificBrand } from "../../api/BrandsApi";
import { getSpecificSubCategoriesData } from "../../api/SubCategoriesApi";
import { getSpecificCategoriesData } from "../../api/CategoriesApi";
import { NoProductsFound } from "../../components/Products/NoProductsFound";
import { ProductCard } from "../../components/Products/ProductCard";
import { useErrorBoundary } from "react-error-boundary";

export const ProductList = () => {
  const { searchtag } = useParams();
  const { showBoundary } = useErrorBoundary();
  const [productdata, setproductData] = useState([]);
  const [visible, setVisible] = useState(12);
  const [productdisplayState, setproductdisplayState] = useState(false);
  const [searchProduct, setSearchProduct] = useState("");
  const showMoreItems = (productdata) => {
    setVisible((prevValue) => prevValue + (productdata.length - 12));
  };
  const [featuredState, setFeaturedState] = useState(false);
  // Location Pin code
  const [zipCode, setZipCode] = useContext(locationPinCode);
  useEffect(() => {
    productsList();
  }, []);
  // GET AVAILABLE PRODUCTS
  const productsList = async () => {
    await getproductList()
      .then((result) => {
        if (result.data.status === 1) {
          var productListArray = result.data.products;
          setproductData(productListArray);
          setproductdisplayState(true);
          setFeaturedState(false);
          if (searchtag) {
            setSearchProduct(searchtag);
          } else {
            setSearchProduct("");
          }
        }
      })
      .catch((error) => {
        // console.log(error);
        showBoundary(error);
      });
  };

  // GET FEATURED PRODUCTS
  const getFeaturedProducts = async () => {
    // setproductData([]);
    await getallFeaturedProdcuts()
      .then((result) => {
        if (result.data.status === 1) {
          setproductData(result.data.products);
          setproductdisplayState(true);
          setFeaturedState(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sidepanelHandler = (clicked_id, type) => {
    setproductData([]);
    if (type == "brand") {
      getBrandsbyId(clicked_id);
    } else if (type == "category") {
      getCategoryById(clicked_id);
    } else if (type == "subcategory") {
      getSubCategoryById(clicked_id);
    }
  };
  var title = [];
  // GET BRNADS BY ID
  const getBrandsbyId = async (brand_id) => {
    await getSpecificBrand(brand_id)
      .then((result) => {
        if (result.data.status === 1) {
          setproductData(result.data.brand.products);
        }
        window.scrollTo({ top: 0, behavior: "smooth" });
      })
      .catch((error) => {
        showBoundary(error);
      });
  };

  // GET SUBCATEGORY BYID
  const getSubCategoryById = async (sub_cat_id) => {
    await getSpecificSubCategoriesData(sub_cat_id)
      .then((result) => {
        if (result.data.status === 1) {
          setproductData(result.data.sub_category.products);
        }
        window.scrollTo({ top: 0, behavior: "smooth" });
      })
      .catch((error) => {
        showBoundary(error);
      });
  };
  // GETCATEGORYBYID
  var productListArray = [];
  const getCategoryById = async (cat_id) => {
    await getSpecificCategoriesData(cat_id)
      .then((result) => {
        if (result.data.status === 1) {
          console.log(result.data.categories);
          var categoriesArray = result.data.categories;
          for (var i = 0; i < categoriesArray.length; i++) {
            var subCategoriesArray = categoriesArray[i].sub_categories;
            for (var j = 0; j < subCategoriesArray.length; j++) {
              var productsArray = subCategoriesArray[j].products;
              for (var k = 0; k < productsArray.length; k++) {
                productListArray.push(productsArray[k]);
              }
            }
          }
          setproductData(productListArray);
        }
        window.scrollTo({ top: 0, behavior: "smooth" });
      })
      .catch((error) => {
        showBoundary(error);
      });
  };
  return (
    <>
      <section className="shop__section section--padding pt-5">
        <div className="container-fluid">
          <div className="row mb-5">
            <div className="col">
              <div className="breadcrumb__content">
                <ul className="breadcrumb__content--menu d-flex">
                  <li className="breadcrumb__content--menu__items">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb__content--menu__items text__primary">
                    <span>All Products</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* ===================== Sorting Conatiner start ============= */}
          <div className="shop__header bg__gray--color d-flex align-items-center justify-content-end mb-30">
            <div className="product__view--mode d-flex flex-wrap align-items-center">
              {/* d-none */}
              <div className="product__view--mode__list product__view--search d-xl-block">
                <form className="product__view--search__form" action="#">
                  <label>
                    <input
                      className="product__view--search__input border-0"
                      placeholder="Search by Product Name"
                      type="text"
                      value={searchProduct}
                      onChange={(e) => {
                        setSearchProduct(e.target.value);
                      }}
                    />
                  </label>
                </form>
              </div>
              <div className="product__view--mode__list">
                <div className="product__tab--one product__grid--column__buttons d-flex justify-content-center">
                  <button
                    className={
                      featuredState
                        ? "border bg-white py-2 px-3 rounded"
                        : "border bg-white py-2 px-3 rounded is__selected"
                    }
                    onClick={() => {
                      productsList();
                    }}
                  >
                    All Products
                  </button>
                  <button
                    className={
                      featuredState
                        ? "border bg-white py-2 px-3 rounded is__selected"
                        : "border bg-white py-2 px-3 rounded"
                    }
                    onClick={() => {
                      getFeaturedProducts();
                    }}
                  >
                    Featured Products
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* ===================== Sorting Conatiner End ============= */}
          <div className="row">
            {/* ===================== Sidebar Start ============= */}
            <SidePanel onClickHandler={sidepanelHandler} />
            {/* ===================== Sidebar End ============= */}
            {/* ===================== Right SideBar start ============= */}
            <div className="col-xl-9 col-lg-8">
              <div className="shop__product--wrapper">
                <div className="tab_content">
                  <div id="product_grid" className="tab_pane active show">
                    <div className="product__section--inner product__section--style3__inner">
                      <div className="row row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-3 row-cols-sm-3 row-cols-2 mb--n30">
                        {productdata.length !== 0
                          ? productdata
                              .filter((item) =>
                                item.product_title
                                  .toLowerCase()
                                  .includes(searchProduct.toLowerCase())
                              )
                              .slice(0, visible)
                              .map((item, index) => (
                                <div key={index}>
                                  <ProductCard item={item} />
                                </div>
                              ))
                          : null}
                        {productdisplayState ? null : <ProductListSkelton />}
                      </div>
                      {productdisplayState ? "" : <NoProductsFound />}
                      {visible < productdata.length ? (
                        <div className="row mt-5 pt-5">
                          <div className="col d-flex justify-content-center align-items-center">
                            <button
                              className="btn btn-success"
                              onClick={() => {
                                showMoreItems(productdata);
                              }}
                            >
                              Load More
                            </button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ===================== Right SideBar End ============= */}
          </div>
        </div>
      </section>
    </>
  );
};
