import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { getallShops } from "../../api/Shops";
import defaultCategoryImage from "../../assets/img/shop.png";
import { SliderSkelton } from "../Skeltons/SliderSkelton";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { NoShopFound } from "./NoShopFound";

SwiperCore.use([Navigation]);

export const ShopSlider = () => {
  const { showBoundary } = useErrorBoundary();
  const [shops, setAllShops] = useState([]);
  const [displayState, setDisplayState] = useState(false);
  useEffect(() => {
    getAllShop();
  }, []);
  const getAllShop = async () => {
    await getallShops()
      .then((result) => {
        let shopData = result.data;
        if (shopData.status === 1) {
          setAllShops(shopData.shops);
          setDisplayState(true);
        }
      })
      .catch((error) => {
        showBoundary(error);
      });
  };

  return (
    <>
      {displayState ? (
        <section className="product__section product__categories--section pt-5 mb-20">
          <div className="container">
            <div className="section__heading d-flex justify-content-between align-items-center mb-40">
              <h2 className="section__heading--maintitle font-18">
                Near by Shops{" "}
              </h2>
              <div>
                <Link to="/all-shops">
                  View All <i className="fa fa-angle-double-right fs-3"></i>
                </Link>
              </div>
            </div>
            {shops.length === 0 ? <NoShopFound /> : null}
            <Swiper
              effect={"fade"}
              centeredSlides={false}
              slidesPerView={6}
              spaceBetween={8}
              autoplay={false}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                },
                400: {
                  slidesPerView: 2,
                },
                639: {
                  slidesPerView: 2,
                },
                865: {
                  slidesPerView: 2,
                },
                1000: {
                  slidesPerView: 12,
                },
                1500: {
                  slidesPerView: 12,
                },
                1700: {
                  slidesPerView: 12,
                },
                2560: {
                  slidesPerView: 12,
                },
              }}
              navigation={shops.length > 12 ? true : false}
              className="product__section--inner categories2__swiper--activation swiper"
            >
              <div className="swiper-wrapper">
                {shops &&
                  shops.map((item, index) => (
                    <SwiperSlide className="swiper-slide " key={item.shop_id}>
                      <div
                        className="categories2__product--items text-center"
                        to={`/shop-detail/${item.shop_id}`}
                      >
                        <div className="categories2__product--thumbnail">
                          {item.store_images ? (
                            <Link
                              className="categories2__product--link display-block"
                              to={`/shop-detail/${item.shop_id}`}
                            >
                              <img
                                className="categories2__product--img display-block rounded-circle cat-img"
                                src={item.store_images[0]}
                                alt={item.shop_name}
                                title={item.shop_name}
                              />
                            </Link>
                          ) : (
                            <Link
                              className="categories2__product--link display-block"
                              to={`/shop-detail/${item.shop_id}`}
                            >
                              <img
                                className="categories2__product--img display-block rounded-circle cat-img"
                                src={defaultCategoryImage}
                                alt={item.shop_name}
                                title={item.shop_name}
                              />
                            </Link>
                          )}
                        </div>
                        <div className="product__categories--content2">
                          <h3 className="product__categories--content2__maintitle product-list-title">
                            <Link to={`/shop-detail/${item.shop_id}`}>
                              {" "}
                              {item.shop_name}
                            </Link>
                          </h3>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
              </div>
            </Swiper>
          </div>
        </section>
      ) : (
        <SliderSkelton />
      )}
    </>
  );
};
