import "./App.css";
import { Routes, Route } from "react-router-dom";
import { useEffect, useState, createContext } from "react";
import { ToastContainer } from "react-toastify";

import { Layout } from "./layouts/Layout";
import { Home } from "./pages/Home";
import { PageNotFound } from "./pages/PageNotFound";
import publicRoutes from "./routes/Publicroutes";
import { geusertLocation } from "./api/WebsiteManagement";
import { ErrorComponent } from "./components/errors/ErrorComponent";
import { ErrorBoundary } from "react-error-boundary";

export const locationPinCode = createContext();

function App() {
  const [zipCode, setZipCode] = useState([]);
  var locationArray = [];
  useEffect(() => {
    getLocation();
  }, []);
  const getLocation = async () => {
    await geusertLocation().then((result) => {
      // console.log(result.data)
      if (result.data.status === 1) {
        locationArray.push(result.data);
        setZipCode(locationArray);
      }
    });
  };
  return (
    <>
      <ErrorBoundary
        FallbackComponent={ErrorComponent}
        onError={() => {
          console.log("Error");
        }}
      >
        <locationPinCode.Provider value={[zipCode, setZipCode]}>
          <Layout>
            <Routes>
              <Route exact path="/" element={<Home />}></Route>
              {publicRoutes.map((route, index) => {
                return (
                  <Route
                    path={route.path}
                    element={route.element}
                    key={index}
                  />
                );
              })}
              <Route path="*" component={<PageNotFound />} />
            </Routes>
            <button id="scroll__top">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="ionicon"
                viewBox="0 0 512 512"
              >
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="48"
                  d="M112 244l144-144 144 144M256 120v292"
                />
              </svg>
            </button>
          </Layout>
        </locationPinCode.Provider>
      </ErrorBoundary>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default App;
