import React from "react";

export const NoCategoriesFound = () => {
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <h3 className="product__details--info__title mb-15 fw-bold text__primary">
            No Categories Found
          </h3>
        </div>
      </div>
    </>
  );
};
