import axios from "axios";

let backendUrl = process.env.REACT_APP_BACKEND_URL;

export async function getContactusData() {
  return await axios.get(backendUrl + "contact/");
}

export async function getSocialMediaData() {
  return await axios.get(backendUrl + "social_media/");
}

export async function getFooterLinksData() {
  return await axios.get(backendUrl + "footer/");
}

export async function enquiryForm(data) {
  return await axios.put(backendUrl + "contact_us/", data);
}

export async function subScribeNewsLetter(data) {
  return await axios.put(backendUrl + "subscriber/", data);
}
export async function geusertLocation() {
  return await axios.get(backendUrl + "location/");
}

export async function geAboutusData() {
  return await axios.get(backendUrl + "aboutus/allaboutus");
}

export async function geAllFAQ() {
  return await axios.get(backendUrl + "faq/allfaqs");
}
