import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const SidepanelSkelton = () => {
  const items = [1];
  return (
    <>
      {items &&
        items.map((cards, index) => (
          <SkeletonTheme baseColor="#f2f2f2" highlightColor="#fff" key={index}>
            <div className="single__widget widget__bg">
              <h2 className="widget__title h3">
                <Skeleton />
              </h2>
              <ul className="widget__tagcloud">
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </ul>
            </div>
          </SkeletonTheme>
        ))}
    </>
  );
};
