import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import defaultImage from "../assets/img/default.png";
import { SliderSkelton } from "../components/Skeltons/SliderSkelton";
import { getAllBrands } from "../api/BrandsApi";
import { useErrorBoundary } from "react-error-boundary";

export const BrandList = () => {
  const { searchparams } = useParams();
  const { showBoundary } = useErrorBoundary();

  const [brands, setBrands] = useState([]);
  const [featuredBrands, setfeaturedBrands] = useState([]);
  const [brandState, setBrandState] = useState(true);
  const [displayState, setdisplayState] = useState(false);
  const [allBrandsVisible, setallBrandsVisible] = useState(12);
  const [featuredBrandsVisible, setfeaturedBrandsVisible] = useState(12);
  const [searchProduct, setSearchProduct] = useState("");
  const showMoreAllBrandItems = () => {
    setallBrandsVisible((prevValue) => prevValue + 12);
  };
  const showMoreFeaturedBrandItems = () => {
    setfeaturedBrandsVisible((prevValue) => prevValue + 12);
  };
  useEffect(() => {
    allBrands();
  }, []);

  var featuredBrandsArray = [];
  const allBrands = async () => {
    await getAllBrands()
      .then((result) => {
        if (result.data.status === 1) {
          let brandsArray = result.data.brands;
          for (var i = 0; i < brandsArray.length; i++) {
            if (brandsArray[i].brand_state === 1) {
              featuredBrandsArray.push(brandsArray[i]);
            }
          }
          setBrands(brandsArray);
          setfeaturedBrands(featuredBrandsArray);
          setdisplayState(true);
          if (searchparams) {
            setSearchProduct(searchparams);
          } else {
            setSearchProduct("");
          }
        }
      })
      .catch((error) => {
        showBoundary(error);
      });
  };
  return (
    <>
      <section className="product__section product__categories--section section--padding pt-5">
        <div className="container">
          <div className="row mb-5">
            <div className="col">
              <div className="breadcrumb__content">
                <ul className="breadcrumb__content--menu d-flex">
                  <li className="breadcrumb__content--menu__items">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb__content--menu__items text__primary">
                    <span>All Brands</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* ===================== Sorting Conatiner start ============= */}
          <div className="shop__header bg__gray--color mb-30">
            <div className="product__view--mode d-flex align-items-center justify-content-between">
              <div className="product__view--mode__list">
                <div className="product__tab--one product__grid--column__buttons d-flex justify-content-center">
                  <button
                    className={
                      brandState
                        ? "product__grid--column__button active"
                        : "product__grid--column__button"
                    }
                    aria-label="grid btn"
                    data-toggle="tab"
                    data-target="#product_grid"
                    onClick={() => {
                      setBrandState(true);
                    }}
                  >
                    All Brands
                  </button>
                  <button
                    className={
                      brandState
                        ? "product__grid--column__button"
                        : "product__grid--column__button active"
                    }
                    aria-label="list btn"
                    data-toggle="tab"
                    data-target="#product_list"
                    onClick={() => {
                      setBrandState(false);
                    }}
                  >
                    Featured Brands
                  </button>
                </div>
              </div>
              <div className="product__view--mode__list product__view--search d-xl-block">
                <form className="product__view--search__form" action="#">
                  <label>
                    <input
                      className="product__view--search__input border-0"
                      placeholder="Search by Brand Name"
                      type="text"
                      value={searchProduct}
                      onChange={(e) => {
                        setSearchProduct(e.target.value);
                      }}
                    />
                  </label>
                </form>
              </div>
            </div>
          </div>
          {displayState ? (
            <div>
              {brands && brandState && (
                <div className="section__heading d-flex justify-content-between align-items-center mb-40">
                  <h2 className="section__heading--maintitle">All Brands </h2>
                </div>
              )}
              {brands.length === 0 ? (
                <div className="section__heading d-flex justify-content-between align-items-center mb-40">
                  <h2 className="section__heading--maintitle">
                    No Brands Found
                  </h2>
                </div>
              ) : null}
              <div className="row">
                {brands &&
                  brandState &&
                  brands
                    .filter((item) =>
                      item.brand_title
                        .toLowerCase()
                        .includes(searchProduct.toLowerCase())
                    )
                    .slice(0, allBrandsVisible)
                    .map((item) => (
                      <div
                        className="col-lg-2 col-xs-6 col-sm-6 mb-30"
                        key={item.brand_id}
                      >
                        <div className="categories2__product--items text-center position__relative">
                          {item.brand_state === 1 ? (
                            <div className="product__badge">
                              <span className="product__badge--items sale">
                                Featured
                              </span>
                            </div>
                          ) : null}

                          {item.brand_images ? (
                            <div className="categories2__product--thumbnail">
                              <Link
                                className="categories2__product--link display-block"
                                to={`/brand-detail/${item.brand_id}`}
                              >
                                <img
                                  className="categories2__product--img display-block cat-list-img rounded-circle"
                                  src={item.brand_images[0]}
                                  alt={item.brand_title}
                                />
                              </Link>
                            </div>
                          ) : (
                            <div className="categories2__product--thumbnail">
                              <Link
                                className="categories2__product--link display-block"
                                to={`/brand-detail/${item.brand_id}`}
                              >
                                <img
                                  className="categories2__product--img display-block cat-list-img rounded-circle"
                                  src={defaultImage}
                                  alt={item.brand_title}
                                />
                              </Link>
                            </div>
                          )}
                          <div className="product__categories--content2">
                            <h3 className="product__categories--content2__maintitle">
                              <Link to={`/brand-detail/${item.brand_id}`}>
                                {item.brand_title}
                              </Link>
                            </h3>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
              {brands && brandState && brands.length > allBrandsVisible ? (
                <div className="row mt-5 pt-5">
                  <div className="col d-flex justify-content-center align-items-center">
                    <button
                      className="btn btn-success"
                      onClick={showMoreAllBrandItems}
                    >
                      Load More
                    </button>
                  </div>
                </div>
              ) : null}
              <div className="row">
                {featuredBrands && !brandState && (
                  <div className="section__heading d-flex justify-content-between align-items-center mb-40">
                    <h2 className="section__heading--maintitle">
                      Featured Brands{" "}
                    </h2>
                  </div>
                )}
                {featuredBrands &&
                  !brandState &&
                  featuredBrands
                    .filter((item) =>
                      item.brand_title
                        .toLowerCase()
                        .includes(searchProduct.toLowerCase())
                    )
                    .slice(0, featuredBrandsVisible)
                    .map((item) => (
                      <div
                        className="col-lg-2 col-xs-6 col-sm-6 mb-30"
                        key={item.brand_id}
                      >
                        <div className="categories2__product--items text-center">
                          {item.brand_images ? (
                            <div className="categories2__product--thumbnail">
                              <Link
                                className="categories2__product--link display-block"
                                to={`/brand-detail/${item.brand_id}`}
                              >
                                <img
                                  className="categories2__product--img display-block cat-list-img rounded-circle"
                                  src={item.brand_images[0]}
                                  alt={item.brand_title}
                                  title={item.brand_title}
                                />
                              </Link>
                            </div>
                          ) : (
                            <div className="categories2__product--thumbnail">
                              <Link
                                className="categories2__product--link display-block"
                                to={`/brand-detail/${item.brand_id}`}
                              >
                                <img
                                  className="categories2__product--img display-block cat-list-img rounded-circle"
                                  src={defaultImage}
                                  alt={item.brand_title}
                                  title={item.brand_title}
                                />
                              </Link>
                            </div>
                          )}
                          <div className="product__categories--content2">
                            <h3 className="product__categories--content2__maintitle">
                              <Link to={`/brand-detail/${item.brand_id}`}>
                                {item.brand_title}
                              </Link>
                            </h3>
                            {/* <span className="product__categories--content__subtitle">7 products</span> */}
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
              {featuredBrands &&
              !brandState &&
              featuredBrands.length > featuredBrandsVisible ? (
                <div className="row mt-5 pt-5">
                  <div className="col d-flex justify-content-center align-items-center">
                    <button
                      className="btn btn-success"
                      onClick={showMoreFeaturedBrandItems}
                    >
                      Load More
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          ) : (
            <SliderSkelton />
          )}
        </div>
      </section>
    </>
  );
};
