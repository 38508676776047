import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import footerLeftShape from "../assets/img/other/footer-shape1.png";
import footerRightShape from "../assets/img/other/footer-shape2.png";
// import { Link } from "react-router-dom";
import {
  getSocialMediaData,
  getFooterLinksData,
  subScribeNewsLetter,
} from "../api/WebsiteManagement";

export const Footer = () => {
  const [socialIcons, setSocialIcons] = useState([]);
  const [footerData, setFooterData] = useState([]);
  const [footerPaymentsLinksData, setFooterPaymentsLinksData] = useState([]);

  const [footerDisplayState, setfooterDisplayState] = useState(false);
  const [mediaDisplayState, setmediaDisplayState] = useState(false);
  useEffect(() => {
    getSocialIcons();
    getfooterData();
  }, []);
  const getSocialIcons = async () => {
    await getSocialMediaData()
      .then((result) => {
        if (result.data.status === 1) {
          let socialIconsArray = result.data.medias;
          setSocialIcons(socialIconsArray);
          setmediaDisplayState(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  var footerArray = [];
  const getfooterData = async () => {
    await getFooterLinksData()
      .then((result) => {
        if (result.data.status === 1) {
          footerArray.push(result.data.footer);
          setFooterData(footerArray);
          setfooterDisplayState(true);
          setFooterPaymentsLinksData(result.data.footer.footer_payment_links);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    onChange,
    onBlur,
  } = useForm({
    defaultValues: {
      subscriber_email: "",
    },
  });

  const subScriberEmail = async (data) => {
    let subscribeForm = await subScribeNewsLetter(data);
    if (subscribeForm.data.status === 1) {
      toast.success(subscribeForm.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      reset();
    } else {
      toast.warning(subscribeForm.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const isValidEmail = (email) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );

  const handleEmailValidation = (email) => {
    const isValid = isValidEmail(email);

    const validityChanged =
      (errors.email && isValid) || (!errors.email && !isValid);
    if (validityChanged) {
    }

    return isValid;
  };
  return (
    <>
      {
        footerDisplayState && mediaDisplayState ? (
          <footer className="footer__section footer__section3 footer__bg">
            <div className="container">
              <div className="footer__top footer__section3--topbar">
                <div className="row">
                  {footerData &&
                    footerData.map((item) => (
                      <div
                        className="col-lg-6 col-sm-12 col-xs-12"
                        key={item.footer_id}
                      >
                        <div className="row">
                          <div className="col-lg-8 col-md-6 mb-30">
                            <div className="footer__widget ">
                              <h3 className="footer__widget--title sm__footer--widget__bock">
                                About Us
                              </h3>
                              <div className="footer__widget--inner d-block">
                                <Link className="footer__widget--logo" to="/">
                                  <img
                                    src={item.footer_icon}
                                    alt="footer-logo"
                                    width={194}
                                  />
                                </Link>
                                <p className="footer__widget--desc m-0">
                                  {item.footer_description}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 mb-30 mobi-grid">
                            <div className="footer__widget">
                              <h3 className="footer__widget--title">
                                Information.{" "}
                              </h3>
                              <ul className="footer__widget--menu footer__widget--inner d-block">
                                {item.footer_quick_links.map(
                                  (quickLinks, index) => {
                                    return (
                                      <li
                                        className="footer__widget--menu__list"
                                        key={index}
                                      >
                                        <a
                                          className="footer__widget--menu__text"
                                          href={quickLinks.image_url}
                                        >
                                          {quickLinks.title}
                                        </a>
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                  <div className="col-lg-2  col-md-3 mb-30 mobi-grid">
                    <div className="footer__widget">
                      <h3 className="footer__widget--title">Social Media</h3>
                      <ul className="footer__widget--inner footer__social d-block">
                        {socialIcons &&
                          socialIcons.map((item) => (
                            <li
                              className="footer__social--list"
                              key={item.media_id}
                            >
                              <a
                                className="footer__social--list__icon"
                                target="_blank"
                                href={item.media_url}
                                rel="noreferrer"
                              >
                                <span className="footer__social--icon__svg">
                                  <img
                                    src={item.media_image}
                                    alt={item.media_title}
                                    title={item.media_title}
                                  />
                                </span>
                                <span className="footer__social--title">
                                  {item.media_title}
                                </span>
                              </a>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-3 mb-30">
                    <div className="footer__widget">
                      <h3 className="footer__widget--title">Newsletter</h3>
                      <div className="footer__widget--inner d-block">
                        <p className="footer__widget--desc m-0">
                          Get updates by subscribe our weekly newsletter.
                        </p>
                        <div className="newsletter__subscribe">
                          <form
                            className="newsletter__subscribe--form"
                            noValidate
                            onSubmit={handleSubmit(subScriberEmail)}
                          >
                            <label>
                              <input
                                className="newsletter__subscribe--input"
                                onChange={onChange}
                                onBlur={onBlur}
                                placeholder="Your mail here ...."
                                type="text"
                                {...register("subscriber_email", {
                                  required: true,
                                  validate: handleEmailValidation,
                                })}
                              />
                            </label>
                            <button
                              className="newsletter__subscribe--button"
                              type="submit"
                            >
                              subscribe
                            </button>
                          </form>
                          {errors.subscriber_email && (
                            <span className="mt-2 text__primary fs-5">
                              Please Enter Proper Email Address
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer__bottom">
                <div className="row justify-content-between align-items-center">
                  <div className="col-12 col-md-auto">
                    {footerData &&
                      footerData.map((footerLink) => (
                        <p
                          className="copyright__content"
                          key={footerLink.footer_id}
                        >
                          {process.env.REACT_APP_BRANCH_NAME}
                          {footerLink.footer_disclaimer}
                        </p>
                      ))}
                  </div>
                  <div className="col-12 col-md-auto">
                    <div className="footer__payment">
                      <ul className="footer__payment--inner d-flex">
                        {footerPaymentsLinksData &&
                          footerPaymentsLinksData.map((item, index) => {
                            return (
                              <li className="footer__payment--list" key={index}>
                                <img
                                  src={item.image_url}
                                  alt={item.title}
                                  title={item.title}
                                  width={42}
                                />
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              className="footer__position--shape__one"
              src={footerLeftShape}
              alt="footer-shape"
            />
            <img
              className="footer__position--shape__two"
              src={footerRightShape}
              alt="footer-shape"
            />
          </footer>
        ) : null
        // <div id="preloader">
        //   <div id="ctn-preloader" className="ctn-preloader">
        //     <div className="animation-preloader">
        //       <div className="spinner"></div>
        //       <div className="txt-loading">
        //         <span data-text-preloader="L" className="letters-loading">
        //           L
        //         </span>

        //         <span data-text-preloader="O" className="letters-loading">
        //           O
        //         </span>

        //         <span data-text-preloader="A" className="letters-loading">
        //           A
        //         </span>

        //         <span data-text-preloader="D" className="letters-loading">
        //           D
        //         </span>

        //         <span data-text-preloader="I" className="letters-loading">
        //           I
        //         </span>

        //         <span data-text-preloader="N" className="letters-loading">
        //           N
        //         </span>

        //         <span data-text-preloader="G" className="letters-loading">
        //           G
        //         </span>
        //       </div>
        //     </div>

        //     <div className="loader-section section-left"></div>
        //     <div className="loader-section section-right"></div>
        //   </div>
        // </div>
      }
    </>
  );
};
