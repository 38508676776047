import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useErrorBoundary } from "react-error-boundary";
import { SliderSkelton } from "../components/Skeltons/SliderSkelton";
import deafultCategoryImage from "../assets/img/default.png";
import { getAllCategoriesData } from "../api/CategoriesApi";

export const CategoriesList = () => {
  const { searchparams } = useParams();
  const { showBoundary } = useErrorBoundary();

  const [categories, setCategories] = useState([]);
  const [featuredCategories, setFeaturedCategories] = useState([]);
  const [categoryState, setCategoryState] = useState(true);
  const [displayState, setdisplayState] = useState(false);
  var featureCategories = [];
  const [allCatvisible, setAllCatVisible] = useState(12);
  const [featureCatVisible, setfeatureCatVisible] = useState(12);
  const [searchProduct, setSearchProduct] = useState("");
  // const [featuredCategoriesState, setFeaturedCategoriesState] =  useState([]);
  useEffect(() => {
    getAllCategoryData();
  }, []);
  const getAllCategoryData = async () => {
    await getAllCategoriesData()
      .then((result) => {
        if (result.data.status === 1) {
          let categories = result.data.categories;
          for (var i = 0; i < categories.length; i++) {
            if (categories[i].category_state === 1) {
              featureCategories.push(categories[i]);
            }
          }
          setCategories(categories);
          setFeaturedCategories(featureCategories);
          setdisplayState(true);
          if (searchparams) {
            setSearchProduct(searchparams);
          } else {
            setSearchProduct("");
          }
        }
      })
      .catch((error) => {
        showBoundary(error);
      });
  };
  const showMoreAllCatItems = () => {
    setAllCatVisible((prevValue) => prevValue + 12);
  };
  const showMoreFeatureCatItems = () => {
    setfeatureCatVisible((prevValue) => prevValue + 12);
  };
  return (
    <>
      <section className="product__section product__categories--section section--padding pt-5">
        <div className="container">
          <div className="row mb-5">
            <div className="col">
              <div className="breadcrumb__content">
                <ul className="breadcrumb__content--menu d-flex">
                  <li className="breadcrumb__content--menu__items">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb__content--menu__items text__primary">
                    <span>All Categories</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* ===================== Sorting Conatiner start ============= */}
          <div className="shop__header bg__gray--color align-items-center mb-30">
            <div className="product__view--mode d-flex justify-content-between align-items-center">
              <div className="product__view--mode__list">
                <div className="product__tab--one product__grid--column__buttons d-flex justify-content-center">
                  <button
                    className={
                      categoryState
                        ? "product__grid--column__button active"
                        : "product__grid--column__button"
                    }
                    aria-label="grid btn"
                    data-toggle="tab"
                    data-target="#product_grid"
                    onClick={() => {
                      setCategoryState(true);
                    }}
                  >
                    All Categories
                  </button>
                  <button
                    className={
                      categoryState
                        ? "product__grid--column__button"
                        : "product__grid--column__button active"
                    }
                    aria-label="list btn"
                    data-toggle="tab"
                    data-target="#product_list"
                    onClick={() => {
                      setCategoryState(false);
                    }}
                  >
                    Featured Categories
                  </button>
                </div>
              </div>
              <div className="product__view--mode__list product__view--search d-xl-block">
                <form className="product__view--search__form" action="#">
                  <label>
                    <input
                      className="product__view--search__input border-0"
                      placeholder="Search by Category Name"
                      type="text"
                      value={searchProduct}
                      onChange={(e) => {
                        setSearchProduct(e.target.value);
                      }}
                    />
                  </label>
                </form>
              </div>
            </div>
          </div>
          {/* ===================== Sorting Conatiner End ============= */}
          {/*====== All categories Start ================= */}
          {displayState ? (
            <div>
              {categories && categoryState && (
                <div className="section__heading d-flex justify-content-between align-items-center mb-40">
                  <h2 className="section__heading--maintitle">
                    All categories{" "}
                  </h2>
                </div>
              )}
              <div className="row">
                {categories &&
                  categoryState &&
                  categories
                    .filter((item) =>
                      item.category_title
                        .toLowerCase()
                        .includes(searchProduct.toLowerCase())
                    )
                    .slice(0, allCatvisible)
                    .map((item) => (
                      <div
                        className="col-lg-2 col-xs-6 col-sm-6 mb-30"
                        key={item.category_id}
                      >
                        <div className="categories2__product--items text-center position__relative">
                          {item.category_state === 1 ? (
                            <div className="product__badge">
                              <span className="product__badge--items sale">
                                Featured
                              </span>
                            </div>
                          ) : null}
                          {item.category_images ? (
                            <div className="categories2__product--thumbnail">
                              <Link
                                className="categories2__product--link display-block"
                                to={`/categories-detail/${item.category_id}`}
                              >
                                <img
                                  className="categories2__product--img cat-list-img display-block rounded-circle"
                                  src={item.category_images[0]}
                                  alt="categories-img"
                                />
                              </Link>
                            </div>
                          ) : (
                            <div className="categories2__product--thumbnail">
                              <Link
                                className="categories2__product--link display-block"
                                to={`/categories-detail/${item.category_id}`}
                              >
                                <img
                                  className="categories2__product--img cat-list-img display-block rounded-circle"
                                  src={deafultCategoryImage}
                                  alt="categories-img"
                                />
                              </Link>
                            </div>
                          )}
                          <div className="product__categories--content2">
                            <h3 className="product__categories--content2__maintitle">
                              <Link
                                to={`/categories-detail/${item.category_id}`}
                              >
                                {item.category_title}
                              </Link>
                            </h3>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
              {categories && categoryState && categories.length > 12 ? (
                <div className="row mt-5 pt-5">
                  <div className="col d-flex justify-content-center align-items-center">
                    <button
                      className="btn btn-success"
                      onClick={showMoreAllCatItems}
                    >
                      Load More
                    </button>
                  </div>
                </div>
              ) : null}
              {/*======== All categories End ================= */}
              {/*====== Featured categories Start ================= */}
              {featuredCategories && !categoryState && (
                <div className="section__heading d-flex justify-content-between align-items-center mb-40">
                  <h2 className="section__heading--maintitle">
                    Featured categories{" "}
                  </h2>
                </div>
              )}
              <div className="row">
                {featuredCategories &&
                  !categoryState &&
                  featuredCategories
                    .filter((item) =>
                      item.category_title
                        .toLowerCase()
                        .includes(searchProduct.toLowerCase())
                    )
                    .slice(0, featureCatVisible)
                    .map((item) => (
                      <div
                        className="col-lg-2 col-xs-6 col-sm-6"
                        key={item.category_id}
                      >
                        <div className="categories2__product--items text-center">
                          {item.category_images ? (
                            <div className="categories2__product--thumbnail">
                              <Link
                                className="categories2__product--link display-block"
                                to={`/categories-detail/${item.category_id}`}
                              >
                                <img
                                  className="categories2__product--img cat-list-img display-block rounded-circle"
                                  src={item.category_images[0]}
                                  alt="categories-img"
                                />
                              </Link>
                            </div>
                          ) : (
                            <div className="categories2__product--thumbnail">
                              <Link
                                className="categories2__product--link display-block"
                                to={`/categories-detail/${item.category_id}`}
                              >
                                <img
                                  className="categories2__product--img cat-list-img display-block rounded-circle"
                                  src={deafultCategoryImage}
                                  alt="categories-img"
                                />
                              </Link>
                            </div>
                          )}
                          <div className="product__categories--content2">
                            <h3 className="product__categories--content2__maintitle">
                              <Link
                                to={`/categories-detail/${item.category_id}`}
                              >
                                {item.category_title}
                              </Link>
                            </h3>
                            {/* <span className="product__categories--content__subtitle">7 products</span> */}
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
              {featuredCategories &&
              !categoryState &&
              featuredCategories.length > 12 ? (
                <div className="row mt-5 pt-5">
                  <div className="col d-flex justify-content-center align-items-center">
                    <button
                      className="btn btn-success"
                      onClick={showMoreFeatureCatItems}
                    >
                      Load More
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          ) : (
            <SliderSkelton />
          )}
          {/*====== Featured categories End ================= */}
        </div>
      </section>
    </>
  );
};
