import sliderBackground from "../../assets/img/slider/home2-slider1.png";
import defaultImage from "../../assets/img/default.png";
import { useEffect, useState } from "react";
import { getActiveHomeSliders } from "../../api/SlidersApi";

import { useErrorBoundary } from "react-error-boundary";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/components/pagination/pagination.min.css";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
SwiperCore.use([Pagination, Navigation, Autoplay]);

export const HeroSlider = () => {
  const { showBoundary } = useErrorBoundary();
  const [homeSliderData, sethomeSliderData] = useState([]);
  const [displayState, setdisplayState] = useState(false);

  useEffect(() => {
    homeSlidesData();
  }, []);
  const homeSlidesData = async () => {
    await getActiveHomeSliders(1)
      .then((result) => {
        if (result.data.status === 1) {
          let homeSlidersArray = result.data.sliders;
          sethomeSliderData(homeSlidersArray);
          setdisplayState(true);
        }
      })
      .catch((error) => {
        showBoundary(error);
      });
  };
  return (
    <>
      {displayState ? (
        <section className="hero__slider--section section--padding pt-0">
          <Swiper
            effect={"fade"}
            centeredSlides={false}
            slidesPerView={6}
            spaceBetween={30}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              400: {
                slidesPerView: 1,
              },
              639: {
                slidesPerView: 1,
              },
              865: {
                slidesPerView: 1,
              },
              1000: {
                slidesPerView: 1,
              },
              1500: {
                slidesPerView: 1,
              },
              1700: {
                slidesPerView: 1,
              },
              2560: {
                slidesPerView: 1,
              },
            }}
            modules={[Pagination]}
            pagination={{ clickable: true }}
            // onSwiper={(swiper) => {
            //     setSwiper(swiper);
            // }}
            // pagination={true}
            navigation={{
              prevEl: ".prev",
              nextEl: ".next",
            }}
            className="hero__slider--inner hero__slider--activation swiper"
          >
            <div className="hero__slider--wrapper swiper-wrapper">
              {homeSliderData &&
                homeSliderData.map((item) => (
                  <SwiperSlide
                    className="swiper-slide "
                    key={item.slider_one_id}
                  >
                    <div
                      className="hero__slider--items slider__2--bg"
                      style={{ backgroundImage: `url(${sliderBackground})` }}
                    >
                      <div className="container">
                        <div className="hero__slider--items__inner two">
                          <div className="row  align-items-center">
                            <div className="col-xl-5 col-lg-6 col-md-6">
                              <div className="slider__content2">
                                <span className="slider__content2--subtitle">
                                  {item.slider_one_short_description}
                                </span>
                                <h2 className="slider__content2--maintitle h1">
                                  {item.slider_one_title}{" "}
                                </h2>
                                <a
                                  className="btn slider__btn"
                                  href={item.slider_one_link}
                                >
                                  Shop Now
                                </a>
                              </div>
                            </div>
                            <div className="col-xl-7 col-lg-6 col-md-6">
                              <div className="hero__slider--layer text-right">
                                {item.slider_one_image ? (
                                  <img
                                    className="slider__layer--img"
                                    src={item.slider_one_image}
                                    alt="slider-img"
                                  />
                                ) : (
                                  <img
                                    className="slider__layer--img"
                                    src={defaultImage}
                                    alt="slider-img"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </div>
          </Swiper>
        </section>
      ) : null}
    </>
  );
};
