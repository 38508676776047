import axios from "axios";

var backendUrl = process.env.REACT_APP_BACKEND_URL;

export async function getallShops() {
  return await axios.get(backendUrl + "shop/");
}

export async function getShopbyId(id) {
  return await axios.get(backendUrl + "shop/" + id);
}

export async function getProductsbyShopId(id) {
  return await axios.get(backendUrl + "shop/products/" + id);
}
