import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export const SliderSkelton = () => {
  const items = [1, 2, 3, 4, 5, 6];

  return (
    <>
      <section className="product__section product__categories--section">
        <div className="container ">
          <div className="row">
            <div className="col-lg-12">
              <SkeletonTheme baseColor="#f2f2f2" highlightColor="#fff">
                <div className="section__heading d-flex justify-content-between align-items-center mb-40">
                  <h2 className="section__heading--maintitle">
                    <Skeleton />
                  </h2>
                  <div>
                    <div>
                      <Skeleton />
                    </div>
                  </div>
                </div>
              </SkeletonTheme>
            </div>
            {items &&
              items.map((item, index) => (
                <SkeletonTheme
                  baseColor="#f2f2f2"
                  highlightColor="#fff"
                  key={index}
                >
                  <div className="col-lg-2 col-md-2 col-xs-6 col-sm-6 product__section--inner categories2__swiper--activation">
                    <div
                      className="categories2__product--items text-center"
                      to="/categories-detail/1"
                    >
                      <div className="categories2__product--thumbnail">
                        <div className="categories2__product--link display-block">
                          {/* <img
                            className="categories2__product--img display-block rounded-circle cat-img"
                            src=""
                            alt="categories-img"
                          /> */}
                          <Skeleton
                            height={90}
                            width={90}
                            circle={true}
                            className="categories2__product--img display-block rounded-circle cat-img"
                          />
                        </div>
                      </div>
                      <div className="product__categories--content2">
                        <h3 className="product__categories--content2__maintitle">
                          <Skeleton />
                        </h3>
                      </div>
                    </div>
                  </div>
                </SkeletonTheme>
              ))}
          </div>
        </div>
      </section>
    </>
  );
};
