import { useEffect, useState } from "react";
import catFour from "../../assets/img/product/categories4.png";
import { getAllCategoriesData } from "../../api/CategoriesApi";
import { getAllBrands } from "../../api/BrandsApi";
import { getAllSubCategoriesData } from "../../api/SubCategoriesApi";
import { SidepanelSkelton } from "../Skeltons/SidePanel";
import { useErrorBoundary } from "react-error-boundary";

export const SidePanel = ({ onClickHandler }) => {
  const [catDisplay, setCatDisplay] = useState(false);
  const { showBoundary } = useErrorBoundary();
  const [brandDisplay, setBrandDisplay] = useState(false);
  const [subCatDisplay, setsubCatDisplay] = useState(false);
  const [catActive, setCatActive] = useState(null);
  const [brandActive, setBrandActive] = useState(null);
  const [subCatActive, setsubCatActive] = useState(null);
  useEffect(() => {
    getAllCategories();
    getAllBrandData();
    getAllSubCategoryData();
  }, []);

  // GET ALL CATEGORIES
  const [categoriesData, setCategoriesData] = useState([]);
  const getAllCategories = async () => {
    await getAllCategoriesData()
      .then((result) => {
        if (result.data.status === 1) {
          setCategoriesData(result.data.categories);
          setCatDisplay(true);
        }
      })
      .catch((err) => {
        showBoundary(err);
      });
  };

  // GET ALL BRANDS
  const [brandsData, setBrandsData] = useState([]);
  const getAllBrandData = async () => {
    await getAllBrands()
      .then((result) => {
        if (result.data.status === 1) {
          setBrandsData(result.data.brands);
          setBrandDisplay(true);
        }
      })
      .catch((err) => {
        showBoundary(err);
      });
  };

  // GET ALL SUBCATEGORIES
  const [subCategoriesData, setSubCategoriesData] = useState([]);
  const getAllSubCategoryData = async () => {
    await getAllSubCategoriesData()
      .then((result) => {
        if (result.data.status === 1) {
          setSubCategoriesData(result.data.sub_categories);
          setsubCatDisplay(true);
        }
      })
      .catch((err) => {
        showBoundary(err);
      });
  };
  // sidepnelData ={id: '', type: ''}
  // const onClickHandler = async (clicked_id) =>{
  //   console.log(clicked_id);
  //   this.props.clickedHandler(clicked_id)
  // }
  return (
    <>
      <div className="col-xl-3 col-lg-4">
        <div className="shop__sidebar--widget widget__area">
          {/* <div className="single__widget widget__bg">
            <h2 className="widget__title h3">Categories</h2>
            <ul className="widget__categories--menu">
              <li className="widget__categories--menu__list">
                <label className="widget__categories--menu__label d-flex align-items-center">
                  <img
                    className="widget__categories--menu__img"
                    src={catFour}
                    alt="categories-img"
                  />
                  <span className="widget__categories--menu__text">
                    Fresh Vegetables
                  </span>
                  <svg
                    className="widget__categories--menu__arrowdown--icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12.355"
                    height="8.394"
                  >
                    <path
                      d="M15.138,8.59l-3.961,3.952L7.217,8.59,6,9.807l5.178,5.178,5.178-5.178Z"
                      transform="translate(-6 -8.59)"
                      fill="currentColor"
                    ></path>
                  </svg>
                </label>
                <ul className="widget__categories--sub__menu">
                  <li className="widget__categories--sub__menu--list">
                    <a
                      className="widget__categories--sub__menu--link d-flex align-items-center"
                      href="shop.html"
                    >
                      <img
                        className="widget__categories--sub__menu--img"
                        src="assets/img/product/categories12.png"
                        alt="categories-img"
                      />
                      <span className="widget__categories--sub__menu--text">
                        Discount Weekly
                      </span>
                    </a>
                  </li>
                  <li className="widget__categories--sub__menu--list">
                    <a
                      className="widget__categories--sub__menu--link d-flex align-items-center"
                      href="shop.html"
                    >
                      <img
                        className="widget__categories--sub__menu--img"
                        src="assets/img/product/categories15.png"
                        alt="categories-img"
                      />
                      <span className="widget__categories--sub__menu--text">
                        Green dhaniya
                      </span>
                    </a>
                  </li>
                  <li className="widget__categories--sub__menu--list">
                    <a
                      className="widget__categories--sub__menu--link d-flex align-items-center"
                      href="shop.html"
                    >
                      <img
                        className="widget__categories--sub__menu--img"
                        src="assets/img/product/categories14.png"
                        alt="categories-img"
                      />
                      <span className="widget__categories--sub__menu--text">
                        resh Nuts
                      </span>
                    </a>
                  </li>
                  <li className="widget__categories--sub__menu--list">
                    <a
                      className="widget__categories--sub__menu--link d-flex align-items-center"
                      href="shop.html"
                    >
                      <img
                        className="widget__categories--sub__menu--img"
                        src="assets/img/product/categories13.png"
                        alt="categories-img"
                      />
                      <span className="widget__categories--sub__menu--text">
                        Millk Cream
                      </span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div> */}
          {catDisplay ? (
            <>
              {categoriesData.length === 0 ? (
                ""
              ) : (
                <div className="single__widget widget__bg">
                  <h2 className="widget__title h3">Categories</h2>
                  <ul className="widget__tagcloud">
                    {categoriesData &&
                      categoriesData.map((category, index) => {
                        return (
                          <li
                            className="widget__tagcloud--list"
                            key={index}
                            onClick={() => {
                              onClickHandler(category.category_id, "category");
                              setCatActive(index);
                              setBrandActive(null);
                              setsubCatActive(null);
                            }}
                          >
                            <a
                              className={
                                index == catActive
                                  ? "widget__tagcloud--link active"
                                  : "widget__tagcloud--link"
                              }
                            >
                              {category.category_title}
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              )}
            </>
          ) : (
            <SidepanelSkelton />
          )}
          {/*========= Price Filter Start========= */}
          {/* <div className="single__widget price__filter widget__bg">
            <h2 className="widget__title h3">Filter By Price</h2>
            <form className="price__filter--form" action="#">
              <div className="price__filter--form__inner mb-15 d-flex align-items-center">
                <div className="price__filter--group">
                  <label
                    className="price__filter--label"
                    htmlFor="Filter-Price-GTE2"
                  >
                    From
                  </label>
                  <div className="price__filter--input border-radius-5 d-flex align-items-center">
                    <span className="price__filter--currency">$</span>
                    <input
                      className="price__filter--input__field border-0"
                      name="filter.v.price.gte"
                      id="Filter-Price-GTE2"
                      type="number"
                      placeholder="0"
                      min="0"
                      max="250.00"
                    />
                  </div>
                </div>
                <div className="price__divider">
                  <span>-</span>
                </div>
                <div className="price__filter--group">
                  <label
                    className="price__filter--label"
                    htmlFor="Filter-Price-LTE2"
                  >
                    To
                  </label>
                  <div className="price__filter--input border-radius-5 d-flex align-items-center">
                    <span className="price__filter--currency">$</span>
                    <input
                      className="price__filter--input__field border-0"
                      name="filter.v.price.lte"
                      id="Filter-Price-LTE2"
                      type="number"
                      min="0"
                      placeholder="250.00"
                      max="250.00"
                    />
                  </div>
                </div>
              </div>
              <button className="btn price__filter--btn" type="submit">
                Filter
              </button>
            </form>
          </div> */}
          {/*========= Price Filter End========= */}
          {/*========= Brands Start======== */}
          {brandDisplay ? (
            <>
              {brandsData.length === 0 ? (
                ""
              ) : (
                <div className="single__widget widget__bg">
                  <h2 className="widget__title h3">Brands</h2>
                  <ul className="widget__tagcloud">
                    {brandsData &&
                      brandsData.map((brands, index) => (
                        <li
                          className="widget__tagcloud--list"
                          key={index}
                          onClick={() => {
                            onClickHandler(brands.brand_id, "brand");
                            setBrandActive(index);
                            setCatActive(null);
                            setsubCatActive(null);
                          }}
                        >
                          <a
                            className={
                              index == brandActive
                                ? "widget__tagcloud--link active"
                                : "widget__tagcloud--link"
                            }
                          >
                            {brands.brand_title}
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
              )}
            </>
          ) : (
            <SidepanelSkelton />
          )}
          {/*========= Brands End======== */}
          {/*========= Top rated Products Start======== */}
          {/* <div className="single__widget widget__bg">
            <h2 className="widget__title h3">Top Rated Product</h2>
            <div className="product__grid--inner">
              <div className="product__grid--items d-flex align-items-center">
                <div className="product__grid--items--thumbnail">
                  <a
                    className="product__items--link"
                    href="product-details.html"
                  >
                    <img
                      className="product__grid--items__img product__primary--img"
                      src={catFour}
                      alt="product-img"
                    />
                    <img
                      className="product__grid--items__img product__secondary--img"
                      src={catFour}
                      alt="product-img"
                    />
                  </a>
                </div>
                <div className="product__grid--items--content">
                  <h3 className="product__grid--items--content__title h4">
                    <a href="product-details.html">Green-surface</a>
                  </h3>
                  <div className="product__items--price">
                    <span className="current__price">$42.00</span>
                    <span className="old__price">$48.00</span>
                  </div>
                  <div className="product__items--rating d-flex align-items-center">
                    <ul className="d-flex">
                      <li className="product__items--rating__list">
                        <span className="product__items--rating__icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10.105"
                            height="9.732"
                            viewBox="0 0 10.105 9.732"
                          >
                            <path
                              data-name="star - Copy"
                              d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                              transform="translate(0 -0.018)"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </li>
                      <li className="product__items--rating__list">
                        <span className="product__items--rating__icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10.105"
                            height="9.732"
                            viewBox="0 0 10.105 9.732"
                          >
                            <path
                              data-name="star - Copy"
                              d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                              transform="translate(0 -0.018)"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </li>
                      <li className="product__items--rating__list">
                        <span className="product__items--rating__icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10.105"
                            height="9.732"
                            viewBox="0 0 10.105 9.732"
                          >
                            <path
                              data-name="star - Copy"
                              d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                              transform="translate(0 -0.018)"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </li>
                      <li className="product__items--rating__list">
                        <span className="product__items--rating__icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10.105"
                            height="9.732"
                            viewBox="0 0 10.105 9.732"
                          >
                            <path
                              data-name="star - Copy"
                              d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                              transform="translate(0 -0.018)"
                              fill="currentColor"
                            />
                          </svg>
                        </span>
                      </li>
                      <li className="product__items--rating__list">
                        <span className="product__items--rating__icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10.105"
                            height="9.732"
                            viewBox="0 0 10.105 9.732"
                          >
                            <path
                              data-name="star - Copy"
                              d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                              transform="translate(0 -0.018)"
                              fill="#c7c5c2"
                            />
                          </svg>
                        </span>
                      </li>
                    </ul>
                    <span className="product__items--rating__count--number">
                      (24)
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/*========= Top rated Products End======== */}
          {/*========= Brands Start======== */}
          {subCatDisplay ? (
            <>
              {subCategoriesData.length === 0 ? (
                ""
              ) : (
                <div className="single__widget widget__bg">
                  <h2 className="widget__title h3">Sub Categories</h2>
                  <ul className="widget__tagcloud">
                    {subCategoriesData &&
                      subCategoriesData.map((subCategory, index) => (
                        <li
                          className="widget__tagcloud--list"
                          key={index}
                          onClick={() => {
                            onClickHandler(
                              subCategory.sub_category_id,
                              "subcategory"
                            );
                            setBrandActive(null);
                            setCatActive(null);
                            setsubCatActive(index);
                          }}
                        >
                          <a
                            className={
                              index == subCatActive
                                ? "widget__tagcloud--link active"
                                : "widget__tagcloud--link"
                            }
                          >
                            {subCategory.sub_category_title}
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
              )}
            </>
          ) : (
            <SidepanelSkelton />
          )}
          {/*========= Brands End======== */}
        </div>
      </div>
    </>
  );
};
